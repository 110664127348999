export const getCurrentDate = () => {
    let date = new Date();
    let targetDate = new Date(date.setDate(date.getDate()+3))

    let year = targetDate.getFullYear().toString();

    let month = targetDate.getMonth() + 1;
    month = month < 10 ? '0' + month.toString() : month.toString();
    
    let day = targetDate.getDate();
    day = day < 10 ? '0' + day.toString() : day.toString();
    
    let hour = targetDate.getHours();
    hour = hour < 10 ? '0' + hour.toString() : hour.toString();
    
    let minites = targetDate.getMinutes();
    minites = minites < 10 ? '0' + minites.toString() : minites.toString();
    
    let seconds = targetDate.getSeconds();
    seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();
    
    return year + month + day + hour + minites + seconds;
}

export const formatCurrentDate = (targetDate) => {

    let year = targetDate.getFullYear().toString();

    let month = targetDate.getMonth() + 1;
    month = month < 10 ? '0' + month.toString() : month.toString();
    
    let day = targetDate.getDate();
    day = day < 10 ? '0' + day.toString() : day.toString();
    
    let hour = targetDate.getHours();
    hour = hour < 10 ? '0' + hour.toString() : hour.toString();
    
    let minites = targetDate.getMinutes();
    minites = minites < 10 ? '0' + minites.toString() : minites.toString();
    
    let seconds = targetDate.getSeconds();
    seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();
    
    return `${year}-${month}-${day} ${hour}:${minites}:${seconds}`;
}

export const formatCurrentDateZero = (targetDate, type) => {

    let year = targetDate.getFullYear().toString();

    let month = targetDate.getMonth() + 1;
    month = month < 10 ? '0' + month.toString() : month.toString();
    
    let day = targetDate.getDate();
    day = day < 10 ? '0' + day.toString() : day.toString();
    
    let hour = targetDate.getHours();
    hour = hour < 10 ? '0' + hour.toString() : hour.toString();
    
    let minites = targetDate.getMinutes();
    minites = minites < 10 ? '0' + minites.toString() : minites.toString();
    
    let seconds = targetDate.getSeconds();
    seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();
    
    if(type == 'end') return `${year}-${month}-${day} 23:59:59`;
    return `${year}-${month}-${day} 00:00:00`;
}

Date.prototype.addMonth = function(month) {
    var date = new Date(this.valueOf());
    date.setDate(1);
    date.setMonth(date.getMonth() + month);
    return date;
}

export const getMonth = (startDate, stopDate) => {
    var dateArray = new Array();
    if(typeof(stopDate) == 'date')
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    stopDate.setHours(23);
    stopDate.setMinutes(59);
    stopDate.setSeconds(59);
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date (currentDate));
        currentDate = currentDate.addMonth(1);
    }
    return dateArray;
}