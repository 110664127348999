import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import { isMobile, isIOS } from 'react-device-detect';
import ServiceConsoleTab01 from './ServiceConsoleTab01';
import ServiceConsoleTab02 from './ServiceConsoleTab02';
import ServiceConsoleTab03 from './ServiceConsoleTab03';
import ServiceConsoleTab04 from './ServiceConsoleTab04';
import ServiceConsoleTab05 from './ServiceConsoleTab05';
import ServiceConsoleTab06 from './ServiceConsoleTab06';
import ServiceConsoleTab06_modify from './ServiceConsoleTab06_modify';
import ServiceConsoleTab_infor from './ServiceConsoleTab_infor';
import ServiceConsoleTab_templt from './ServiceConsoleTab_templt';

const ServiceConsole = (props) => {
    const {svcNo, svcData, svcName, requireNumber, requireTemplate} = props
    const [selectedTab, setSelectedTab] = useState(0)
    const [modal, setModal] = useState(false);
    const [modal02, setModal02] = useState(false);
    const [modifyData, setModifyData] = useState(false);
    const tabList = {
        0 : <ServiceConsoleTab01 svcNo={svcNo} svcName={svcName}/>,
        1 : <ServiceConsoleTab02 />,
        2 : <ServiceConsoleTab03 />,
        3 : <ServiceConsoleTab04 svcNo={svcNo}/>,
        4 : <ServiceConsoleTab_infor svcNo={svcNo} svcData={svcData}/>,
        5 : <ServiceConsoleTab05 />,
        6 : <ServiceConsoleTab_templt setModal={setModal} modal={modal} setModal01={setModal} modal02={modal02} setModal02={setModal02} modifyData={modifyData} setModifyData={setModifyData}/>,
    }
    
    useEffect(()=>{
        if(isMobile || isIOS) window.location.href = '/';
        if(t('langNo') !== '1') window.location.href = '/';
    },[])

    return(
        <>
            <div className="tab subtab">
                <ul>
                    <li className={selectedTab == 0 ? 'tab-link current' : 'tab-link'} onClick={()=>{setSelectedTab(0)}}>Sub Account</li>
                    <li className={selectedTab == 1 ? 'tab-link current' : 'tab-link'} onClick={()=>{setSelectedTab(1)}}>사용내역</li>
                    {(()=>{
                        if(requireNumber){
                            return <li className={selectedTab == 2 ? 'tab-link current' : 'tab-link'} onClick={()=>{setSelectedTab(2)}}>발신번호</li>
                        }
                    })()}                    
                    <li className={selectedTab == 3 ? 'tab-link current' : 'tab-link'} onClick={()=>{setSelectedTab(3)}}>서비스 신청 이력</li>
                    <li className={selectedTab == 4 ? 'tab-link current' : 'tab-link'} onClick={()=>{setSelectedTab(4)}}>연동 정보</li>
                    {(()=>{
                        if(requireTemplate){return(
                            <>
                                <li className={selectedTab == 5 ? 'tab-link current' : 'tab-link'} onClick={()=>{setSelectedTab(5)}}>발신프로필 등록</li>
                                <li className={selectedTab == 6 ? 'tab-link current' : 'tab-link'} onClick={()=>{setSelectedTab(6);}}>템플릿 관리</li>
                            </>
                        )}
                    })()}
                </ul>
            </div>
            {tabList[selectedTab]}
            {modal ? <ServiceConsoleTab06 modal={modal} setModal={setModal}/> : null}
            {modal02 ? <ServiceConsoleTab06_modify modal={modal02} setModal={setModal02} modifyData={modifyData}/> : null}
        </>
    )
}

export default ServiceConsole;