const { REACT_APP_API_URL, REACT_APP_VAPID_KEY, REACT_APP_AUTH_INFO } = process.env;

export async function loadServiceWorker() {
    if ('serviceWorker' in navigator) {
      let registration = await navigator.serviceWorker.getRegistration();

      if(!registration) {
        navigator.serviceWorker.register('./public/service-worker.js', { scope: '/'});
        return true;
      }else{
        console.warn("Registration already exist");
        return true;
      }
    } else {
      console.warn('Service workers are not supported in this browser.');
      return false;
    }
}

export async function checkSubscribed() {
    if(!('showNotification' in ServiceWorkerRegistration.prototype)) {
      console.warn('Notifications aren\'t supported');
      return false;
    }

    if(Notification.permission === 'denied') {
      console.warn('The user has blocked notifications.');
      return false;
    }

    if(!('PushManager' in window)) {
      console.warn('Push messaging isn\'t supported');
      return false;
    }

    try {
        //get subscription from the browser of user
        const serviceWorkerRegistration = await navigator.serviceWorker.ready;
        const subscription = await serviceWorkerRegistration.pushManager.getSubscription();

        if(!subscription) {
            return true;
        }else{
            return false;
        }

        //get subscription from server
    }
    catch(err) {
        console.warn('Error during getSubscription()', err);
        return false;
    }
    finally{
        //Do nothing;
    };

}
  
export function initializeState(userId) {
    if(!('showNotification' in ServiceWorkerRegistration.prototype)) {
      console.warn('Notifications aren\'t supported');
      return;
    }
  
    if(Notification.permission === 'denied') {
      console.warn('The user has blocked notifications.');
      return;
    }
  
    if(!('PushManager' in window)) {
      console.warn('Push messaging isn\'t supported');
      return;
    }
  
    return navigator.serviceWorker.ready.then(function (ServiceWorkerRegistration) {
      ServiceWorkerRegistration.pushManager.getSubscription()
      .then(
        function (subscription) {
          if(!subscription) {
            return subscribe(userId);

          }
        }
      )
      .catch(function(err) {
        console.warn('Error during getSubscription()', err);
      });
    });
  }
  
export async function subscribe(userId) {
    return navigator.serviceWorker.ready.then( async function (ServiceWorkerRegistration) {
      try {
        const subscription = await ServiceWorkerRegistration.pushManager.subscribe(
            {
              userVisibleOnly: true,
              applicationServerKey: REACT_APP_VAPID_KEY
            });
        const doSubscribe = await sendSubscriptionToServer(subscription, userId);
        const doInitialNotify = await initialNotify(userId);
        return doInitialNotify;

      } catch(e) {
        if (Notification.permission === 'denied') {
          console.warn('Permission for Notifications was denied');
        } else {
          console.error('Unable to subscribe to push.', e);
        }
      }
    });
  }
  
export function unsubscribe() {
    navigator.serviceWorker.ready.then(function (ServiceWorkerRegistration) {
      ServiceWorkerRegistration.pushManager.getSubscription()
      .then(
        function (subscription) {
          if(subscription) {
            if(window.confirm('구독을 해제하시겠습니까?')){
              subscription.unsubscribe().then((res) => {
                notifyUnsubscriptionToServer(subscription);
              }).catch((e) => {
                console.error("Unable to unsubscribe. ", e);
              });
            }
          }
        }
      )
      .catch(function(err) {
        console.warn('Error during getSubscription()', err);
      });
    });
  }
  
export async function sendSubscriptionToServer(subscription, userId) {
    var key = subscription.getKey ? subscription.getKey('p256dh') : '';
    var auth = subscription.getKey ? subscription.getKey('auth') : '';
  
    return fetch(`${REACT_APP_API_URL}/subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': REACT_APP_AUTH_INFO
      },
      body: JSON.stringify({
        userId: userId,
        clientId: 'apiplex',  //depends on client
        endpointUrl: subscription.endpoint,
        p256dh: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : '',
        auth: auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : '',
        browserType: checkBrowserType()
      })
    }).then(function (res) {
      // console.log(res);
    });
  }

export async function initialNotify(idx) {
    return fetch(`${REACT_APP_API_URL}/notify/byuser`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'authorization': REACT_APP_AUTH_INFO
      },
      body: JSON.stringify({
        clientId: 'apiplex',  //depends on client
        userId: idx,
        payload: {
            msgKey: 'init',
            title: '이제 API PLEX의 알림을 받을 준비가 되었습니다!',
            text: '신규 서비스 안내부터 각종 이벤트까지 그 어떤 혜택도 놓치지 마세요!',
            icon: 'https://apiplex.net/public/images/img_m_midvs_1.png'
        }
      })
    }).then(function (res) {
      // console.log(res);
    });
  }
  
export function notifyUnsubscriptionToServer(subscription) {
    var key = subscription.getKey ? subscription.getKey('p256dh') : '';
    var auth = subscription.getKey ? subscription.getKey('auth') : '';
  
    return fetch(`${REACT_APP_API_URL}/unsubscribe`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'authorization': REACT_APP_AUTH_INFO
      },
      body: JSON.stringify({
        endpointUrl: subscription.endpoint,
        p256dh: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : '',
        auth: auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : '',
        browserType: checkBrowserType()
      })
    }).then(function (res) {
      // console.log(res);
    });
  }
  
export function checkBrowserType() {
    let agent = navigator.userAgent;
  
    //조건은 다음 링크 참조 : https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent

    if(agent.match(/Firefox\/[0-9]+/) !== null && agent.match(/Seamonkey\/[0-9]+/) === null){
      return "Firefox";
    }else if(agent.match(/Safari\/[0-9]+/) !== null && agent.match(/Chromium\/[0-9]+/) === null && agent.match(/Chrome\/[0-9]+/) === null){
      return "Safari";
    }else if(agent.match(/Chrome\/[0-9]+/) !== null && agent.match(/Chromium\/[0-9]+/) === null && agent.match(/Edg/) === null){
      return "Chrome";
    }else if(agent.match(/Chromium\/[0-9]+/) !== null && agent.match(/Edg/) === null){
      return "Chromium";
    }else if(agent.match(/Edg/) !== null){
      return "Edge";
    }else if(agent.match(/OPR\/[0-9]+/) !== null || agent.match(/Opera\/[0-9]+/) !== null){
      return "Opera";
    }else{
      return "Others";
    }
  }