import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../Util/Loading';
import { getApiDetail, getApplicationHistory, getServiceApplication, } from '../../Util/reqApi';

const ServiceConsoleTab04 = () => {
    const [serviceApplication, setServiceApplication] = useState();
    const { api } = useParams();

    useEffect(()=>{
        getServiceApplication(api).then(result => {
            getApiDetail(api).then(({slaveSvcList}) => {
                Promise.all(slaveSvcList.map(el => {
                    return getServiceApplication(el.svcNo)
                })).then(res => {
                    let data = res.reduce((acc, cur) => {
                        return acc.concat(cur)
                    }, [])

                    let svcNoList = slaveSvcList.reduce((acc, cur) => {
                        acc.push(cur.svcNo);
                        return acc;
                    }, [])

                    getApplicationHistory([...svcNoList, api].sort((a,b) => a-b).join(',')).then(result => {
                        setServiceApplication(result)
                    })
                    // setServiceApplication([...data, ...result])
                })
            })
        })
    },[])    

    if(serviceApplication){
        return(
            <div id="cons-4" className="tab-content current">
                <div>
                    <div className="tt_h2">서비스 신청 이력</div>
                    <div className="tt_script">
                    · 서비스 신청 후 변경사항 이력을 확인할 수 있습니다.
                    </div>
                </div>
                <div className="tt_blank20"></div>
                <table className="table_mypage">
                    <colgroup>
                        <col width="80px" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>번호</th>
                            <th>서비스명</th>
                            <th>내용</th>
                            <th>변경날짜</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(()=>{
                            if(serviceApplication.length == 0){
                                return (
                                    <tr>
                                        <td colSpan={4}>서비스 신청 이력이 없습니다.</td>
                                    </tr>
                                )
                            }else{
                                return serviceApplication.map((el,idx)=>{
                                    return(
                                        <tr key={idx}>
                                            <td>{idx}</td>
                                            <td>{el.svcNameKr}</td>
                                            <td>
                                                {`IP: ${el.ip ? el.ip : '-'}`}
                                                {`, `}
                                                {`callBack: ${el.ip ? el.ip : '-'}`}
                                                <br />
                                                {
                                                    (el.applicationJson && el.applicationJson !== null && el.applicationJson !== 'null') &&
                                                    JSON.parse(el.applicationJson)
                                                    .filter(el => el.user_editable)
                                                    .map(el => {
                                                        return `${el.label} : ${el.value}`
                                                    }).join(', ')
                                                }
                                            </td>
                                            <td>{el.regDate[0]}. {el.regDate[1]}. {el.regDate[2]}</td>
                                        </tr>
                                    )
                                })
                            }
                        })()}
                    </tbody>
                </table>
                <div className="tt_blank80"></div>
            </div>
        )
    }else return <Loading />
}

export default ServiceConsoleTab04;