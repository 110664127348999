import React, { useState, useEffect } from 'react';
import Loading from '../../Util/Loading';
import { deleteSubAccount, getSubAccount } from '../../Util/reqApi';
import CreateSubAccount from './Component/Modal/CreateSubAccount';
import EditSubAccount from './Component/Modal/EditSubAccount';
import SubAccountDetail from './Component/Modal/SubAccountDetail';

const ServiceConsoleTab01 = (props) => {
    const {svcNo, svcName} = props;

    const [subAccountData, setSubAccountData] = useState();
    const [subAccountModal, setSubAccountModal] = useState(false);
    const [subAccountEditModal, setSubAccountEditModal] = useState(false);
    const [subAccountEditTarget, setSubAccountEditTarget] = useState();
    const [subAccountDetail, setSubAccountDetail] = useState();
    const [subAccountDetailData, setSubAccountDetailData] = useState();
    const [checkedSubAccount, setCheckedSubAccount] = useState(null);
    const [simpleText, setSimpleText] = useState();
    const [simpleTextModal, setSimpleTextModal] = useState(false);

    useEffect(()=>{
        getSubAccount(svcNo).then(result=>{ setSubAccountData(result) })
        return () => {setSubAccountData()}
    },[])

    if(subAccountData){
        return(
            <>
                <div id="cons-1" className="tab-content current">
                    <div>
                        <div className="tt_h2">Sub Account</div>
                        <div className="tt_script">
                        1. 메시지를 발송하기 위해서는 먼저 Sub Account를 등록합니다. <br/>
                        2. “상세조회” 클릭 하시면, Secret Key를 확인하실 수 있습니다.
                        </div>
                    </div>
                    <div className="tt_blank20"></div>
                    <div className="option_bar">
                        <button
                            className="btn_sm6"
                            onClick={()=>{setSubAccountModal(true)}}
                        >
                            + Sub Account
                        </button>
                        <div className="r_area">
                            <button
                                className="btn_sm3"
                                style={{width:"60px"}}
                                onClick={()=>{
                                    if(checkedSubAccount !== null){
                                        setSubAccountEditTarget(subAccountData.subAccountList[checkedSubAccount])
                                        setSubAccountEditModal(true)
                                    }else{
                                        alert('수정할 Sub Account를 선택해주세요.')
                                    }
                                }}
                            >
                                수정
                            </button>
                            <button
                                className="btn_sm3"
                                style={{width:"60px"}}
                                onClick={()=>{
                                    if(checkedSubAccount !== null){
                                        setSimpleTextModal(true);
                                        setSimpleText('정말 삭제하시겠습니까?');
                                    }else{
                                        alert('삭제할 Sub Account를 선택해주세요.')
                                    }
                                }}
                            >
                                삭제
                            </button>
                        </div>
                    </div>
                    <div className="tt_blank10"></div>
                    {
                        (()=>{
                            if(subAccountData){
                                if(subAccountData.subAccountList.length == 0){
                                    return (
                                        <table className="table_mypage">
                                            <colgroup>
                                                <col width="5%" />
                                                <col width="10%" />
                                                <col width="*" />
                                                <col width="10%" />
                                                <col width="15%" />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>선택</th>
                                                    <th>이름</th>
                                                    <th>설명</th>
                                                    <th>서비스</th>
                                                    <th>서비스 상세 조회</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={5}>등록된 Sub Account가 없습니다.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    )
                                }else{
                                    return (
                                        <table className="table_mypage">
                                            <colgroup>
                                                <col width="5%" />
                                                <col width="10%" />
                                                <col width="*" />
                                                <col width="10%" />
                                                <col width="15%" />
                                            </colgroup>
                                            <thead>
                                                <tr>
                                                    <th>선택</th>
                                                    <th>이름</th>
                                                    <th>설명</th>
                                                    <th>서비스</th>
                                                    <th>서비스 상세 조회</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    subAccountData.subAccountList.map((el,idx)=>{
                                                        return(
                                                            <tr key={idx}>
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        id={`UNII${idx}`}
                                                                        name="UNII1"
                                                                        checked={checkedSubAccount == idx ? true : false}
                                                                        onChange={()=>{setCheckedSubAccount(idx)}}
                                                                    />
                                                                    <label htmlFor={`UNII${idx}`}></label>
                                                                </td>
                                                                <td>{el.subject}</td>
                                                                <td style={{textAlign:'left'}}>{el.description}</td>
                                                                <td>{el.serviceId.split('|')[0]}</td>
                                                                <td>
                                                                    <button
                                                                        className="btn_sm7"
                                                                        onClick={()=>{
                                                                            setSubAccountDetailData(el.accountNo)
                                                                            setSubAccountDetail(true)
                                                                        }}
                                                                    >
                                                                        상세조회
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    )
                                }
                            }else{
                                return <Loading />
                            }
                        })()
                    }            
                    <div className="tt_blank80"></div>
                </div>
                <CreateSubAccount
                    svcNo={svcNo}
                    subAccountModal={subAccountModal}
                    setSubAccountModal={setSubAccountModal}
                    svcName={svcName}
                    setSubAccountData={setSubAccountData}
                />
                {
                    subAccountEditModal
                    ?
                        <EditSubAccount
                            svcNo={svcNo}
                            svcName={svcName}
                            subAccountEditModal={subAccountEditModal}
                            setSubAccountEditModal={setSubAccountEditModal}
                            subAccountEditTarget={subAccountEditTarget}
                            setSubAccountEditTarget={setSubAccountEditTarget}
                            setSubAccountData={setSubAccountData}
                        />
                    :
                        null
                }
                {
                    subAccountDetail
                    ?
                        <SubAccountDetail 
                            subAccountDetail={subAccountDetail}
                            setSubAccountDetail={setSubAccountDetail}
                            subAccountDetailData={subAccountDetailData}
                        />
                    :
                        null
                }
                <SimpleModal
                    simpleTextModal={simpleTextModal}
                    setSimpleTextModal={setSimpleTextModal}
                    subAccountData={subAccountData}
                    checkedSubAccount={checkedSubAccount}
                    setSubAccountData= {setSubAccountData}
                    svcNo = {svcNo}
                    >{simpleText}</SimpleModal>
            </>
        )
    }else{
        return <Loading />
    }
}

const SimpleModal = (props) => {
    const {children, simpleTextModal, setSimpleTextModal, subAccountData, checkedSubAccount, svcNo, setSubAccountData} = props;
    return(
        <div className="pop_set_c" style={simpleTextModal ? {display:'block'} : {display:'none'}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page">
                        <div className="ppheader">
                            <div className="htit">알림</div>
                            <div className="rclose" onClick={()=>{setSimpleTextModal(false)}}><img src={'/images/icon_pop_close.png'} alt="닫기"/></div>
                        </div>
                        <div>
                            <div className="s_u_p_tit">{children}</div>
                            <div className="s_u_p_btn">
                                <button
                                    className="login_as"
                                    onClick={()=>{
                                        setSimpleTextModal(false)
                                        deleteSubAccount(subAccountData.subAccountList[checkedSubAccount].accountNo).then(result=>{
                                            getSubAccount(svcNo).then(_result=>{setSubAccountData(_result)})
                                        })
                                    }}
                                >
                                    삭제
                                </button>
                                {/* <button
                                    className="login_as"
                                    onClick={()=>{
                                        setSimpleTextModal(false)
                                    }}
                                >
                                    취소
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceConsoleTab01;