import React from "react";

const Loading = (props) => {
    const {height, tableLoading} = props;
    if(tableLoading){
        return(
            <div style={{position:"relative", width:"100%", height: "60px",}}>
                <div className="loading" style={{width:"30px", height:"30px"}}>
                </div>
            </div>
        )
    }else{
        return(
            <div style={{position:"relative", width:"100%", height: height ? `${height}px` : `200px`}}>
                <div className="loading">
                </div>
            </div>
        )
    }
}

export default Loading;