import React, {useEffect, useState, useRef, useCallback} from "react";
import { Link, useLocation, useNavigate} from "react-router-dom";
import {
    checkNeedRecaptcha,
    getTwoFactorAuthList,
    login,
    sendTwoFactorAuthSMS,
    verifyRecaptcha
} from "../../Util/reqApi";
import { useGlobal } from "../../shared/globalContext";
import setMetaTags from "../../Util/setMetaTags";
import PageInfo from "../../page_info.json";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
    const global = useGlobal();
    const { login: loginState, setLogin} = global;

    const [userId, setUserId] = useState('');
    const [userPwd, setUserPwd] = useState('');
    const [saveId, setSaveId] = useState(false);
    const [twoFactor, setTwoFactor] = useState();
    const [twoFactorModal, setTwoFactorModal] = useState(false);
    const [callTwoFactor, setCallTwoFactor] = useState(false);
    const [loginReady, setLoginReady] = useState(false);
    const [authNo, setAuthNo] = useState();
    const [authKey, setAuthKey] = useState('');
    const [reCaptchaToken, setReCaptchaToken] = useState();
    const [useRecaptcha, setUseRecaptcha] = useState(false);


    let navigate = useNavigate();
    const location = useLocation();
    const userIdInput = useRef(null);
    const userPwdInput = useRef(null);

    const selectTwoFactor = useRef();


    useEffect(()=>{
        setMetaTags({
            title : PageInfo.login.title,
            description : PageInfo.login.description,
            keywords : PageInfo.login.keywords
        })
        const savedId = localStorage.getItem('API_STORE_ID');
        const savedIdChecker = localStorage.getItem('API_STORE_SAVE_ID_STATUS');
        if(savedIdChecker === 'true'){
            setSaveId(true)
            savedId && setUserId(savedId)
        }
    },[])

    useEffect(()=>{
        if(loginState){
            const previousPageURL = location.state?.from || '/';

            if(previousPageURL == '/mypage/account/changeProfile'){
                navigate(previousPageURL, {replace: true});
            }else {
                navigate('/', {replace: true});
            }
        }
    },[loginState])

    const handleLogin = async () => {

        //값 유효성 검증
        setAuthKey();
        setAuthNo();
        setTwoFactor();
        setCallTwoFactor(false);
        setLoginReady(false);
        if(!userId){
            alert('아이디를 입력해주세요');
            if(useRecaptcha){
                window.location.reload();
                return;
            }
            userIdInput.current.focus();
            return;
        }
        if(!userPwd){
            alert('비밀번호를 입력해주세요');
            if(useRecaptcha){
                window.location.reload();
                return;
            }
            userPwdInput.current.focus();
            return;
        }

        //리캡챠를 이미 시도하고 있고, 인증이 된 상태가 아니라면
        if(useRecaptcha){
            if(reCaptchaToken) {
                verifyRecaptcha(reCaptchaToken).then(result => {
                    if (result.code != 0) {
                        alert("유효하지 않은 ReCAPTCHA 동작입니다.")
                        window.location.reload();
                        return;
                    } else {
                        // console.log(result);    //리캡챠 통과되었고, 로그인 로직 시작
                        getTwoFactorAuthList(userId, userPwd).then(result => {
                            if (result.code == '2001') {
                                alert(result.data);
                                navigate('/unlockAccount');
                                return;
                            }
                            if (result.code == '1002') {
                                alert(result.data);
                                navigate('/userRecover');
                                return;
                            }
                            if (typeof (result.data) !== 'object') {
                                if (result.data === 'NO_DATA') {
                                    loginLast();
                                    return;
                                }
                                alert(result.data);
                                window.location.reload();
                                return;
                            }
                            setTwoFactor(result.data);
                            setTwoFactorModal(true);
                        })
                    }
                })
            }else{
                alert("ReCAPTCHA 인증을 시도해주세요");
                return;
            }
        }else{  //리캡챠를 시도하지 않고 있다면
            //리캡챠가 필요한지 체크하기
            checkNeedRecaptcha(userId).then(res => {
                setUseRecaptcha(res);
                if(res){
                    return; //캡챠 출현하고, 로그인 재시도 필요
                }else{
                    //리캡챠가 필요하지 않고, 로그인 로직 시작
                    getTwoFactorAuthList(userId, userPwd).then(result => {
                        if (result.code == '2001') {
                            alert(result.data);
                            navigate('/unlockAccount');
                            return;
                        }
                        if (result.code == '1002') {
                            alert(result.data);
                            navigate('/userRecover');
                            return;
                        }
                        if (typeof (result.data) !== 'object') {
                            if (result.data === 'NO_DATA') {
                                loginLast();
                                return
                            }
                            alert(result.data);
                            return null
                        }
                        setTwoFactor(result.data);
                        setTwoFactorModal(true);
                    })
                }
            })
        }
    }

    const handleReqTwoFactor = async () => {
        sendTwoFactorAuthSMS(selectTwoFactor.current.value, userId, userPwd).then( async result=>{
            if(result == 'ok'){
                alert('인증번호가 발신되었습니다.')
                setAuthNo(selectTwoFactor.current.value)
                setCallTwoFactor(true);
                setLoginReady(true)
                // loginLast();
            }else{
                alert(result)
                setAuthKey();
                setAuthNo();
                setTwoFactorModal(false)
                setTwoFactor();
                setCallTwoFactor(false);
                setLoginReady(false);
                window.location.reload();
                return;
            }
        })
    }

    const loginLast = async () => {
        login(authKey, authNo, userId, userPwd).then(result=>{
            if(result.code == 0){
                localStorage.setItem('API_STORE_ID', userId);
                localStorage.setItem('API_STORE_SAVE_ID_STATUS', saveId);
                localStorage.setItem('ACCESS_TOKEN', result.data.accessToken);
                localStorage.setItem('REFRESH_TOKEN', result.data.refreshToken);
                setLogin(true);
            }else{
                setAuthKey();
                setAuthNo();
                setTwoFactorModal(false)
                setTwoFactor();
                setCallTwoFactor(false);
                setLoginReady(false);
                alert(result.data);
                window.location.reload();
                return;
            }
        })
    }

    const handleReCAPTCHA = (val) => {
        if(val) setReCaptchaToken(val);
    }

    return(
        <div className="member_section">
            <h1 style={{display:"none"}}>{PageInfo.login.h1}</h1>
            <div className="mem_area">
                <h1 className="m_title">로그인</h1>
                <form className="input_section" onSubmit={(e)=>{e.preventDefault(); handleLogin()}}>
                    <div className="login_area">
                        <input type="text" placeholder="아이디" style={{width:"100%"}} onChange={(e)=>{setUserId(e.target.value)}} value={userId || ''} ref={userIdInput}/>
                        <input type="password" placeholder="패스워드" style={{width:"100%"}} onChange={(e)=>{setUserPwd(e.target.value)}} value={userPwd || ''} ref={userPwdInput}/>
                        {useRecaptcha ? <div style={{width:"100%",display:"flex",justifyContent:"center",marginTop:"20px"}}>
                            <ReCAPTCHA
                                sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                                onChange={handleReCAPTCHA}
                            />
                        </div> : ''}
                    </div>
                    <div className="ip_btn_area">
                        <button type="submit" className="login_1">로그인</button>
                    </div>
                    <div className="option_area1">
                        <div className="left_option">
                            <input id="idsave" type="checkbox" onChange={(e)=>{setSaveId(e.target.checked)}} checked={saveId}/>
                            <label htmlFor="idsave">아이디 저장</label>
                        </div>
                        <div className="right_option"><Link to="/passwordFind" replace={false}>비밀번호가 기억나지 않나요?</Link></div>
                    </div>
                    <div className="option_area2">
                        계정이 없으신가요? <button className="btnSignup" onClick={(e) => {
                            e.preventDefault();
                            braze.logCustomEvent("api_stop_signup_event");
                        }}
                    >회원가입</button>
                        <div className="tt_2">
                            고객님의 소중한 계정 정보를 보호하고자<br/>
                            2단계 로그인 서비스를 제공합니다.<br/>
                            [마이 페이지]-[회원 정보]에서 설정하실 수 있습니다.
                        </div>
                    </div>
                </form>
            </div>
            {
                (()=>{
                    if(twoFactorModal){
                        return(
                            <div className="pop_set" style={twoFactorModal ? {display:"block"} : {display:"none"}}>
                                <div className="pop_section">
                                    <div className="parea" style={{maxWidth : "400px"}}>
                                        <div className="page" style={{paddingBottom: "30px"}}>
                                            <div className="ppheader" style={{borderRadius:"10px 10px 0 0"}}>
                                                <div className="htit">2차 인증</div>
                                                <div className="rclose"
                                                    onClick={()=>{setTwoFactorModal(false)}}
                                                ><img src="/images/icon_pop_close.png" alt="닫기"/></div>
                                            </div>
                                            <div className="inputArea">
                                                <label style={{display:"block", marginBottom:"20px"}}>2차 인증 담당자 선택</label>
                                                <select ref={selectTwoFactor} style={{width:"100%", margin:"0 0 10px 0"}}>
                                                    {
                                                        (()=>{
                                                            if(twoFactor && twoFactor.length !== 0 && typeof(twoFactor) == 'object'){
                                                                return twoFactor.map(el=>{
                                                                    return <option key={el.authNo} value={el.authNo}>{el.name} | {el.tel}</option>
                                                                })
                                                            }else{
                                                                return null
                                                            }
                                                        })()
                                                    }
                                                </select>
                                            </div>
                                            {
                                                (()=>{
                                                    if(!callTwoFactor){
                                                        return null
                                                    }else{
                                                        return(
                                                            <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                                                                <input
                                                                    type="text"
                                                                    placeholder="인증번호 입력"
                                                                    style={{border: "1px solid #d9d9d9", borderRadius:"4px", width:"1000%"}}
                                                                    value={authKey}
                                                                    onChange={(e)=>{setAuthKey(e.target.value)}}
                                                                />
                                                                {/* <button className="btn_sm5" style={{width:"25%", margin:"0"}}>확인</button> */}
                                                            </div>
                                                        )
                                                    }
                                                })()
                                            }
                                            {
                                                (()=>{
                                                    if(!loginReady){
                                                        return(
                                                            <div className="btn_area" style={{marginTop:"30px"}}>
                                                                <button className="basic" onClick={handleReqTwoFactor}>인증번호 요청</button>
                                                            </div>
                                                        )
                                                    }else{
                                                        return(
                                                            <div className="btn_area" style={{marginTop:"30px"}}>
                                                                <button className="basic" onClick={loginLast}>로그인</button>
                                                            </div>
                                                        )
                                                    }
                                                })()
                                            }                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }else{
                        return null
                    }
                })()
            }
        </div>
    )
}

export default Login;