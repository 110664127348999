import { t } from 'i18next';
import React,{useEffect, useState} from 'react';
import Loading from '../Util/Loading';
import { getFooterData, getMainBanner } from '../Util/reqApi';

const MobileFooter = () => {
    const [footerData, setFooterData] = useState();
    const [subBn, setSubBn] = useState();    
    useEffect(()=>{
        getFooterData(t('langNo')).then(result => {
            setFooterData(result);
        })
        getMainBanner('S', t('langNo')).then(result=>{setSubBn(result)})
    },[])

    if(footerData){
        return(
            <>
                {/* sub banner section */}
                {(()=>{
                    if(subBn){
                        if(subBn.length == 0){return null}
                        return(
                            <div className="basic_section_1">
                                <div className="bg_img"><img src={`/uploads/banner/${subBn[0].mobileImageFileName}`} alt=""/></div>
                                <div className="banner_text">
                                    <div className="bt_area">
                                        {(()=>{
                                            if(subBn[0].imageLink !== ''){
                                                return <div className="title_1"><img src={subBn[0].imageLink} alt = ""/></div>
                                            }else return <div className="title_1">{subBn[0].subject}</div>
                                        })()}
                                        <div className="title_2">{subBn[0].content}</div>
                                        {(()=>{
                                            if(subBn[0].buttonText !== '' && subBn[0].buttonLink !== ''){
                                                return <div className='btn_area'><button className="basic banC" onClick={()=>{window.open(subBn[0].buttonLink)}}>{subBn[0].buttonText}</button></div>
                                            }else return null;
                                        })()}
                                        <div className="btn_area">                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }else return <Loading />
                })()}
                <div className="footer">
                    <div className="basic_section_2">
                        <div className="info_area">
                            <table className="table_type1">
                                <colgroup>
                                    <col width="*" />
                                    <col width="*" />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th><img src="/images/icon_talk.png" /> {t('footer.infor01_title')}</th>
                                        <td>{t('footer.infor01_desc')}</td>
                                    </tr>
                                    <tr>
                                        <th><img src="/images/icon_phone.png" /> {t('footer.infor02_title')}</th>
                                        <td>{t('footer.infor02_desc')}</td>
                                    </tr>
                                    <tr>
                                        <th><img src="/images/icon_email.png" /> {t('footer.infor03_title')}</th>
                                        <td>{t('footer.infor03_desc')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="company_info">
                        <div className="c_section">
                            <h1><img src="/images/logo_cjolive.png"/></h1>
                            <div className="btn_area">
                                <span><a href="#" onClick={(e) => {e.preventDefault()}}>{t('footer.term01')}</a></span>ㅣ
                                <span className="point"><a href="#" onClick={(e) => {e.preventDefault()}}>{t('footer.term02')}</a></span>ㅣ
                                <span><a href="#" onClick={(e) => {e.preventDefault()}}>{t('footer.term03')}</a></span>
                            </div>
                            <div className="c_info_area" dangerouslySetInnerHTML={{__html : footerData.footerText.replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}}></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }else return <Loading />
}

export default MobileFooter;