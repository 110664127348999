import React, {useState, useEffect} from "react";
import axios from "axios";
import Loading from "../../../Util/Loading";
import { t } from "i18next";

const CompanyListItem = () => {
    const [partnerItems, setPartnerItems] = useState();

    useEffect(()=>{
        getpartnerItems();
    },[])

    const getpartnerItems = async () => {
        const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/board/getPartner?langNo=${t('langNo')}&pageNumber=0&pageSize=9999`);
        result.data.code == 0 && setPartnerItems(result.data.data.partnerList);
    }

    if(partnerItems){
        return( partnerItems.map((partnerItem, idx) => 
            <li key={idx}>
                <div className="logo_box">
                    <img src={`/uploads/partner/${partnerItem.imageFileName}`} alt=""/>
                </div>
            </li>
        ) )
    }else{
        return <Loading />
    }
}

export default CompanyListItem;