import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

const SvcNeedApproval = (props) => {
    const {svcNeedApproval, setSvcNeedApprovalModal} = props;
    let navigate = useNavigate();

    return(
        <div className="pop_set_c" style={svcNeedApproval ? {display:'block'} : {display:'none'}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page">
                        <div className="ppheader">
                            <div className="htit">알림</div>
                            <div className="rclose" onClick={()=>{setSvcNeedApprovalModal(false)}}><img src={'/images/icon_pop_close.png'} alt="닫기"/></div>
                        </div>
                        <div>
                            <div className="s_u_p_tit">서비스 사용을 위해서는 정식 계정 승인이 필요합니다.</div>
                            <div className="s_u_p_btn">
                                <button
                                    className="login_as"
                                    onClick={()=>{
                                        setSvcNeedApprovalModal(false)
                                        navigate(`/mypage/account/changeProfile`)
                                    }}
                                >
                                    정식 계정 신청하기
                                </button>
                            </div>
                        </div>
                        <div className="nt_btn_area_p">
                            <button className="btn_sm4" onClick={()=>{setSvcNeedApprovalModal(false)}}>닫기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SvcNeedApproval;