import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import SubHeader from '../Common/Component/SubHeader';
import SubInfoArea from './Component/SubInfoArea';
import useSearchParams from '../../Util/useSearchParams';
import NoticeView from './noticeView';
import Loading from '../../Util/Loading';
import { t } from "i18next";
import { getNotice, getNoticeCategory, getSiteCategoryDetail } from '../../Util/reqApi';
import { isMobile } from 'react-device-detect';
import Pagination from './Component/Pagination';
import setMetaTags from '../../Util/setMetaTags';
import PageInfo from '../../page_info.json';

const Notice = (props) => {
    const {view} = props;
    const navigate = useNavigate();

    const { searchParams, withSearchParams } = useSearchParams();

    const [cateData, setCateData] = useState({});
    const [page, setPage] = useState(0);
    const [numPages, setNumPages] = useState(0)
    const [noticeCates, setNoticeCates] = useState([]);
    const [selectedCate, setSelectedCate] = useState(0);
    const [noticePosts, setNoticePosts] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [keywordTemp, setKeywordTemp] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    
    const pageLimit = 10;

    const getQueryString = (key) => new URLSearchParams(window.location.search).get(key);

    const initQuery = () => {
        setPage(getQueryString('page') || "")
        setSelectedCate(getQueryString('cate') || "")
        setKeyword(getQueryString('query') || "")
    }

    const getNoticePost = () => {
        // initQuery();
        setIsLoading(false);
        getNotice({
            langNo : t('langNo'),
            cateNo: selectedCate,
            query : keyword,
            pageSize : pageLimit,
            pageNumber : page
        })
        .then(result => {
            setNoticePosts([...result.data.noticeList]);
            setNumPages(Math.ceil(result.record / pageLimit))
            setIsLoading(true);
        })
    }

    useEffect(() => {
        setMetaTags({
            title : PageInfo.Notice.title,
            description : PageInfo.Notice.description,
            keywords : PageInfo.Notice.keywords 
        })

        getSiteCategoryDetail(4).then(result => { setCateData(result) })

        getNoticeCategory(t('langNo')).then(result => setNoticeCates(result))
        initQuery();

        return () => {
            setNoticePosts([]);
        }
    },[])

    useEffect(() => {
        if(page < 0) setPage(0);
        getNoticePost();
        navigate(
            withSearchParams(
                `/support/notice`,
                { set: {
                    page,
                    cate: selectedCate,
                    query: keyword
                }})
            ); 
    },[page, selectedCate, keyword])

    useEffect(()=>{
        getNoticePost();
        setKeyword();
        setKeywordTemp();
        navigate(
            withSearchParams(
                `/support/notice`,
                { set: {
                    cate: selectedCate,
                    query: null
                }})
            ); 
    }, [selectedCate])

    const notice = {
        subTitle : t('support.notice'),
        subDesc : t('support.notice_desc'),
        bgImgSrc : `${!isMobile ? cateData?.pcImageName ? `/uploads/banner/${cateData?.pcImageName}` : '' : cateData?.mobileImageName ? `/uploads/banner/${cateData?.mobileImageName}` : ''}`,
    }

    return(
        <>
            <h1 style={{display:"none"}}>{PageInfo.Notice.h1}</h1>
            <SubHeader
                subTitle = {notice.subTitle}
                subDesc = {notice.subDesc}
                bgImgSrc = {notice.bgImgSrc}
                noti_text = {true}
            />
            <SubInfoArea
                type="sub"
                cate="notice"
            />
            <div className="support_content">
                <div className="support_section">
                    <div className="top_area">
                        <form className="input_area_top" onSubmit={(e)=>{e.preventDefault(); setKeyword(keywordTemp)}}>
                            <input id="search" type="text" placeholder={t('support.placeHolder')} value={keywordTemp || ''} onChange={(e)=>{setKeywordTemp(e.target.value)}}/>
                            <label htmlFor="search" className="hidden">{t('support.placeHolder')}</label>
                            <button><img src={`/images/icon_search_1.png`} alt=""/></button>
                        </form>
                    </div>
                    <div className="tab Noti_tab">
                        <ul>
                            <li
                                className={selectedCate == 0 ? 'tab-link current' : 'tab-link'}
                                onClick={()=>{ setSelectedCate(0); setPage(0); }}
                            >
                                {t('support.all')}
                            </li>
                            { noticeCates && noticeCates.map((noticeCate,idx)=>{
                                return(
                                    <li
                                        key = {idx}
                                        className = {selectedCate == noticeCate.cateNo ? 'tab-link current' : 'tab-link'}
                                        onClick = {()=>{ setSelectedCate(noticeCate.cateNo); setPage(0); }}
                                    >
                                        <h3 style={{display:'none'}}>{t('support.notice')}</h3>
                                        {noticeCate.cateName}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    {
                        view
                        ?   <NoticeView selectedCate={selectedCate} />
                        :   <div id="tab-1" className="tab-content current">
                                <div className="mp_table">
                                    <table>
                                        <colgroup>
                                            <col width="30px" />
                                            <col width="60px" />
                                            <col width="*%" />
                                            <col width="150px" />
                                        </colgroup>
                                        <tbody>
                                            {
                                                isLoading
                                                ?
                                                noticePosts && noticePosts.map((noticePost,idx)=>{
                                                    return(
                                                        <tr key={idx}>
                                                            {
                                                                noticePost.fixed
                                                                ? <td><img src={`/images/icon_noti_top.png`} alt="상단 고정된 글"/></td>
                                                                : <td></td>
                                                            }
                                                            <td><img src={`/images/icon_noti_${noticePost.noticeCategory.cateNo}.png`} alt={noticePost.noticeCategory.cateName}/></td>
                                                            <td><Link to={`/support/noticeView/${noticePost.noticeNo}`}>{noticePost.subject}</Link></td>
                                                            <td>{noticePost.regDate[0]}{t('year')} {noticePost.regDate[1]}{t('month')} {noticePost.regDate[2]}{t('day')}</td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={4}>
                                                        <Loading />
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                    { numPages !== 0 && <Pagination data={{page, setPage, numPages}} /> }
                                </div>
                            </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Notice