import React from "react";
import { Link } from "react-router-dom";

function MenuH1(props){
    const {LinkTo , innerText, on} = props;
    return(
        <li className={`menu_h1`}>
            <Link to={LinkTo} className={`${on ? ' OnMn' : ''}`} lang="en">{innerText}</Link>
        </li>
    )
}

export default MenuH1;