import React,{ useState, useEffect } from 'react';
import { IMG_URL } from '../../config';
import Loading from '../../Util/Loading';
import { changeRelationStatus, deleteUserRelation, findMaster, getUserRelation, requestUserRelation } from '../../Util/reqApi';

const Group = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const [userRelation, setUserRelation] = useState()

    useEffect(()=>{
        getUserRelation().then(result => {
            setUserRelation(result)
        })
        return () => {setUserRelation();}
    },[])

    if(userRelation){
        return(
            <>
                <div className="table_area">
                    <div className="script_b">
                        마스터 계정은 서브 계정의 사용 내역을 조회 할 수 있습니다.<br />
                        [그룹 연결 신청]을 눌러 사용 내역을 조회를 허용할 마스터 계정을 선택할 수 있습니다.
                    </div>
                    <div className="script_c">마스터</div>
                    <table className="table_mypage">
                        <colgroup>
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>ID</th>
                                <th>이름</th>
                                <th>이메일</th>
                                <th>휴대폰</th>
                                <th>신청일/신청상태</th>
                                <th>삭제</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (()=>{
                                    if(userRelation.masterList.length == 0){
                                        return(
                                            <tr>
                                                <td colSpan={7}>데이터가 없습니다.</td>
                                            </tr>
                                        )
                                    }else{
                                        return(
                                            userRelation.masterList.map((el,idx)=>{
                                                return(
                                                    <tr key={idx}>                 
                                                        <td>{idx}</td>
                                                        <td>{el.userId}</td>
                                                        <td>{el.name}</td>
                                                        <td>{el.email}</td>
                                                        <td>{el.phone}</td>
                                                        <td>
                                                            {(()=>{
                                                                if(el.status == "WAIT"){
                                                                    return "승인대기"
                                                                }else return `${el.regidate[0]}-${el.regidate[1]}-${el.regidate[2]} 승인 완료`
                                                            })()}
                                                            
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn_sm"
                                                                onClick={(e)=>{
                                                                    deleteUserRelation(el.relationNo, 'MASTER')
                                                                    .then(()=>{
                                                                        getUserRelation().then(result => setUserRelation(result))
                                                                    })}
                                                                }
                                                            >
                                                                삭제
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    }
                                })()                     
                            }
                        </tbody>
                    </table>
                </div>
                <div className="table_area">
                    <div className="script_c">서브 ({userRelation.slaveList.length}명의 연결된 사용자가 있습니다.)</div>
                    <table className="table_mypage">
                        <colgroup>
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                            <col width="*" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>번호</th>
                                <th>ID</th>
                                <th>이름</th>
                                <th>이메일</th>
                                <th>휴대폰</th>
                                <th>신청일</th>
                                <th>승인</th>
                                <th>삭제</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (()=>{
                                    if(userRelation.slaveList.length == 0){
                                        return(
                                            <tr>
                                                <td colSpan={8}>데이터가 없습니다.</td>
                                            </tr>
                                        )
                                    }else{
                                        return(
                                            userRelation.slaveList.map((el,idx)=>{
                                                return(
                                                    <tr key={idx}>
                                                        <td>{idx}</td>
                                                        <td>{el.userId}</td>
                                                        <td>{el.name}</td>
                                                        <td>{el.email}</td>
                                                        <td>{el.phone}</td>
                                                        <td>{el.regidate[0]}-{el.regidate[1]}-{el.regidate[2]}</td>
                                                        <td>
                                                            <div
                                                                className={el.status == 'WAIT' ? "toggle_btn" : "toggle_btn active"}
                                                                onClick={(e)=>{
                                                                    e.target.closest('div').classList.toggle('active')
                                                                    changeRelationStatus(el.relationNo, el.status == 'WAIT' ? 'ACTIVE' : 'WAIT').then(
                                                                        ()=>getUserRelation().then(result => setUserRelation(result))
                                                                    )                                                                    
                                                                }}
                                                            >
                                                                <input type="checkbox" className="cb-value" />
                                                                <span className="round-btn"></span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button
                                                                className="btn_sm"
                                                                onClick={(e)=>{
                                                                    deleteUserRelation(el.relationNo, 'SLAVE')
                                                                    .then(()=>{
                                                                        getUserRelation().then(result => setUserRelation(result))
                                                                    })}
                                                                }
                                                            >
                                                                삭제
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        )
                                    }
                                })()
                            }
                        </tbody>
                    </table>
                </div>
                <div className="ip_btn_area2">
                    <button
                        style={{width:"200px"}}
                        className="login_1"
                        onClick={()=>{setModalOpen(true)}}
                    >그룹 연결 신청</button>
                </div>

                {/* 팝업 */}
                { modalOpen && <GroupModal modalOpen={modalOpen} setModalOpen={setModalOpen} setUserRelation={setUserRelation}/> }                
            </>
        )
    }else{
        return <Loading />
    }
}

const GroupModal = (props) => {
    const {modalOpen, setModalOpen, setUserRelation} = props;
    const [masterChk, setMasterChk] = useState(false);
    const [userId, setUserId] = useState();
    const [fixedUserId, setFixedUserId] = useState();
    

    const findMasterId = (userId) => {
        findMaster(userId).then(result => {
            if(result && result == 'ok'){
                setMasterChk(true)
                setFixedUserId(userId)
            }else{
                alert(`${userId} 아이디가 조회되지 않습니다.`)
                setMasterChk(false)
                setFixedUserId()
            }
        })
    }

    return(
        <div id="" className="pop_set_b" style={modalOpen ? {display:"block"} : {display:"none"}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page">
                        <div className="ppheader">
                            <div className="htit">그룹 신청</div>
                            <div className="rclose" onClick={()=>{setModalOpen(false)}}><img src={`/images/icon_pop_close.png`} alt="닫기"/></div>
                        </div>
                        <div className="gr_pp_serch">
                            <span>마스터 아이디 검색</span>
                            <input
                                type="text"
                                placeholder="ID 입력"
                                value={userId}
                                onChange={(e)=>{setUserId(e.target.value)}}
                            />
                            <button
                                className="btn_sm6"
                                onClick={()=>{
                                    if(userId !== ''){
                                        findMasterId(userId)
                                    }else{
                                        alert('검색할 아이디를 입력해주세요.')
                                    }
                                }}
                            >
                                검색
                            </button>
                        </div>
                        {
                            masterChk
                            ?
                                <div className="gr_pp_id_tt">
                                    마스터 “<span>{fixedUserId}</span>” 님께 그룹 신청을 하시겠습니까?
                                </div>
                            :
                                null
                        }
                        <div className="nt_btn_area_p">
                            <button className="btn_sm4" onClick={()=>{
                                setUserId()
                                setFixedUserId()
                                setModalOpen(false);
                            }}>취소</button>
                            {
                                masterChk
                                ?
                                <button className="btn_sm6" onClick={()=>{
                                    requestUserRelation(fixedUserId).then(result => {
                                        if(result == 'ok'){
                                            alert('정상적으로 신청되었습니다.')
                                            getUserRelation().then(result => setUserRelation(result))
                                        }else{
                                            alert(result)
                                        }
                                    })
                                    setModalOpen(false)
                                }}>신청하기</button>
                                :
                                null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Group;