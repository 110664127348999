import React, { useEffect, useState, useRef } from 'react';
import { changePasswordWithAuth, checkIdandPw, checkPassword, getUserAuthParameter, openKmcert, recoverUser } from '../../Util/reqApi';
import { useNavigate } from 'react-router-dom';
import setMetaTags from '../../Util/setMetaTags';
import PageInfo from '../../page_info.json';

const PasswordFind = ({unlock, recover}) => {
    const [tr_cert, set_tr_cert]  = useState();
    const [tr_url, set_tr_url ] = useState();
    const [cert, setCert] = useState(false);
    const [userId, setUserId] = useState();
    const [newUserPwd, setNewUserPwd] = useState('');
    const [newUserPwdConfirm, setNewUserPwdConfirm] = useState('');

    let navigate = useNavigate();
    const reqKMCISForm = useRef();
    const resKMCISForm = useRef();

    if(!recover){
        setMetaTags(
            unlock?
            {
                title : PageInfo.unlockAccount.title,
                description : PageInfo.unlockAccount.description,
                keywords : PageInfo.unlockAccount.keywords
            }
            :
            {
                title : PageInfo.passwordFind.title,
                description : PageInfo.passwordFind.description,
                keywords : PageInfo.passwordFind.keywords
            }
        )

        return (        
            <div className="member_section">
                <h1 style={{display:"none"}}>{unlock ? PageInfo.unlockAccount.h1 : PageInfo.passwordFind.h1}</h1>
                <div className="mem_area">
                    <div className="m_title">{ unlock ? "계정 잠금 풀기" : "비밀번호 변경"}</div>
                    {/* <div className="login2_tt1">
                        가입한 이메일 주소를 입력해 주세요.<br/>
                        임시비밀 번호를 가입하신 휴대폰으로 보내드립니다.
                    </div> */}
                    <div className="input_section">
                        {/* <div className="login_area">
                            <input type="text" placeholder="이메일" style={{width:"100%"}}/>
                        </div> */}
                        <div className="login_area">
                            {(()=>{
                                if(cert){
                                  return(
                                      <form>
                                        <input type="text" placeholder='아이디를 입력하세요' value={userId} onChange={(e)=>{setUserId(e.target.value)}} style={{width:"100%"}}/>
                                        <input type="password" placeholder='변경할 비밀번호를 입력하세요' value={newUserPwd} onChange={(e)=>{setNewUserPwd(e.target.value)}} style={{width:"100%"}} />
                                        <input type="password" placeholder='다시 한번 비밀번호를 입력하세요' value={newUserPwdConfirm} onChange={(e)=>{setNewUserPwdConfirm(e.target.value)}} style={{width:"100%"}} />
                                        <button
                                            className="login_1"
                                            onClick={(e)=>{
                                              e.preventDefault();
                                              if(!checkPassword(newUserPwd, userId)){alert('비밀번호는 8자 이상, 16자 이하의 영문자, 숫자 및 특수문자를 조합하여 사용해야 합니다.'); return}
                                              if(!checkIdandPw(userId, newUserPwd)){alert('ID와 유사한 비밀번호는 사용할 수 없습니다.'); return}
                                              if(newUserPwd !== newUserPwdConfirm){alert('입력한 비밀번호와 비밀번호 확인이 일치하지 않습니다.'); return}
                                              changePasswordWithAuth(
                                                  document.querySelector('#certNum').value,
                                                  newUserPwd,
                                                  document.querySelector('#rec_cert').value,
                                                  userId
                                              ).then(result => {
                                                if(result == 'ok'){
                                                  alert('비밀번호가 변경되었습니다.\n로그인 해주세요.')
                                                  navigate('/login', {replace: true});
                                                }else{
                                                  alert(result)
                                                }
                                              })
                                            }}
                                        >확인</button>
                                      </form>
                                  )
                                }else{
                                    return(
                                        <button
                                        className="login_1"
                                        onClick={()=>{
                                            getUserAuthParameter({callbackUrl:`${window.location.origin}/mobileCheckResult`, type:'findPassword'}).then(result => {
                                                set_tr_cert(result.tr_cert);
                                                set_tr_url(result.tr_url);
                                                openKmcert(reqKMCISForm.current);
                                            })
                                        }}
                                    >본인인증</button>      
                                    )
                                }
                            })()}
                        </div>
                    </div>
                </div>
                <form name="reqKMCISForm" method="post" action="#" ref={reqKMCISForm}>
                    <input type="hidden" name="tr_cert" value={tr_cert} />
                    <input type="hidden" name="tr_url"  value={tr_url} />
                </form>
                <form name="resKMCISForm" ref={resKMCISForm} className="em_ligin">
                    <input type="hidden" id="rec_cert" style={{marginBottom: "20px"}} onClick={()=>{setCert(true)}} />
                    <input type="hidden" id="certNum" />
                </form>
            </div>
        );
    }else{
        setMetaTags({
            title : PageInfo.userRecover.title,
            description : PageInfo.userRecover.description,
            keywords : PageInfo.userRecover.keywords
        })

        return (        
            <div className="member_section">
                <h1 style={{display:"none"}}>{PageInfo.userRecover.h1}</h1>
                <div className="mem_area">
                    <div className="m_title">휴면 해제</div>
                    <div className="input_section">
                        <div className="login_area">
                            {(()=>{
                                if(cert){
                                    return(
                                        <form>
                                            <input type="text" placeholder='아이디를 입력하세요' value={userId} onChange={(e)=>{setUserId(e.target.value)}} style={{width:"100%"}}/>
                                            <button
                                                className="login_1"
                                                onClick={(e)=>{
                                                    e.preventDefault();
                                                    recoverUser({
                                                        id : userId,
                                                        kCertNum : document.querySelector('#certNum').value,
                                                        recCert : document.querySelector('#rec_cert').value,
                                                    }
                                                    ).then(result => {
                                                        if(result == 'ok'){
                                                            alert('휴면상태가 해제되었습니다.\n다시 로그인 해주세요.')
                                                            navigate('/login', {replace: true});
                                                        }else{
                                                            alert(result)
                                                        }
                                                    })
                                                }}
                                            >휴면 해제</button>
                                        </form>
                                    )
                                }else{
                                    return(
                                        <button
                                        className="login_1"
                                        onClick={()=>{
                                            getUserAuthParameter({callbackUrl:`${window.location.origin}/mobileCheckResult`, type:'findPassword'}).then(result => {
                                                set_tr_cert(result.tr_cert);
                                                set_tr_url(result.tr_url);
                                                openKmcert(reqKMCISForm.current);
                                            })
                                        }}
                                    >본인인증</button>
                                    )
                                }
                            })()}
                        </div>
                    </div>
                </div>
                <form name="reqKMCISForm" method="post" action="#" ref={reqKMCISForm}>
                    <input type="hidden" name="tr_cert" value={tr_cert} />
                    <input type="hidden" name="tr_url"  value={tr_url} />
                </form>
                <form name="resKMCISForm" ref={resKMCISForm} className="em_ligin">
                    <input type="hidden" id="rec_cert" style={{marginBottom: "20px"}} onClick={()=>{setCert(true)}} />
                    <input type="hidden" id="certNum" />
                </form>
            </div>
        );
    }
}

export default PasswordFind;