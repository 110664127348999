import React, {useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import SubHeader from '../Common/Component/SubHeader';
import SubInfoArea from './Component/SubInfoArea';
import useSearchParams from '../../Util/useSearchParams';
import Loading from '../../Util/Loading';
import { t } from "i18next";
import { getFaq, getFaqCategory, getSiteCategoryDetail } from '../../Util/reqApi';
import { isMobile } from 'react-device-detect';
import setMetaTags from '../../Util/setMetaTags';
import PageInfo from '../../page_info.json';

const Faq = () => {
    const [tabTitle, setTabTitle] = useState({});
    const [cateData, setCateData] = useState({});
    const [cates, setCates] = useState();
    const [selectCate, setSelectCate] = useState({cateName:'전체', cateNo: 0})
    const [faqItems, setFaqItems] = useState();
    const [keyword, setKeyword] = useState();
    const [keywordTemp, setKeywordTemp] = useState();

    let navigate = useNavigate();
    const { searchParams, withSearchParams } = useSearchParams();

    const getQueryString = (key) => { return new URLSearchParams(window.location.search).get(key); }

    const getFaqCategorys = () => {
        getFaqCategory(t('langNo')).then(result => {
            setCates(result)
            if(getQueryString('cate')){
                for(const element of result){
                    if(element.cateName == getQueryString('cate')){
                        setSelectCate(element)
                    }
                }
            }else{
                setSelectCate({cateName:t('support.all'), cateNo: 0})
            }
        })
    }

    
    const getFaqPost = async () => {
        getFaq({cateNo : selectCate.cateNo, langNo : t('langNo'), pageNumber : 0, pageSize : 9999, query : keyword})
        .then(result => { setFaqItems(); setFaqItems(result.faqList); })
    }

    useEffect(()=>{
        setMetaTags({
            title : PageInfo.Faq.title,
            description : PageInfo.Faq.description,
            keywords : PageInfo.Faq.keywords
        })
        getSiteCategoryDetail(4).then(result => { setCateData(result) })
        getFaqCategorys();
        if(getQueryString('cate')){
            if(cates){
                for(const element of cates){
                    if(element.cateName == getQueryString('cate')){
                        setSelectCate(element)
                    }
                }
            }
        }
        if(getQueryString('query')){setKeyword(getQueryString('query'))}
        getFaqPost();
    },[])

    useEffect(()=>{
        getFaqPost();
        if(typeof(selectCate) == 'string'){
            navigate(withSearchParams(`/support/faq`, { set: { cate: selectCate, query: keyword }}));
        }else{
            navigate(withSearchParams(`/support/faq`, { set: { cate: selectCate.cateName, query: keyword }}));
        }
    },[selectCate, keyword])

    useEffect(()=>{ setKeywordTemp(''); setKeyword(''); },[selectCate])

    const faq = {
        subTitle : t('support.faq'),
        subDesc : t('support.faq_desc'),
        bgImgSrc : `${!isMobile ? cateData?.pcImageName ? `/uploads/banner/${cateData?.pcImageName}` : '' : cateData?.mobileImageName ? `/uploads/banner/${cateData?.mobileImageName}` : ''}`,
    }


    return(
        <>
        <h1 style={{display:"none"}}>{PageInfo.Faq.h1}</h1>
            <SubHeader
                subTitle = {faq.subTitle}
                subDesc = {faq.subDesc}
                bgImgSrc = {faq.bgImgSrc}
                noti_text = {true}
            />
            <SubInfoArea
                type="sub"
                cate="faq"
            />
            <div className="support_content">
                <div className="support_section">
                    <div className="top_area">
                        <form className="input_area_top" onSubmit={(e)=>{e.preventDefault(); setKeyword(keywordTemp)}}>
                            <input id="search" type="text" placeholder={t('support.placeHolder')} onChange={(e)=>{setKeywordTemp(e.target.value)}} value={keywordTemp || ''}/>
                            <label htmlFor="search" className="hidden">{t('support.placeHolder')}</label>
                            <button type="submit"><img src={`/images/icon_search_1.png`} alt=""/></button>
                        </form>
                    </div>
                    <div className="tab Noti_tab">
                        {
                            cates
                            ?   <ul>
                                    <h3 style={{display:'none'}}>{t('support.inquiry')}</h3>
                                    <li
                                        className={selectCate.cateNo === 0 ? 'current' : undefined}
                                        onClick={()=>{setSelectCate({cateName:t('support.all'), cateNo:0})}}
                                    >
                                        {t('support.all')}
                                    </li>
                                        {
                                        cates.map((cate ,idx) => {
                                                return(
                                                    <li
                                                        key={idx}
                                                        className={selectCate.cateNo === cate.cateNo ? 'current' : undefined}
                                                        onClick={()=>{setSelectCate(cate)}}
                                                    >
                                                        {cate.cateName}
                                                    </li>
                                                )
                                            })
                                        }
                                </ul>
                            :   <Loading />
                        }
                    </div>
                    <div className="tab-content current">
                        <div className="faq_section">
                            <div className="faq_header">
                                <div className="cate">{t('fractionation')}</div>
                                <div className="que">{t('question')}</div>
                            </div>
                                {
                                    faqItems
                                    ?   faqItems.map((postItem , idx) => {
                                        return(
                                            <FaqBlock postItem={postItem} key={idx}/>
                                        )
                                    })
                                    :   <Loading />
                                }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const FaqBlock = (props) => {
    const {postItem , idx, cates} = props;
    const [open, setOpen] = useState(false);
    
    const faqBox = useRef()

    return(
        <div
            className="faq_box"
            onClick={()=>{faqBox.current.classList.toggle('on')}}
            ref={faqBox}
        >
            <div className="faq_inheader">
                <div className="cate">{postItem.faqCategory.cateName}</div>
                <div className="que">{postItem.question}</div>
            </div>
            <div className="faq_inInfop">
                <div
                    className="ttBox"
                    dangerouslySetInnerHTML={{__html: postItem.answer.replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL) }}
                />
            </div>
        </div>
    )
}

export default Faq