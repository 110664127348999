import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import SubHeader from '../Common/Component/SubHeader';
import SubInfoArea from './Component/SubInfoArea';
import { getInquiryDetail, getInquiryList, getSiteCategoryDetail } from '../../Util/reqApi';
import Loading from '../../Util/Loading';
import { t } from 'i18next';
import { isMobile } from 'react-device-detect';
import { useGlobal } from '../../shared/globalContext';
import setMetaTags from '../../Util/setMetaTags';
import PageInfo from '../../page_info.json';

const RequestsSearch = () => {
    const global = useGlobal();
    const { login, setLogin } = global;

    const [cateData, setCateData] = useState({});
    const [useNomemberReq, setUseNomemberReq] = useState(true);
    const [noMemberEmail, setNoMemberEmail] = useState(false);
    const [viewReq, setViewReq] = useState(false);
    const [viewData, setViewData] = useState();

    useEffect(()=>{
        setMetaTags({
            title : PageInfo.RequestSearch.title,
            description : PageInfo.RequestSearch.description,
            keywords : PageInfo.RequestSearch.keywords
        })
        isMobile && setLogin(false);
        getSiteCategoryDetail(4).then(result => { setCateData(result) })
    },[])


    const requestsSearch = {
        subTitle : t('support.inquiry_a'),
        subDesc : t('support.inquiry_a_desc'),
        bgImgSrc : `${!isMobile ? cateData?.pcImageName ? `/uploads/banner/${cateData?.pcImageName}` : '' : cateData?.mobileImageName ? `/uploads/banner/${cateData?.mobileImageName}` : ''}`,
    }

    return(
        <>
        <h1 style={{display:"none"}}>{PageInfo.Requests.h1}</h1>
            <SubHeader
                subTitle = {requestsSearch.subTitle}
                subDesc = {requestsSearch.subDesc}
                bgImgSrc = {requestsSearch.bgImgSrc}
                noti_text = {true}
            />
            <SubInfoArea
                type="sub"
                cate="requestsSearch"
            />
            <div className="support_content">
                {
                    (()=>{
                        if(viewReq){
                            return <ReqViewer viewData={viewData} setViewData={setViewData} setViewReq={setViewReq}/>
                        }else{
                            if(t('langNo') !== '1'){
                                if(noMemberEmail){
                                    // 입력된 비회원 이메일이 있을 경우
                                    return <NoMemberReqList noMemberEmail={noMemberEmail} setViewReq={setViewReq} setViewData={setViewData}/>
                                }else{
                                    // 입력된 비회원 이메일이 없을 경우
                                    return <NoMemberReqEmail setNoMemberEmail={setNoMemberEmail}/>
                                }
                            }
                            if(login){
                                // 로그인 상태일 경우
                                return <MemberReqList setViewReq={setViewReq} setViewData={setViewData}/>
                            }else{
                                // 로그인 상태가 아닐 경우
                                if(useNomemberReq){
                                    // 비회원 문의를 사용 할 경우 이메일 입력 받기
                                    if(noMemberEmail){
                                        // 입력된 비회원 이메일이 있을 경우
                                        return <NoMemberReqList noMemberEmail={noMemberEmail} setViewReq={setViewReq} setViewData={setViewData}/>
                                    }else{
                                        // 입력된 비회원 이메일이 없을 경우
                                        return <NoMemberReqEmail setNoMemberEmail={setNoMemberEmail}/>
                                    }
                                }else{
                                    // 비회원 문의 사용 체크 X
                                    return <ReqLogin setUseNomemberReq={setUseNomemberReq}/>
                                }                            
                            }
                        }
                    })()
                }
            </div>
        </>
    )
}

const ReqLogin = (props) => {
    const {setUseNomemberReq} = props;
    const navigate = useNavigate();

    return(
        // 회원로그인
        <div className="support_section">
            <div className="req_login">
                <div className="l_tit">
                    로그인 후 문의하시면<br/> 문의 내역을 더 정확한 상담을 받을 수 있습니다.
                </div>
                <div className="l_btn">
                    <button className="basic banC" onClick={()=>{navigate('/login')}}>로그인</button>
                </div>
                <div className="l_script">
                    로그인을 할 수 없을 때는 <button onClick={()=>{setUseNomemberReq(true)}}>[비회원 문의]</button>를 선택하세요.
                </div>
            </div>
        </div>
    )
}

const NoMemberReqEmail = (props) => {
    const {setNoMemberEmail} = props;    
    const [email, setEmail] = useState('')

        return(
            // 비회원로그인
            <form
                className="support_section"
                onSubmit={(e)=>{
                    if(email){
                        e.preventDefault();
                        setNoMemberEmail(email)
                    }else{
                        alert(t('alert.alert02'))
                    }
                }}
            >
                <div className="req_login">
                    <div className="l_tit">
                        {t('support.emailForm')}
                    </div>
                    <div className="em_ligin">
                        <input type="text" placeholder={t('support.emailFormPlaceHolder')} value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                    </div>
                    <div className="l_btn">
                        <button className="basic banC" type='submit'>{t('support.inquiry_a')}</button>
                    </div>
                    <div className="l_script">
                        {t('support.emailFormDesc')}
                    </div>
                </div>
            </form>
        )
}

const MemberReqList = (props) => {
    const {setViewReq, setViewData} = props;
    const [page, setPage] = useState(0);
    const [pagelimit, setPagelimit] = useState(10)
    const [total, setTotal] = useState();
    const [numPages, setNumPages] = useState()
    const [memberReqList, setMemberReqList] = useState();

    const navigate = useNavigate();
    useEffect(()=>{
        getInquiryList(null, page, pagelimit).then(result => {
            setTotal(result.record)
            setMemberReqList(result.data)
        })
    },[])

    const calcNumpage = () => {
        setNumPages(Math.ceil(total / pagelimit))
    }

    useEffect(()=>{
        calcNumpage();
    },[memberReqList])

    if(memberReqList){
        return(
            // 회원으로 로그인 되었을 경우 리스트
            <div className="support_section">
                <div className="ur_tit_pp">{t('support.reqFormTitle')}</div>
                <div className="mp_table_pp">
                    <table>
                        <colgroup>
                            <col width="10%" />
                            <col width="*%" />
                            <col width="150px" />
                        </colgroup>
                        <tbody>
                            {
                                memberReqList.map((el,idx)=>{
                                    return(
                                        <tr key={idx}>
                                            <td>{el.inquiryCategory.cateName}</td>
                                            <td
                                                onClick={()=>{
                                                    getInquiryDetail(el.email, el.inquiryNo)
                                                        .then(result =>{
                                                            if(result == t(alert.alert11)){
                                                                alert(result)
                                                            }else{
                                                                setViewData(result)
                                                                setViewReq(true)
                                                            }
                                                        }
                                                    )
                                                }}
                                                style={{cursor:'pointer'}}>{el.content}</td>
                                            <td>{
                                                (()=>{
                                                    if(el.status == 1){
                                                        return t('support.wait')
                                                    }else if(el.status == 2){
                                                        return t('support.complete')
                                                    }
                                                })()
                                            }</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {
                        numPages !== 0
                        ?
                        <div className="paging">
                            <div className="ps_area">                                
                                <span className={page == 0 ? 'disable imgb' : 'imgb'} onClick={()=>{page !== '0' && setPage(0)}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_first.png`} alt="맨 처음으로"/></a></span>
                                <span className={page == 0 ? 'disable imgb' : 'imgb'} onClick={()=>{page !== '0' && setPage(page-1)}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_pre.png`} alt="이전으로"/></a></span>
                                {
                                    numPages && Array(numPages).fill().map((_, idx)=>{
                                        return(                                                
                                            <span
                                                key={idx}
                                                className={page == idx ? 'on' : ''}
                                                onClick={()=>{setPage(idx)}}
                                            >
                                                <a href="#" onClick={(e) => {e.preventDefault()}} title="현재 페이지">{idx + 1}</a>
                                            </span>
                                        )
                                    })
                                }
                                <span className={page == numPages-1 ? 'disable imgb' : 'imgb'} onClick={()=>{page !== numPages && setPage(page+1)}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_next.png`} alt="다음으로"/></a></span>
                                <span className={page == numPages-1 ? 'disable imgb' : 'imgb'} onClick={()=>{page !== numPages && setPage(numPages-1)}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_last.png`} alt="맨 마지막으로"/></a></span>
                            </div>
                        </div>
                        : undefined
                    }
                </div>
                <div className="nt_btn_area">
                    <button className="type_3" onClick={()=>{navigate('/support/requests')}}>{t('support.reqSubmit')}</button>
                </div>
            </div>
        )
    }else{
        return <Loading />
    }
}

const NoMemberReqList = (props) => {
    const {setViewReq, noMemberEmail, setViewData} = props;
    const [nomemberReqList, setNomemberReqList] = useState();
    const [page, setPage] = useState(0);
    const [pagelimit, setPagelimit] = useState(10)
    const [total, setTotal] = useState();
    const [numPages, setNumPages] = useState()
    const [memberReqList, setMemberReqList] = useState();

    const calcNumpage = () => {
        setNumPages(Math.ceil(total / pagelimit))
    }

    useEffect(()=>{
        calcNumpage();
    },[memberReqList])


    const navigate = useNavigate();

    useEffect(()=>{
        getInquiryList(noMemberEmail, 0, 10).then(result => setNomemberReqList(result))
    },[noMemberEmail])

    if(nomemberReqList){
        return(
            //비회원으로 로그인 되었을 경우 리스트
                <div className="support_section">
                    <div className="ur_tit_pp">{t('support.reqFormTitle')}</div>
                    <div className="mp_table_pp">
                        <table>
                            <colgroup>
                                <col width="10%" />
                                <col width="*%" />
                                <col width="150px" />
                                <col width="27%" />
                            </colgroup>
                            <tbody>
                                {
                                    (()=>{
                                        if(nomemberReqList.data.length == 0){
                                            
                                        }else{
                                            return(
                                                nomemberReqList.data.map((el,idx)=>{
                                                    return(
                                                        <NomemberInquiryBlock key={idx} setViewData={setViewData} setViewReq={setViewReq} content={el} />
                                                    )
                                                })
                                            )
                                        }
                                    })()
                                }
                            </tbody>
                        </table>
                        {
                            numPages !== 0
                            ?
                            <div className="paging">
                                <div className="ps_area">                                
                                    <span className={page == 0 ? 'disable imgb' : 'imgb'} onClick={()=>{page !== 0 && setPage(0)}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_first.png`} alt="맨 처음으로"/></a></span>
                                    <span className={page == 0 ? 'disable imgb' : 'imgb'} onClick={()=>{page !== 0 && setPage(page-1)}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_pre.png`} alt="이전으로"/></a></span>
                                    {
                                        numPages && Array(numPages).fill().map((_, idx)=>{
                                            return(                                                
                                                <span
                                                    key={idx}
                                                    className={page == idx ? 'on' : ''}
                                                    onClick={()=>{setPage(idx)}}
                                                >
                                                    <a href="#" onClick={(e) => {e.preventDefault()}} title="현재 페이지">{idx + 1}</a>
                                                </span>
                                            )
                                        })
                                    }
                                    <span className={page == numPages-1 ? 'disable imgb' : 'imgb'} onClick={()=>{setPage(page+1)}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_next.png`} alt="다음으로"/></a></span>
                                    <span className={page == numPages-1 ? 'disable imgb' : 'imgb'} onClick={()=>{setPage(numPages-1)}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_last.png`} alt="맨 마지막으로"/></a></span>
                                </div>
                            </div>
                            : undefined
                        }
                    </div>
                    <div className="nt_btn_area">
                        <button className="type_3" onClick={()=>{navigate('/support/requests')}}>{t('support.reqSubmit')}</button>
                    </div>
                </div>
        )
    }else{
        return <Loading />
    }
}

const NomemberInquiryBlock = (props) => {
    const {content : el, setViewReq, setViewData} = props
    const [password, setPassword] = useState('');
    return(
        <tr>
            <td>{el.inquiryCategory.cateName}</td>
            <td>{el.subject}</td>
            <td>{
                (()=>{
                    if(el.status == 1){
                        return t('support.wait')
                    }else if(el.status == 2){
                        return t('support.complete')
                    }
                })()
            }</td>
            <td>
                {t('support.reqForm04')}
                <input type="password" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
                <button
                    className="btn_sm7"
                    onClick={()=>{
                        getInquiryDetail(el.email, el.inquiryNo, password)
                            .then(result => {
                                if(result == t('alert.alert11')){
                                    alert(result+'.')
                                }else{
                                    setViewData(result)
                                    setViewReq(true)
                                }
                            }
                        )
                    }}
                >{t('support.view')}</button>
            </td>
        </tr>
    )
}

const ReqViewer = (props) => {
    const {viewData, setViewReq, setViewData} = props;

    if(viewData){
        return(
            <div className="support_section">
                <div className="req_tit">
                {t('support.reqFormTitle02')}
                </div>
                <div className="">
                    <table className="table_Req">
                        {/* <colgroup>
                            <col width="100px" />
                            <col width="*%" />
                            <col width="*%" />
                            <col width="*%" />
                        </colgroup> */}
                        <tbody>
                            <tr>
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr>
                                <th>{t('support.reqForm01')}</th>
                                <td colSpan={3}>
                                    <input type="text" style={{width:"200px"}} placeholder="" defaultValue={viewData.inquiryCategory.cateName} readOnly={true}/>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('support.reqForm02')}</th>
                                <td>
                                    <input type="text" style={{width:"200px"}} placeholder="" defaultValue={viewData.email} readOnly={true}/>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>{t('support.reqForm03')}</th>
                                <td>
                                    <input type="text" style={{width:"200px"}} placeholder="" defaultValue={viewData.phone} readOnly={true}/>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr className="blankLine">
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr>
                                <th>{t('support.inquiry_title')}</th>
                                <td colSpan={3}>
                                    <input type="text" style={{width:"100%",maxWidth:"600px"}} placeholder="" defaultValue={viewData.subject} readOnly={true} />
                                </td>
                            </tr>
                            <tr>
                                <th>{t('support.inquiry_content')}</th>
                                <td colSpan={3}>
                                    <textarea type="text" defaultValue={viewData.content} style={{width:"100%",maxWidth:"600px",height:"150px", fontSize: "14px"}} placeholder="" readOnly={true}></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr className="blankLine">
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            {
                                (()=>{
                                    if(viewData.answerList.length !== 0){
                                        return(
                                            <tr>
                                                <th>{t('support.answers')}</th>
                                                <td colSpan={3}>
                                                    <div style={{width:"100%",maxWidth:"580px",height:"150px", border: "1px solid #dddddd", background: "#F7F7F7", fontSize: "14px", padding: "10px 10px", borderRadius:"5px", height:"150px", overflow: "auto"}} dangerouslySetInnerHTML={{__html:viewData.answerList[0].answer.replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}}></div>
                                                </td>
                                            </tr>
                                        )
                                    }else{
                                        return null;
                                    }
                                })()
                            }
                            <tr>
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr className="blankLine">
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="nt_btn_area">
                    <button className="type_3" onClick={()=>{setViewData(); window.location.href = ('/support/requests')}}>{t('support.reReqSubmit')}</button>
                    <button className="type_2" onClick={()=>{setViewReq(false); setViewData()}}>{t('cancle')}</button>
                </div>
            </div>
        )
    }else{
        return <Loading />
    }
}

export default RequestsSearch