import { getAccessTokenToLocal } from './reqApi';
import axios from 'axios';

const logout = async (setLogin) => {
    if (setLogin !== null) setLogin(false);
    const auth = getAccessTokenToLocal() ? `Bearer ${getAccessTokenToLocal()}` : null;
    await axios.post(process.env.REACT_APP_API_SERVER_URL+'/api/user/logout', null, {
        headers: {
            Authorization: auth,
        },
    }).then(res => {
        localStorage.removeItem('ACCESS_TOKEN');
        localStorage.removeItem('REFRESH_TOKEN');
    })
};

export default logout;
