import React, {useEffect, useState} from "react";
import SubHeader from "../Common/Component/SubHeader";
import { getSiteCategoryDetail } from "../../Util/reqApi";
import { t } from "i18next";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import setMetaTags from "../../Util/setMetaTags";
import PageInfo from "../../page_info.json";

const Partner = () => {
    const [ cateData, setCateData ] = useState({});

    useEffect(()=>{
        getSiteCategoryDetail(3).then(result => setCateData(result))
        setMetaTags({
            title : PageInfo.Partner.title,
            description : PageInfo.Partner.description,
            keywords : PageInfo.Partner.keywords 
        })

        return() => {
            setCateData();
            setMetaTags({});
        }
    },[])

    return (
        <>
            <h1 style={{display:"none"}}>{PageInfo.Partner.h1}</h1>
            <SubHeader
                subTitle = {cateData[t('subBanner.headingText')]}
                subDesc = {cateData[t('subBanner.content')]}
                bgImgSrc = {!isMobile ? cateData.pcImageName ? `/uploads/banner/${cateData.pcImageName}` : '' : cateData.mobileImageName ? `/uploads/banner/${cateData.mobileImageName}` : ''}
            />
            <div className="s_pt_mid_section">
                <div className="pt_tit_area">
                    <div className="tt_area">
                        <div className="tt_1" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">{t('new-partner.reason')}</div>
                        <div className="tt_2" data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" dangerouslySetInnerHTML={{__html: t('new-partner.reason-desc')}}></div>
                        <Link to="/support/apiAll" type="button" className="nm_btn_black big_btn" data-aos="fade-up" data-aos-duration="900" data-aos-delay="300">{t('new-partner.contact-us')}</Link>
                    </div>
                </div>
                <div className="pt_visual_area">
                    {/* 1 */}
                    <div className="vs_area_1">
                        <div className="vs_box">
                            <div className="img_area">
                                <div className="talk_box">
                                    <span dangerouslySetInnerHTML={{__html : t('new-partner.service-alliance-msg01')}}></span>
                                </div>
                                <div className="cha_img">
                                    <img src='/images/img_vs_man_1.png' alt=""/>
                                </div>
                            </div>
                            <div className="ip_tt_area">
                                <div className="tt_box">
                                    <div className="tt_1" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">{t('new-partner.service-alliance')}</div>
                                    <div className="tt_2" data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" dangerouslySetInnerHTML={{__html : t('new-partner.service-alliance-msg02')}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 1 */}
                    {/* 2 */}
                    <div className="vs_area_2">
                        <div className="vs_box">
                            <div className="img_area">
                                <div className="talk_box">
                                    <span dangerouslySetInnerHTML={{__html : t('new-partner.platform-business-alliance-msg01')}}></span>
                                </div>
                                <div className="cha_img">
                                    <img src='/images/img_vs_man_2.png' alt=""/>
                                </div>
                            </div>
                            <div className="ip_tt_area">
                                <div className="tt_box">
                                    <div className="tt_1" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">{t('new-partner.platform-business-alliance')}</div>
                                    <div className="tt_2" data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" dangerouslySetInnerHTML={{__html : t('new-partner.platform-business-alliance-msg02')}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 2 */}
                    {/* 3 */}
                    <div className="vs_area_1 second">
                        <div className="vs_box">
                            <div className="img_area">
                                <div className="talk_box">
                                    <span dangerouslySetInnerHTML={{__html : t('new-partner.market-place-register-msg01')}}></span>
                                </div>
                                <div className="cha_img">
                                    <img src='/images/img_vs_man_3.png' alt=""/>
                                </div>
                            </div>
                            <div className="ip_tt_area">
                                <div className="tt_box">
                                    <div className="tt_1" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">{t('new-partner.market-place-register')}</div>
                                    <div className="tt_2" data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" dangerouslySetInnerHTML={{__html : t('new-partner.market-place-register-msg02')}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 3 */}
                    {/* 4 */}
                    <div className="vs_area_2 second">
                        <div className="vs_box">
                            <div className="img_area">
                                <div className="talk_box">
                                    <span dangerouslySetInnerHTML={{__html : t('new-partner.api-business-consulting-msg01')}}></span>
                                </div>
                                <div className="cha_img">
                                    <img src='/images/img_vs_man_4.png' alt=""/>
                                </div>
                            </div>
                            <div className="ip_tt_area">
                                <div className="tt_box">
                                    <div className="tt_1" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">{t('new-partner.api-business-consulting')}</div>
                                    <div className="tt_2" data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" dangerouslySetInnerHTML={{__html : t('new-partner.api-business-consulting-msg02')}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 4 */}
                </div>
            </div>
            <div className="s_pt_fl_section">
                <div className="fl_area">
                    <div className="tit">{t('new-partner.partnership-registration')}</div>
                    <div className="flow_area">
                        <ol role="list">
                            <li role="listitem">
                                <div className="fl_box" data-aos="fade-up" data-aos-duration="900" data-aos-delay="100">
                                    <div className="icon"><img src='/images/icon_flow_t_1.png' alt=""/></div>
                                    <div className="tt_box">
                                        <div className="tt_1">{t('new-partner.partnership-proposal')}</div>
                                        <div className="tt_2">{t('new-partner.partnership-proposal-desc')}</div>
                                    </div>
                                </div>
                            </li>
                            <li role="listitem">
                                <div className="fl_box" data-aos="fade-up" data-aos-duration="900" data-aos-delay="300">
                                    <div className="icon"><img src='/images/icon_flow_t_2.png' alt=""/></div>
                                    <div className="tt_box">
                                        <div className="tt_1">{t('new-partner.review')}</div>
                                        <div className="tt_2">{t('new-partner.review-desc')}</div>
                                    </div>
                                </div>
                            </li>
                            <li role="listitem">
                                <div className="fl_box" data-aos="fade-up" data-aos-duration="900" data-aos-delay="500">
                                    <div className="icon"><img src='/images/icon_flow_t_3.png' alt=""/></div>
                                    <div className="tt_box">
                                        <div className="tt_1">{t('new-partner.partnership-agreement-registration')}</div>
                                        <div className="tt_2">{t('new-partner.partnership-agreement-registration-desc')}</div>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </div>
                    <div className="btn_area">
                        <Link to="/support/apiAll" type="button" className="nm_btn_black big_btn" >{t('new-partner.join-in')}</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Partner;