import { t } from 'i18next';
import React, {useRef, useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import Loading from '../../Util/Loading';
import setMetaTags from '../../Util/setMetaTags';
import PageInfo from '../../page_info.json';

const ModalTerm = forwardRef((props, ref) => {
    const {modalOpen, modalContent, type, setModalClose } = props
    const [selectedNum, setSelectedNum] = useState(0)
    const [listArr, setListArr] = useState([]);
    const [titleBackUp, setTitleBackup] = useState();

    const pageInfoMap = new Map([
        ["U", PageInfo.termsOfService],
        ["P", PageInfo.privacyPolicy],
        ["T", PageInfo.consent3rdPartyInfoDisclosure],
        ["O", PageInfo.OverseasTransferOfPInfo],
        ["C", PageInfo.CommercialInfo],
        ["A", PageInfo.UserPrivacyPolicyRequired],
        ["B", PageInfo.UserPrivacyPolicyOptional],
        ["Q", PageInfo.SupportInfo],
        ["F", PageInfo.ContentsDownloadInfo]
      ]);

    const translateMap = new Map([
        ["U",t('modal.termsOfService')],
        ["P",t('modal.privacyPolicy')],
        ["T",t('modal.consent3rdPartyInfoDisclosure')],
        ["O",t('modal.OverseasTransferOfPInfo')],
        ["C",t('modal.CommercialInfo')],
        ["A",t('modal.UserPrivacyPolicyRequired')],
        ["B",t('modal.UserPrivacyPolicyOptional')],
        ["F",t('modal.ContentsDownloadInfo')]
    ]);

    const modalFocusRef = useRef(null);

    useImperativeHandle(ref, () => ({
        focusTitle: () => {
            modalFocusRef.current.focus();
        },
    }));

    useEffect(()=>{
        setListArr([]);
        let listTempArr = [];
        setTimeout(()=>{
            let policyContent = document.querySelector('.js-text_policy_content');
            let listTitle = policyContent?.querySelectorAll('h4');
            if(listTitle){
                for(const element of listTitle){
                    listTempArr.push(element.innerText)
                    element.id = element.innerText;
                }
                setListArr(listTempArr);
            }else{
                return;
            }
        },1000)
    },[selectedNum])

    useEffect(() => {
        if(modalOpen)
        {
            setTitleBackup(document.querySelector('title').innerText)
            setMetaTags({
                title: pageInfoMap.get(type).title || '',
                description : pageInfoMap.get(type).description || '',
                keywords : pageInfoMap.get(type).keywords || ''
            })
        }else{
            if(titleBackUp) document.querySelector('title').innerText = titleBackUp;
        }
    }, [modalOpen])


    if(modalContent){
        return(
            <div id="" className="pop_set_b" style={modalOpen ? {} : {display:"none"}}>
                <div className="pop_section termModal">
                    <div className="parea">
                        <div className="page">
                            <div className="ppheader">
                                <div className="htit_t16" tabIndex={4} ref={modalFocusRef}>{translateMap.get(type) || '약관동의'}</div>
                                <div id="NoticePopA" className="rclose" onClick={()=>{setSelectedNum(0); setModalClose();}}><img src='/images/icon_pop_close.png' alt={t('close')}/></div>
                            </div>
                            {
                                modalContent.termList.length !== 0
                                ?
                                    <>
                                        <div className="text_policy _guide_area" tabIndex={5}>
                                            <div style={{marginBottom:'20px'}}>
                                                {listArr.length !== 0
                                                    ?
                                                        <h5 style={{marginBottom:'20px'}}>{t('modal.list')}</h5>
                                                    :
                                                        null

                                                }
                                                {listArr && listArr.map((el,idx)=>{
                                                    return <a href={`#${el}`} key={idx} style={{display:'block', marginBottom:'5px', color:'#666'}}>{el}</a>
                                                })}
                                            </div>
                                            <div className="js-text_policy_content" dangerouslySetInnerHTML={modalContent && {__html: modalContent.termList[selectedNum].content.replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}}></div>
                                            <div className="termListWrap" style={{marginTop:'30px'}}>
                                                <p style={{marginBottom:'10px'}}>{t('modal.revisionHistory')}</p>
                                                <ul>
                                                    {
                                                        modalContent.termList.map((el,idx) => {
                                                            return(
                                                                <li 
                                                                    key={idx}
                                                                    style={                                                                        
                                                                        selectedNum == idx
                                                                        ?   {fontWeight: 'bold' , marginBottom: '5px'} 
                                                                        :   {fontWeight: 'normal', cursor:'pointer', marginBottom: '5px'}
                                                                    }
                                                                    onClick={
                                                                        ()=>{
                                                                            selectedNum !== idx && setSelectedNum(idx)
                                                                        }
                                                                    }
                                                                >
                                                                    {`${t('modal.dateOfRevision')} : ${new Date(el.applyDate).getFullYear()}/${new Date(el.applyDate).getMonth()+1}/${new Date(el.applyDate).getDate()}`}
                                                                </li>
                                                            )
                                                        })
                                                    }                                                
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                :   <div className="text_policy _guide_area" tabIndex={4} style={{textAlign:'center'}}>{t('noData')}</div>
                            }
                            <div className="btn_area">
                                <button className="btn_sm4" onClick={()=>{setSelectedNum(0); setModalClose();}}>{t('close')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else{
        return(
            <div id="" className="pop_set_b" style={modalOpen ? {} : {display:"none"}}>
                <div className="pop_section">
                    <div className="parea">
                        <div className="page">
                            <div className="ppheader"></div>
                            <div className="text_policy _guide_area" tabIndex={4}>
                                <Loading />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
});

export default ModalTerm;