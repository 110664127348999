import React, { useEffect } from 'react';
import { t } from "i18next";
import setMetaTags from '../../Util/setMetaTags';
import PageInfo from '../../page_info.json';

const AboutUs = () => {
    useEffect(()=>{
        setMetaTags({
            title : PageInfo.AboutUs.title,
            description : PageInfo.AboutUs.description,
            keywords : PageInfo.AboutUs.keywords
        })
    },[])

    return(
        <>
        <h1 style={{display:"none"}}>{PageInfo.AboutUs.h1}</h1>
            <div className="sub_banner_au">
                <div className="b_area">
                    <div className="obj_img">
                        <img src='/images/img_abu_t_obj.png'alt=""/>
                    </div>
                    <div className="tit_area">
                        <div className="t_box">
                            <div className="tt_1" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0" dangerouslySetInnerHTML={{__html : t('new-about-us.title')}}></div>
                            <div className="tt_2" data-aos="fade-up" data-aos-duration="900" data-aos-delay="200">{t('new-about-us.desc')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="au_mid_area_1">
                <div className="image"><img src='/images/img_abu_midimg_1.png' alt=""/></div>
            </div>
            <div className="au_mid_area_2">
                <div className="image"><img src='/images/img_abu_midimg_2.png' alt=""/></div>
                <div className="tt_area" data-aos="fade-up" data-aos-duration="900" data-aos-delay="100">
                    <span dangerouslySetInnerHTML={{__html: t('new-about-us.value')}}></span>
                </div>
                <div className="info_list_area">
                    <div className="ip_section">
                        <ul>
                            <li>
                                <div className="ip_box" data-aos="fade-up" data-aos-duration="900" data-aos-delay="100">
                                    <div className="text">
                                        <div className="tt_1"><b>{t('new-about-us.history-cnt')}</b><br/>{t('new-about-us.history')}</div>
                                        <div className="tt_2">{t('new-about-us.history-desc')}</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="ip_box" data-aos="fade-up" data-aos-duration="900" data-aos-delay="300">
                                    <div className="text">
                                        <div className="tt_1"><b>{t('new-about-us.partners-cnt')}</b><br/>{t('new-about-us.partners')}</div>
                                        <div className="tt_2">{t('new-about-us.partners-desc')}</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="ip_box" data-aos="fade-up" data-aos-duration="900" data-aos-delay="500">
                                    <div className="text">
                                        <div className="tt_1"><b>{t('new-about-us.communication-records-cnt')}</b><br/>{t('new-about-us.communication-records')}</div>
                                        <div className="tt_2">{t('new-about-us.communication-records-desc')}</div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="ip_box" data-aos="fade-up" data-aos-duration="900" data-aos-delay="700">
                                    <div className="text">
                                        <div className="tt_1"><b>∞</b><br/>{t('new-about-us.best-expert')}</div>
                                        <div className="tt_2">{t('new-about-us.best-expert-desc')}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="au_map_area">
                <div className="tit" lang="en">Contact us</div>
                <div className="map_box">
                    <div
                        style={{ margin: 'auto', position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}
                        dangerouslySetInnerHTML={{__html: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.173921073735!2d126.97054991531114!3d37.55096597980081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b581daaaaaaab%3A0xd16346279906acfb!2zQ0rsmKzrpqzruIzrhKTtirjsm43siqQg67O47IKs!5e0!3m2!1sko!2skr!4v1661827549585!5m2!1sko!2skr" width="600" height="450" style="border:0;width:100%;height:100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="지도"></iframe>'}}
                    />
                </div>
                <div className="address">{t('new-about-us.addr')}</div>
            </div>
        </>
    )
}

export default AboutUs;