import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { renderToString } from 'react-dom/server';
import axios from 'axios';
import ModalTerm from '../../Layouts/Modal/modalTerm';
import { checkEmail, checkIdandPw, checkPassword, checkPassword_advence, checkUserID, duplicateCheckUserId, getUserAuthParameter, getUserNameAndPhoneByEncData, openKmcert } from '../../Util/reqApi';
import { t } from 'i18next';
import setMetaTags from '../../Util/setMetaTags';
import PageInfo from '../../page_info.json';


const SignUp = () => {    
    const { step } = useParams();
    let navigate = useNavigate();

    const [chk01, setChk01] = useState(false);
    const [chk02, setChk02] = useState(false);
    const [chk03, setChk03] = useState(false);
    const [chk04, setChk04] = useState(false);
    const [chk05, setChk05] = useState(false);
    const [chk06, setChk06] = useState(false);
    const [chk07, setChk07] = useState(false);
    const [id, setId] = useState();

    const [userData, setUserData] = useState({});

    useEffect(()=>{
        step || navigate('/signUp/1');
        setMetaTags({
            title: PageInfo.signUp.subpages[step-1].title + " - " + PageInfo.signUp.title,
            description : PageInfo.signUp.subpages[step-1].description,
            keywords : PageInfo.signUp.subpages[step-1].keywords
        })
    },[step])

    return(
        <div className="member_section">
            <h1 style={{display:"none"}}>{PageInfo.signUp.h1}</h1>
            <div className="mem_area">
                <div className="m_title">{`${step !== '3' ? '회원가입' : '회원가입 완료'}`}</div>
                <div className="m_flow">
                    <div className="f_section flow_1">
                        <div className={`flow_box ${step == '1' && 'On'}`}>
                            <span>1</span>
                        </div>
                        <div className="script">이용 약관 동의</div>
                    </div>
                    <div className="f_section flow_2">
                        <div className={`flow_box ${step == '2' && 'On'}`}>
                            <span>2</span>
                        </div>
                        <div className="script">회원 정보 입력</div>
                    </div>
                    <div className="f_section flow_3">
                        <div className={`flow_box ${step == '3' && 'On'}`}>
                            <span>3</span>
                        </div>
                        <div className="script">회원 가입 완료</div>
                    </div>
                </div>
                {(()=>{
                    switch(step){
                        case '1' : return <Step01 userData={userData} setUserData={setUserData} chk01={chk01} chk02={chk02} chk03={chk03} chk04={chk04} chk05={chk05} chk06={chk06} chk07={chk07} setChk01={setChk01} setChk02={setChk02} setChk03={setChk03} setChk04={setChk04} setChk05={setChk05} setChk06={setChk06} setChk07={setChk07} />
                        case '2' : return <Step02 id={id} setId={setId} userData={userData} setUserData={setUserData} />
                        case '3' : return <Step03 userData={userData} />
                        default : return null;
                    }
                })()}
            </div>
        </div>
    )
}

const Step01 = (props) => {
    const {chk01, chk02, chk03, chk04, chk05, chk06, chk07, setChk01, setChk02, setChk03, setChk04, setChk05, setChk06, setChk07, userData, setUserData} = props;
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [modalType, setModalType] = useState();
    const handleAllChk = (allChk) => {
        if(allChk){
            setChk01(false)
            setChk02(false)
            setChk03(false)
            setChk04(false)
            setChk05(false)
            setChk06(false)
            setChk07(false)
        }else{
            setChk01(true)
            setChk02(true)
            setChk03(true)
            setChk04(true)
            setChk05(true)
            setChk06(true)
            setChk07(true)
        }        
    }

    const handleSubAllChk = (subChk) => {
        if(subChk){
            setChk04(false)
            setChk05(false)
        }else{
            setChk04(true)
            setChk05(true)
        }
    }

    useEffect(()=>{
        setUserData({...userData, infoShare : chk01, privacy : chk02, privacyAgr: chk03, recvSms : chk04, recvNewsletter : chk05, over14YearsOld: chk06})
    },[])

    const getTerm = async (type) => {
        setModalContent();

        setModalType(type)
        const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/main/getTerm?langNo=${t('langNo')}&pageNumber=0&pageSize=9999&type=${type}`);
        setModalContent(result.data.data);
    }

    return(
        <>
            <div className="input_section">
            <h2 style={{display:"none"}}>{PageInfo.signUp.subpages[0].h1}</h2>
                <table className="table_type2">
                    <colgroup>
                        <col width="15px" />
                        <col width="*" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>
                                <input type="checkbox"
                                    id="list01"
                                    checked={chk01 && chk02 && chk03 && chk04 && chk05 ? true : false }
                                    onChange={()=>{
                                        chk01 && chk02 && chk03 && chk04 && chk05 ? handleAllChk(true) : handleAllChk(false)
                                        setUserData({...userData, infoShare : !chk01, privacy : !chk02, recvSms : !chk03, recvNewsletter : !chk03})
                                    }}/>
                            </th>
                            <td>
                                <label htmlFor="list01">전체 약관에 동의합니다(선택동의 항목 포함).</label>
                                <div className="moreInfo" style={{maxWidth:'80%', wordBreak:'keep-all'}}>
                                    <p className="subtt">선택항목에 동의하지 않은 경우에도 회원 가입 및 기본 서비스를 이용할 수 있습니다.</p>
                                </div>
                            </td> 
                        </tr>
                        <tr>
                            <th><input id="list02" 
                                type="checkbox" checked={chk01} onChange={()=>{
                                chk01 ? setChk01(false) : setChk01(true)
                                setUserData({...userData, infoShare : !chk01})
                                }} /></th>
                            <td>
                                <label htmlFor="list02">이용 약관 동의(필수)</label>
                                <button className="viewBtn" onClick={()=>{getTerm('U'); setModalOpen(true)}} aria-label='이용 약관 동의(필수) 보기'>보기</button></td>
                        </tr>
                        <tr>
                            <th><input id="list03"  
                                type="checkbox" checked={chk06} onChange={()=>{
                                chk06 ? setChk06(false) : setChk06(true)
                                setUserData({...userData, over14YearsOld : !chk06})
                                }} /></th>
                            <td>
                                <label htmlFor="list03">본인은 만14세 이상입니다 (필수)</label>
                            </td>
                        </tr>
                        <tr>
                            <th><input id="list04" 
                                type="checkbox" checked={chk02} onChange={()=>{
                                chk02 ? setChk02(false) : setChk02(true)
                                setUserData({...userData, privacy : !chk02})
                                }} /></th>
                            <td><label htmlFor="list04">회원관리 목적의 개인 정보 수집 이용 동의(필수)</label><button className="viewBtn" onClick={()=>{
                                getTerm('A'); setModalOpen(true);
                            }}
                            aria-label='회원관리 목적의 개인 정보 수집 이용 동의(필수) 보기'
                            >보기</button></td>
                        </tr>
                        <tr>
                            <th><input id="list05" 
                                type="checkbox" checked={chk03} onChange={()=>{
                                chk03 ? setChk03(false) : setChk03(true)
                                setUserData({...userData, privacyAgr : !chk03})
                                }} /></th>
                            <td>
                                <label htmlFor="list05">회원관리 목적의 개인 정보 수집 이용 동의(선택)</label>
                                <button className="viewBtn" onClick={()=>{
                                    getTerm('B'); setModalOpen(true);
                                }}
                                aria-label='회원관리 목적의 개인 정보 수집 이용 동의(선택) 보기'
                                >보기</button>
                                <div className="moreInfo">
                                    <p className="subtt">- 사업자 구분, 팩스, 홈페이지, 회신번호, 직책</p>
                                </div>
                            </td>
                        </tr>                        
                        <tr>
                            <th><input id="list06"  
                                type="checkbox"
                                checked={chk04 && chk05 ? true : false }
                                onChange={()=>{
                                    chk04 && chk05 ? handleSubAllChk(true) : handleSubAllChk(false)
                                    setUserData({...userData, recvSms : !chk04, recvNewsletter : !chk05 })
                                }}
                            /></th>
                            <td>
                                <label htmlFor="list06">광고성 정보 수신 동의 (전체선택)</label>
                                <button className="viewBtn" onClick={()=>{
                                    getTerm('C'); setModalOpen(true);
                               }}
                               aria-label='광고성 정보 수신 동의(선택) 보기'
                               >보기</button>
                                <div className="moreInfo">
                                    <p className="subtt">- 서비스 혜택, 이벤트정보 광고 등의 마케팅 활용</p>
                                </div>
                                <div className="moreInfo">
                                    <p>
                                        <input id="list0601" 
                                            type="checkbox"
                                            checked={chk04} onChange={()=>{
                                            chk04 ? setChk04(false) : setChk04(true)
                                            setUserData({...userData, recvSms : !chk04})
                                            }}
                                        />
                                        <label htmlFor="list0601">SMS/알림톡 수신 동의 (선택)</label>
                                    </p>
                                    {/* <p className="subtt" style={{paddingLeft:'25px', width:'100%'}}>API PLEX에서 보내는 알림톡을 받으시겠습니까?<br />알림톡 내용 : 회원가입 처리 안내, 서비스 결제 관련 취소<br />처리 안내, 계정 상황 안내 등</p> */}
                                    <p>
                                        <input id="list0602" 
                                        type="checkbox"
                                        checked={chk05} onChange={()=>{
                                            chk05 ? setChk05(false) : setChk05(true)
                                            setUserData({...userData, recvNewsletter : !chk05})
                                        }}
                                        />
                                        <label htmlFor="list0602">이메일 수신 동의 (선택)</label>
                                    </p>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th><input id="list07"
                                       type="checkbox" checked={chk07}
                                       onChange={() => {
                                           chk07 ? setChk07(false) : setChk07(
                                               true)
                                           setUserData({
                                               ...userData,
                                               overseasTransferOfPInfo: !chk07
                                           })
                                       }}/></th>
                            <td>
                                <label htmlFor="list05">제 3자 정보 제공
                                    동의(선택)
                                </label>
                                <button className="viewBtn" onClick={() => {getTerm('T'); setModalOpen(true)}} aria-label='제 3자 정보 제공 동의(선택) 보기'>보기</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {
                    chk01 && chk02 && chk06
                    ?
                    <Link to="/signUp/2" className="ip_btn_area">
                        <button className="login_n1">동의하고 가입하기</button>
                    </Link>
                    :
                    <a className="ip_btn_area" onClick={()=>{alert('필수항목에 동의해주세요.')}}>
                        <button className="login_n1">동의하고 가입하기</button>
                    </a>
                }            
                <div className="m_script">
                    사업자 회원께서는 <strong>사업자등록증 사본</strong>을 미리 준비해 주세요.
                </div>
                <ModalTerm modalOpen={modalOpen} setModalOpen={setModalOpen} setModalClose={() => {setModalOpen(false)}} modalContent={modalContent} type={modalType}/>
            </div>
        </>
    )
}
const Step02 = (props) => {
    const {userData, setUserData} = props
    const [name, setName] = useState();
    const [phone, setPhone] = useState();
    const [chkId, setChkId] = useState('');
    const [chkPwd, setChkPwd] = useState('');
    const [chkPwd2, setChkPwd2] = useState(true);
    const [chkMobile, setChkMobil] = useState();
    const [chkEmail, setChkEmail] = useState();
    const [duplicateCheck, setDuplicateCheck] = useState(false);
    const [tr_cert, set_tr_cert]  = useState();
    const [tr_url, set_tr_url ] = useState();
    const [cert, setCert] = useState(false);
    const [kCertNum, setKCertNum] = useState('')
    const [recCert, setRecCert] = useState('')
    let navigate = useNavigate();

    const reqKMCISForm = useRef();

    useEffect(()=>{
        if(!userData.infoShare || !userData.privacy){
            navigate('/signUp/1')
        }
    },[])

    useEffect(()=>{
        if(cert){
            getUserNameAndPhoneByEncData(document.querySelector('#certNum').value, document.querySelector('#rec_cert').value).then(
                result => {
                    setKCertNum(document.querySelector('#certNum').value)
                    setRecCert(document.querySelector('#rec_cert').value)
                    setName(result.data.name);
                    setPhone(result.data.phoneNo);
                    setUserData({...userData, name : result.data.name, mobile : result.data.phoneNo})
                }
            )
        }
    },[cert])

    const checkPassword2 = (e) => {
        if(chkPwd == e.target.value){
            setChkPwd2(true)
        }else{
            setChkPwd2(false)
        }
    }

    const checkForm = async () => {
        // console.log(userData)
        if(!duplicateCheck){
            alert('아이디 중복확인을 해주세요.')
            return;
        }
        if(!userData.userId){
            // console.log(userData)
            alert('아이디를 확인해주세요.')
            return;
        }

        if(checkPassword_advence(chkId, chkPwd)){
            alert(checkPassword_advence(chkId, chkPwd));
            return
        }

        if(!userData.pwd){
            alert('비밀번호는 8~20자, 영문/숫자/특수(!@#$%^*+=- 가능) 1개 이상 필수입니다.')
            return;
        }

        if(!chkPwd2){
            alert('비밀번호가 일치하지 않습니다.')
            return;
        }

        if(!checkIdandPw(userData.userId, userData.pwd)){alert('ID와 유사한 비밀번호는 사용할 수 없습니다.'); return;}

        if(document.querySelector('#rec_cert').value == ''){
            alert('휴대폰 인증을해주세요.')
            return;
        }
        if(!userData.email){
            alert('이메일을 확인해주세요.')
            return;
        }

        let form = new FormData();
        form.append('email' , userData.email)
        form.append('infoShare' , userData.infoShare)
        form.append('kCertNum' , document.querySelector('#certNum').value)
        form.append('managerName' , userData.name)
        form.append('phone' , userData.mobile)
        form.append('privacy' , userData.privacy)
        form.append('privacyAgr' , userData.privacyAgr)
        form.append('recCert' , document.querySelector('#rec_cert').value)
        form.append('recvNewsletter' , userData.recvNewsletter)
        form.append('recvSms' , userData.recvSms)
        form.append('userId' , userData.userId)
        form.append('userPwd' , userData.pwd)

        const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/user/signup`, form, {
            headers : {
                'Content-type': 'application/x-www-form-urlencoded',
            }
        })
        if(result.data.code == 0){
            navigate('/signup/3');
        }else{
            alert(result.data.data)
        }
    }

    const duplicateCheckId = (userId) => {
        duplicateCheckUserId(userId).then(result=>{
            if(result == 'ok'){
                alert('사용 가능한 아이디입니다.')
                setUserData({...userData, userId : userId})
                setDuplicateCheck(true)
                return true;
            }else{
                alert(result)
                return false;
            }
        })
    }    

    return(
        <div className="input_section">
            <h2 style={{display:"none"}}>{PageInfo.signUp.subpages[1].h1}</h2>
            <table className="table_type3">
                <colgroup>
                    <col width="100px" />
                    <col width="*%" />
                </colgroup>
                <tbody>
                    <tr tabIndex="-1">
                        <th colSpan="2" height="20px"></th>
                    </tr>
                    <tr>
                        <th>아이디<span>*</span></th>
                        <td>
                            <input
                                type="text"
                                style={{width:"72%"}}
                                placeholder="4~12자 입력 (영문, 숫자만 입력이 가능)"
                                autoComplete='off'
                                onChange={(e)=>{
                                    setDuplicateCheck(false)
                                    setChkId(e.target.value)
                                }}
                            />
                            <button
                                className={`ip_color1 ${checkUserID(chkId) ? ' ' : 'disable'}`}
                                style={{width:"26%"}}
                                onClick={()=>{
                                    if(checkUserID(chkId)){
                                        duplicateCheckId(chkId);
                                    }
                                }}
                            >
                                중복확인
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <th>비밀번호<span>*</span></th>
                        <td>
                            <input
                                type="password"
                                style={{width:"99%"}}
                                placeholder="8~20자, 영문/숫자/특수(!@#$%^();:’ 가능) 1개 이상 필수"
                                autoComplete='off'
                                value={chkPwd}
                                onChange={(e)=>{
                                    setChkPwd(e.target.value)
                                    if(checkPassword(e.target.value)){
                                        setUserData({...userData, pwd : e.target.value})
                                    }else{
                                        setUserData({...userData, pwd : undefined})
                                    }
                                }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>비밀번호 확인<span>*</span></th>
                        <td>
                            <input
                                type="password"
                                style={chkPwd2 ? {width:"99%"} : {width:"99%", borderColor: "red"}}
                                placeholder="비밀번호와 동일하게 입력"
                                autoComplete='off'
                                onBlur={(e)=>{checkPassword2(e)}}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>담당자 이름<span>*</span></th>
                        <td>
                            <input
                                type="text"
                                style={{width:"99%", background:'#ededed'}}
                                placeholder={`휴대폰 인증시 자동으로 입력됩니다.`}
                                value={name}
                                autoComplete='off'
                                onClick={()=>{
                                    getUserAuthParameter({callbackUrl:`${window.location.origin}/mobileCheckResult`, type:'join'}).then(result => {
                                        set_tr_cert(result.tr_cert);
                                        set_tr_url(result.tr_url);
                                        openKmcert(reqKMCISForm.current);
                                    })
                                }}
                                readOnly={true}
                                // onChange={(e)=>{setName(e.target.value)}}
                                // onBlur={()=>{setUserData({...userData, name: name})}}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>휴대폰 번호<span>*</span></th>
                        <td>
                            <input
                                type="text"
                                style={{width:"72%", background:'#ededed'}}
                                placeholder="휴대폰 인증시 자동으로 입력됩니다."
                                autoComplete='false'
                                onClick={()=>{
                                    getUserAuthParameter({callbackUrl:`${window.location.origin}/mobileCheckResult`, type:'join'}).then(result => {
                                        set_tr_cert(result.tr_cert);
                                        set_tr_url(result.tr_url);
                                        openKmcert(reqKMCISForm.current);
                                    })
                                }}
                                readOnly={true}
                                value={phone}
                            />
                            <button
                                className="ip_color2"
                                style={{width:"26%"}}
                                onClick={()=>{
                                    setCert(false);
                                    getUserAuthParameter({callbackUrl:`${window.location.origin}/mobileCheckResult`, type:'join'}).then(result => {
                                        set_tr_cert(result.tr_cert);
                                        set_tr_url(result.tr_url);
                                        openKmcert(reqKMCISForm.current);
                                    })
                                }}
                            >인증</button>
                        </td>
                    </tr>
                    <tr>
                        <th>이메일<span>*</span></th>
                        <td>
                            <input
                                type="text"
                                style={{width:"99%"}}
                                autoComplete='false'
                                value={chkEmail}
                                onChange={(e)=>{
                                    setChkEmail(e.target.value);
                                    if(checkEmail(e.target.value)){
                                        setUserData({...userData, email : e.target.value})
                                    }else{
                                        setUserData({...userData, email : undefined})
                                    }
                                }}                                
                            />
                            <p style={{fontSize:'12px', marginTop:'10px'}}>*해당 주소로 안내 메일을 발송드리오니 정확히 입력 바랍니다.</p>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="ip_btn_area">
                <button style={{width:"49%",marginRight:"2%"}} className="login_2 ip_color3" onClick={()=>{navigate('/signUp/1')}}>취소</button>
                <a onClick={()=>{checkForm()}}><button style={{width:"49%"}} className="login_2">가입완료</button></a>
            </div>
            <form name="reqKMCISForm" method="post" action="#" ref={reqKMCISForm}>
                <input type="hidden" name="tr_cert" value={tr_cert} />
                <input type="hidden" name="tr_url"  value={tr_url} />
            </form>
            <form name="resKMCISForm" method="post" action="#" onClick={()=>{setCert(true)}} >
                <input type="hidden" id="rec_cert" />
                <input type="hidden" id="certNum" />
            </form>
        </div>
    )
}
const Step03 = (props) => {
    const {userData} = props
    let navigate = useNavigate();

    useEffect(()=>{
        if(!userData.userId || !userData.pwd || !userData.name || !userData.mobile || !userData.email){
            navigate('/signUp/2')
        }
    },[])
    
    return(
        <div className="input_section">
            <h2 style={{display:"none"}}>{PageInfo.signUp.subpages[2].h1}</h2>
            <div className="info_tt_area">
                <div className="section1">
                    <span className="check">{userData.name}</span>님 , API PLEX 서비스 가입이 완료되었습니다.<br/>
                    API PLEX 서비스 승인완료 후 서비스를 이용할 수 있습니다.<br/>
                    승인 완료 후 안내메일 또는 알림톡을 발송해드립니다.<br/>
                </div>
                <div className="section2">
                    고객 유형: <span>일반 회원</span> <br/>
                    이메일 주소: <span className="check">{userData.email}</span> <br/>
                    휴대폰: <span>{userData.mobile}</span>
                </div>
                <div className="section3">
                    CJ 올리브네트웍스 API PLEX 서비스를 이용해 주셔서 감사합니다.
                </div>
            </div>
            <div className="ip_btn_area">
                <Link to="/">
                    <button style={{width:"48%"}} className="login_1">홈으로</button>
                </Link>
            </div>
        </div>
    )
}

export default SignUp;
