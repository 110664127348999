import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getMonth } from '../../Util/DateFormatter';
import Loading from '../../Util/Loading';
import { getUsage, getUserRelation } from '../../Util/reqApi';
import UsageDetail from './Component/UsageDetail';
import UsageTableHeader from './Component/UsageTableHeader';
import { CSVLink, CSVDdownload } from 'react-csv';

const ServiceUsage = () => {    
    const [slaveList, setSlaveList] = useState();    
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [slaveFilter, setSlaveFilter] = useState();
    const [isIncludeSlave, setIsIncludeSlave] = useState(true)
    const [rangeMonthData, setRangeMonthData] = useState();
    const [csvData, setCsvData] = useState();    

    useEffect(()=>{
        getUserRelation().then(result => setSlaveList(result.slaveList))
    },[])

    useEffect(()=>{
        if(startDate && endDate){
            setRangeMonthData(getMonth(startDate, endDate).map(el => {
                return {
                    year: el.getFullYear(), 
                    month : el.getMonth(), 
                    date : el.getDate()
                }
            }))
        }
    },[startDate,endDate])

    if(slaveList){
        return(
            <>
                <div className="option_bar">
                    <span>기간선택</span>
                    <div style={{display:"inline-flex", justifyContent:"flex-start", maxWidth:'400px', alignItems: 'center'}}>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart    
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat = {`yyyy-MM-dd`}
                            className='datePicker'
                        />
                        <span>~</span>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            dateFormat = {`yyyy-MM-dd`}
                            className='datePicker'
                        />
                    </div>
                    <div style={{display:"inline-flex", justifyContent:"flex-start", maxWidth:'400px'}}>
                        <select style={{marginLeft:"20px"}} value={slaveFilter} onChange={(e)=>{
                            e.target.value !== '전체 계정' ? setSlaveFilter(e.target.value) : setSlaveFilter()
                            !slaveFilter && setIsIncludeSlave(false)
                            slaveFilter == '' && setIsIncludeSlave(true)
                            }}>
                            <option value={undefined}>전체 계정</option>
                            {
                                (()=>{
                                    if(slaveList.length !== 0){
                                        return slaveList.map((el,idx)=>{
                                            return <option key={idx} value={el.userId}>{el.userId}</option>
                                        })
                                    }
                                })()
                            }
                        </select>
                        {/* <button
                            className="btn_sm2"
                            style={{width: "96px"}}
                            onClick={reSearch}
                        >검색</button> */}
                    </div>
                    <div className="r_area">
                        {
                            csvData ?
                                <CSVLink
                                    filename={`apiPlex_사용내역${startDate.getFullYear()}${startDate.getMonth()+1 < 10 ? `0${startDate.getMonth() +1}` : startDate.getMonth()+1}${startDate.getDate() < 10 ? `0${startDate.getDate()}` : startDate.getDate()}-${endDate.getFullYear()}${endDate.getMonth()+1 < 10 ? `0${endDate.getMonth() +1}` : endDate.getMonth()+1}${endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate()}`}
                                    data={csvData}>
                                        <button className="btn_sm7"><img  src={`/images/icon_excel.png`} alt=""/>엑셀다운</button>
                                </CSVLink> : null
                        }
                    </div>
                </div>

                {
                    (()=>{                     
                        console.log(rangeMonthData);                       
                        return rangeMonthData.map((_,idx) => {
                            return(
                                <MonthTable key={idx} date={{idx, startDate, endDate, totalLength : rangeMonthData.length}} isIncludeSlave={isIncludeSlave} slaveFilter={slaveFilter} rangeDate={rangeMonthData[idx]} setCsvData={setCsvData}/>
                            )
                        })
                    })()
                }
                
                <div className="script_b">
                - 서비스 이용 요금 정산은 한국 표준시간대(UTC+9)를 기준으로 산정됩니다. <br/>
                - 자세한 요금 납부 기간은 자주하는 질문(FAQ)을 참고하시기 바랍니다.<br/>
                - 사용 내역은 3일 전 데이터를 기준으로 합니다.<br/><br/><br/>
                </div>    
            </>
        )
    }else return <Loading />
}

export default ServiceUsage;

const MonthTable = (props) => {
    const {date, isIncludeSlave, slaveFilter, rangeDate, setCsvData } = props;    
    const [amount, setAmount] = useState(0);
    const [tableData, setTableData] = useState();
    const [usage, setUsage] = useState();
    const [usageDetailModal, setUsageDetailModal] = useState(false);
    const [modalStart, setModalStart] = useState();
    const [modalEnd, setModalEnd] = useState();
    const [modalDate, setModalDate] = useState('');

    const toNumber = (val) => {
        return (val === null || val === '' || val === undefined)? 0 : Number(val);
    };

    const toStringException = (val) => {
        return (val === null || val === '' || val === undefined) ? '미확정' : val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    useEffect(()=>{
        // 하나의 월만 선택 되어있을 때
        if(date.totalLength == 1){
            setModalStart(`${date.startDate.getFullYear()}-${date.startDate.getMonth() +1 < 10 ? `0${date.startDate.getMonth() +1}` : date.startDate.getMonth() +1}-${date.startDate.getDate() < 10 ? `0${date.startDate.getDate()}` : date.startDate.getDate()}`)
            setModalEnd(`${date.endDate.getFullYear()}-${date.endDate.getMonth() +1 < 10 ? `0${date.endDate.getMonth() +1}` : date.endDate.getMonth() +1}-${date.endDate.getDate()< 10 ? `0${date.endDate.getDate()}` : date.endDate.getDate()}`)
            getUsage(
                `${date.startDate.getFullYear()}-${date.startDate.getMonth() +1 < 10 ? `0${date.startDate.getMonth() +1}` : date.startDate.getMonth() +1}-${date.startDate.getDate() < 10 ? `0${date.startDate.getDate()}` : date.startDate.getDate()} 00:00:00`,
                `${date.endDate.getFullYear()}-${date.endDate.getMonth() +1 < 10 ? `0${date.endDate.getMonth() +1}` : date.endDate.getMonth() +1}-${date.endDate.getDate()< 10 ? `0${date.endDate.getDate()}` : date.endDate.getDate()} 23:59:59`,
                isIncludeSlave,
                slaveFilter
            ).then(result => {
                if(typeof(result) == 'object'){
                    let sumCount = 0;
                    result.map(el=>{
                        sumCount = sumCount + toNumber(el.total_price_amount);
                    })
                    setAmount(sumCount)
                }
                setTableData(result)
            })
            return ()=>{setTableData(); setAmount(0);}
        }
        // 시작월일 때 
        if(date.idx == 0){
            let lastDate = new Date (date.startDate.getFullYear(), date.startDate.getMonth()+1, 0).getDate();
            setModalStart(`${date.startDate.getFullYear()}-${date.startDate.getMonth() +1 < 10 ? `0${date.startDate.getMonth() +1}` : date.startDate.getMonth() +1}-${date.startDate.getDate() < 10 ? `0${date.startDate.getDate()}` : date.startDate.getDate()}`)
            setModalEnd(`${date.startDate.getFullYear()}-${date.startDate.getMonth() +1 < 10 ? `0${date.startDate.getMonth() +1}` : date.endDate.getMonth() +1}-${lastDate < 10 ? `0${lastDate}` : lastDate}`)
            getUsage(
                `${date.startDate.getFullYear()}-${date.startDate.getMonth() +1 < 10 ? `0${date.startDate.getMonth() +1}` : date.startDate.getMonth() +1}-${date.startDate.getDate() < 10 ? `0${date.startDate.getDate()}` : date.startDate.getDate()} 00:00:00`,
                `${date.startDate.getFullYear()}-${date.startDate.getMonth() +1 < 10 ? `0${date.startDate.getMonth() +1}` : date.startDate.getMonth() +1}-${lastDate < 10 ? `0${lastDate}` : lastDate} 23:59:59`,
                isIncludeSlave,
                slaveFilter
            ).then(result => {
                if(typeof(result) == 'object'){
                    let sumCount = 0;
                    result.map(el=>{
                        sumCount = sumCount + toNumber(el.total_price_amount);
                    })
                    setAmount(sumCount)
                }
                setTableData(result)
            })
            return ()=>{setTableData(); setAmount(0);}
        }
        // 마지막월일 때 
        if(date.idx == date.totalLength -1){
            setModalStart(`${date.startDate.getFullYear()}-${date.startDate.getMonth() +1 < 10 ? `0${date.startDate.getMonth() +1}` : date.startDate.getMonth() +1}-${date.startDate.getDate() < 10 ? `0${date.startDate.getDate()}` : date.startDate.getDate()}`)
            setModalEnd(`${date.endDate.getFullYear()}-${date.endDate.getMonth() +1 < 10 ? `0${date.endDate.getMonth() +1}` : date.endDate.getMonth() +1}-${date.endDate.getDate()< 10 ? `0${date.endDate.getDate()}` : date.endDate.getDate()}`)
            getUsage(
                `${date.endDate.getFullYear()}-${date.endDate.getMonth() +1 < 10 ? `0${date.endDate.getMonth() +1}` : date.endDate.getMonth() +1}-01 00:00:00`,
                `${date.endDate.getFullYear()}-${date.endDate.getMonth() +1 < 10 ? `0${date.endDate.getMonth() +1}` : date.endDate.getMonth() +1}-${date.endDate.getDate()< 10 ? `0${date.endDate.getDate()}` : date.endDate.getDate()} 23:59:59`,
                isIncludeSlave,
                slaveFilter
            ).then(result => {
                if(typeof(result) == 'object'){
                    let sumCount = 0;
                    result.map(el=>{
                        sumCount = sumCount + toNumber(el.total_price_amount);
                    })
                    setAmount(sumCount)
                }
                setTableData(result)
            })
            return ()=>{setTableData(); setAmount(0);}
        }
        // 그외 중간
        let lastDate = new Date (rangeDate.year, rangeDate.month +1, 0).getDate();
        setModalStart(`${rangeDate.year}-${rangeDate.month +1 < 10 ? `0${rangeDate.month +1}` : rangeDate.month +1}-${rangeDate.date < 10 ? `0${rangeDate.date}` : rangeDate.date}`)
        setModalEnd(`${rangeDate.year}-${rangeDate.month +1 < 10 ? `0${rangeDate.month +1}` : rangeDate.month +1}-${lastDate < 10 ? `0${lastDate}` : lastDate}`)
        getUsage(
            `${rangeDate.year}-${rangeDate.month +1 < 10 ? `0${rangeDate.month +1}` : rangeDate.month +1}-${rangeDate.date < 10 ? `0${rangeDate.date}` : rangeDate.date} 00:00:00`,
            `${rangeDate.year}-${rangeDate.month +1 < 10 ? `0${rangeDate.month +1}` : rangeDate.month +1}-${lastDate < 10 ? `0${lastDate}` : lastDate} 23:59:59`,
            isIncludeSlave,
            slaveFilter
        ).then(result => {
            if(typeof(result) == 'object'){
                let sumCount = 0;
                result.map(el=>{
                    sumCount = sumCount + toNumber(el.total_price_amount);
                })
                setAmount(sumCount)
            }
            setTableData(result)
        })
        return ()=>{setTableData(); setAmount(0);}
    },[date, slaveFilter])

    const getStatusName = (status)=>{
        switch(status){
            case '10' :
                return "서비스 요청 접수"
            case '20' :
                return "서비스 전송"
            case '30' :
                return "서비스 성공"
            case '40' :
                return "FAILBACK 성공"
            case '60' :
                return "실패-여신부족"
            case '70' :
                return "서비스 실패"
            case '80' :
                return "결과 수신 실패"
            default : return "-"
        }
    }

    return(
        <div className="table_area">
            <div className="ur_tit">
                {rangeDate.year}년 {rangeDate.month +1}월 이용 현황
            </div>
            <table className="table_mypage">
                <UsageTableHeader />
                <tbody>
                    {
                        (()=>{
                            if(tableData){
                                if(tableData == 'NO_DATA'){
                                    return <tr><td colSpan={6}>사용 내역이 없습니다.</td></tr>
                                }else if(typeof(tableData) == "object"){
                                    return tableData.map((el,idx) => {
                                        // console.log(el)
                                        return(
                                            <tr key={idx} className="tableRow">
                                                <td>{el.service_name_kr}</td>
                                                <td className='amount'>{toStringException(el.total_price_amount)}</td>
                                                <td>{el.use_count}</td>
                                                <td>{el.promotion_name || '-'}</td>
                                                <td>{getStatusName(el.status)}</td>
                                                <td>
                                                    <button
                                                        className="btn_sm"
                                                        onClick={()=>{
                                                            setUsage(el);
                                                            setModalDate({modalStart:modalStart, modalEnd:modalEnd, modalMonth:rangeDate.month})
                                                            setUsageDetailModal(true);
                                                        }}
                                                    >
                                                        자세히 보기
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }else{
                                    return <tr><td colSpan={6}>server error</td></tr>
                                }
                            }else return <tr><td colSpan={6}><Loading /></td></tr>
                        })()
                    }                    
                </tbody>
            </table>
            <div className="option_bar3">
                <div className="r_area2">총 사용 금액 : <span className="ac">{amount.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> 원</div>
            </div>
            {
                usageDetailModal
                ?
                <UsageDetail
                    usage={usage}
                    usageDetailModal={usageDetailModal}
                    setUsageDetailModal={setUsageDetailModal}
                    startDate={modalStart}
                    modalDate={modalDate}
                    endDate={modalEnd}
                />
                :
                null
            }
        </div>
    )
}