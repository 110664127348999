import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import setMetaTags from "../Util/setMetaTags";
import {isMobile} from "react-device-detect";
import PostInfo from "../page_info.json";
import { t } from 'i18next';

const Error = () => {
    const navigate = useNavigate();
    const [time, setTime] = useState(30); //타이머 시간 설정(30)

    useEffect(()=>{
        setMetaTags({
            title: PostInfo.error.title,
            description: PostInfo.error.description,
            keywords: PostInfo.error.keywords
        })
    },[])

    useEffect(() => {
        if(!isMobile){
            const timerId = setInterval(() => {
                setTime(prevTime => {
                        if(prevTime <= 0) {
                            clearInterval(timerId);
                            navigate('/', {replace: true});
                            return 0;
                        } else {
                            return prevTime - 1;
                        }
                    })
              }, 1000);
    
            // 컴포넌트가 언마운트될 때 타이머 정리
            return () => clearInterval(timerId);
        }
      }, [time]);

    return(
        <>
         {isMobile ? (
            <div className="s_pt_mid_section">
                <div className="pt_tit_area">
                <div className="tt_area">
                    <img src='/images/icon_warning_black.png' className="errorIcon" alt=""/>
                    <div className="errorTitleMiddle">{t('error.sorry')}</div>
                    <div className="errorContentSmall">{t('error.title')}</div>
                    <p className="errorContentSmall">{t('error.content')}</p>
                    <p className="errorContentSmall">{t('error.ifYouHaveAQuetion')}</p>
                    <p className="errorContentSmall">{t('error.emailTitle')} : {t('error.email')}</p>
                    <div>
                        <button className="errorBtnReport" onClick={() => {navigate('/Support/requests')}}>{t('error.btnSupport')}</button>
                        <button className="errorBtnReport" onClick={(e) => {window.location.href = 'mailto:apiplex@cj.net'; e.preventDefault();}}>{t('error.btnEmail')}</button>
                    </div>
                    <div className="errorContentSmall">{t('error.thanks')}</div>
                    <div>
                        <button type="button" className="errorBtnShort" onClick={() => {window.history.back();}}>
                            {t('error.btnBack')}
                        </button>
                        <button type="button" className="errorBtnShort" onClick={() => {navigate('/', {replace: true});}}>
                            {t('error.btnHome')}
                        </button>
                    </div>
                </div>
                </div>
            </div>  
        ) : (
            <div className="s_pt_mid_section">
                <div className="pt_tit_area">
                <div className="tt_area">
                    <img src='/images/icon_warning_black.png' className="errorIcon" alt=""/>
                    <div className="errorTitleBig">{t('error.sorry')}</div>
                    <div className="errorTitleMiddle">{t('error.title')}</div>
                    <p className="errorContent">{t('error.content')}</p>
                    <p className="errorContentSmall">{t('error.ifYouHaveAQuetion')}</p>
                    <p className="errorContentSmall">{t('error.emailTitle')} : {t('error.email')}</p>
                    <div>
                        <button className="errorBtnReport" onClick={() => {navigate('/Support/requests')}}>{t('error.btnSupport')}</button>
                        <button className="errorBtnReport" onClick={(e) => {window.location.href = 'mailto:apiplex@cj.net'; e.preventDefault();}}>{t('error.btnEmail')}</button>
                    </div>
                    <div className="errorContentSmall">{t('error.thanks')}</div>
                    <div className="errorContentSmall">{time} {t('error.timer')}</div>
                    <div>
                        <button type="button" className="errorBtnShort" onClick={() => {window.history.back();}}>
                            {t('error.btnBack')}
                        </button>
                        <button type="button" className="errorBtnShort" onClick={() => {navigate('/', {replace: true});}}>
                            {t('error.btnHome')}
                        </button>
                    </div>
                </div>
                </div>
            </div>            
         )}
        </>
        
    )
}

export default Error;