import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

const VideoModal = forwardRef(({setVideoModal}, ref) => {    

    const videoRef = useRef(null);

    const handleClose = () => {
        videoRef.current.pause();
        setVideoModal(false)
    }

    useImperativeHandle(ref, () => ({
        focusVideo: () => {
            videoRef.current.focus();
        },
    }));

    useEffect(()=>{
        videoRef.current.play();
        return () => {
            if(videoRef.current) videoRef.current.pause();
        }
    },[])

    return (
        <div id="NoticePopA" className="pop_set" style={{display:'block'}}>
            <div className="pop_section">
                <div className="parea" style={{maxWidth:"1080px"}}>
                    <div className="page" style={{borderRadius:'0', padding:"0", fontSize:'0'}}>
                        <button
                            id="NoticePopA"
                            className="rclose"
                            onClick={handleClose}
                            style={{
                                position:'absolute',
                                top:'10px',
                                right:'10px',
                                cursor:'pointer',
                                padding:0,
                                zIndex:'9999'
                            }}
                            tabIndex={2} 
                            role='button'
                            alt='닫기'
                            >
                                <img src='/images/icon_pop_close.png' style={{paddingRight:0}}alt=""/>
                        </button>
                        <video
                            tabIndex={1}
                            ref={videoRef}
                            src="/media/APIPLEX소비자편_221024.mp4"
                            controls
                            style={{width:'100%'}}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default VideoModal;