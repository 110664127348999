import React, {useState} from "react";
import {Link} from "react-router-dom";
import { t } from "i18next";

const SearchBar = ({searchStr, setSearchStr}) => {
  return (
      <>
        <form className='headerRightAlign' action={`/search/${searchStr}`}>
          <div id='searchBar' className='searchBar'>
            <label htmlFor='headerSearch' className='hidden'></label>
            <input id='headerSearch' className='searchBarInput searchBarInput-focused' type='text' placeholder={t('search.placeHolder')} value={searchStr} onChange={(e) => {setSearchStr(e.target.value);}}/>
            <span className='vertical-separator' style={{color: 'gray'}}>|</span>
            <Link to={`/search/${searchStr}`}>
              <button type='button' className='searchBtn' disabled={!searchStr}><img src='/images/icon-search-gray.svg' className='searchIcon'/></button>
            </Link>
          </div>
        </form>
      </>
  );

};

export default SearchBar;