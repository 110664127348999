import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getApiItems, getSiteCategoryDetail, getSvcMainCategory } from '../../Util/reqApi';
import { t } from 'i18next';
import { isMobile } from 'react-device-detect';
import { useLocation } from "react-router-dom"
import Loading from '../../Util/Loading';
import SubHeader from '../Common/Component/SubHeader';
import ApiListItems from '../Common/Component/ApiListItem';
import setMetaTags from '../../Util/setMetaTags';
import PageInfo from '../../page_info.json';

const MarketPlace = () => {
    const location = useLocation(); // 푸터 사이트맵에서 진입 시 초기 페이지 설정

    const [ cateData, setCateData ] = useState({});
    const [ apiCategorys, setApiCategorys] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ selectedMarketPlaceTab, setSelectedMarketPlaceTab ] = useState(0);

    useEffect(()=>{
        getSiteCategoryDetail(2).then(result => { setCateData(result) })
        getSvcMainCategory().then(result => {
            Promise.all(result
                .filter(el => {return el.mainCateNo === 5})[0].svcCategoryList
                .map(el => {
                return getApiItems(el.cateNo).then(apiItem => {
                    if(apiItem.length !== 0){
                        el.data = apiItem;
                    }
                    return el
                })
            })).then(()=> {
                setApiCategorys(result.filter(el => {return el.mainCateNo === 5})[0].svcCategoryList);
                setIsLoading(true);
            })
        })

        setMetaTags({
            title : PageInfo.Marketplace.title,
            description : PageInfo.Marketplace.description,
            keywords : PageInfo.Marketplace.keywords
        })

        return ()=>{
            setCateData({});
            setApiCategorys([]);
            setMetaTags({});
            setSelectedMarketPlaceTab(0);
        }
    },[])

    useEffect(()=>{
        window.scrollTo(0,0);
        setSelectedMarketPlaceTab(location.state?.idx || 0)
    },[location.state])

    return(
        <div>
            <h1 style={{display:"none"}}>{PageInfo.Marketplace.h1}</h1>
            <SubHeader
                subTitle = {cateData[t('subBanner.headingText')]}
                subDesc = {cateData[t('subBanner.content')]}
                bgImgSrc = {!isMobile ? cateData.pcImageName ? `/uploads/banner/${cateData.pcImageName}` : '' : cateData.mobileImageName ? `/uploads/banner/${cateData.mobileImageName}` : ''}
            />
            <div className="s_mk_tab">
                <div className="tab_area">
                    <ul>
                        <li onClick={()=>{setSelectedMarketPlaceTab(0)}}>
                            <div className={`t_box ${selectedMarketPlaceTab === 0 ? 'on' : ''}`}>
                                <div className="icon">
                                    <span>
                                        <img src='/images/icon_mp_tab_1.png' alt=""/>
                                    </span>
                                </div>
                                <div className="text">
                                    <span>Naver Cloud Platform Market</span>
                                </div>
                            </div>
                        </li>
                        <li onClick={()=>{setSelectedMarketPlaceTab(1)}}>
                            <div className={`t_box ${selectedMarketPlaceTab === 1 ? 'on' : ''}`}>
                                <div className="icon">
                                    <span>
                                        <img src='/images/icon_mp_tab_2.png' alt=""/>
                                    </span>
                                </div>
                                <div className="text">
                                    <span>Partners Market</span>
                                </div>
                            </div>
                        </li>
                        <li onClick={()=>{setSelectedMarketPlaceTab(2)}}>
                            <div className={`t_box ${selectedMarketPlaceTab === 2 ? 'on' : ''}`}>
                                <div className="icon">
                                    <span>
                                        <img src='/images/icon_mp_tab_3.png' alt=""/>
                                    </span>
                                </div>
                                <div className="text">
                                    <span>AI Market</span>
                                </div>
                            </div>
                        </li>
                        <li onClick={()=>{setSelectedMarketPlaceTab(3)}}>
                            <div className={`t_box ${selectedMarketPlaceTab === 3 ? 'on' : ''}`}>
                                <div className="icon">
                                    <span>
                                        <img src='/images/icon_mp_tab_4.png' alt=""/>
                                    </span>
                                </div>
                                <div className="text">
                                    <span>Public API Market</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            {
                isLoading
                ?
                    // selectedMarketPlaceTab === 3 ?
                    //     <Link to="/support/requests">
                    //         <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', padding:'100px 0', cursor:'pointer'}}>
                    //             <img src="/images/comming_soon.png" style={{maxWidth:'100%'}}/>
                    //         </div>
                    //     </Link>
                    // :
                    apiCategorys.map((el,idx) => {
                        if(idx === selectedMarketPlaceTab){
                            return(
                                <div className="s_mk_list_area" key={idx}>
                                    <h3 style={{display:'none'}}>
                                        {
                                            selectedMarketPlaceTab === 0? 'Naver Cloud Platform Market' :
                                            (selectedMarketPlaceTab === 1? 'Partners Market' : 
                                            (selectedMarketPlaceTab === 2? 'AI Market' : 
                                            (selectedMarketPlaceTab === 3? 'Public API Market' : '')))}</h3>
                                    <div className="sub_api_list">
                                        <div className="list_area">
                                            <ApiListItems key={el.cateNo} data={el.data ? [...el.data] : []} />
                                        </div>
                                    </div>
                                </div>
                            )
                        }else return null;
                    })
                : <Loading />
            }
        </div>
    )
}

export default MarketPlace;