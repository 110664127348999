import React from "react";

const BasicButton = (props) => {
    const {link, children} = props;

    return(
        <div className="btn_area" style={{paddingTop:"20px"}}>
            <button className={`basic banC`} onClick={()=>{window.open(`${link}`, `target_blank`)}}>
                {children}
            </button>
        </div>
    )
}

export default BasicButton;