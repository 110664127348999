import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Mypage = () => {
    const { pathname } = useLocation();

    return(
        <>
            <div className="mypage_content">
                <div className="mapage_section">
                    <div className="mp_tit">마이페이지</div>
                    <div className="tab">
                        <ul>
                            <Link to="/mypage/payment">
                                <li className={`${pathname === '/mypage/payment' ? 'tab-link current' : 'tab-link'}`}>
                                    결제내역
                                </li>
                            </Link>
                            <Link to="/mypage/service">
                                <li className={`${pathname === '/mypage/service' ? 'tab-link current' : 'tab-link'}`}>
                                    서비스 이용 현황
                                </li>
                            </Link>
                            <Link to="/mypage/group">
                                <li className={`${pathname === '/mypage/group' ? 'tab-link current' : 'tab-link'}`}>
                                    그룹설정
                                </li>
                            </Link>
                            <Link to="/mypage/account/changeProfile">
                                <li className={`${pathname.indexOf('/mypage/account') !== -1 ? 'tab-link current' : 'tab-link'}`}>
                                    계정관리
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default Mypage;