import { t } from 'i18next';
import React, { useRef, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import MainNotice from '../../Layouts/Modal/MainNotice';
import VideoModal from './Components/VideoModal';
import DownloadModal from './Components/DownloadModal';
import setMetaTags from '../../Util/setMetaTags';
import PageInfo from '../../page_info.json';
import { useTopButton } from '../../shared/topButtonContext';

//web push
import { loadServiceWorker, initializeState, checkSubscribed } from '../../cjwebpush.js';
import PushSubscribeModal from '../../Layouts/Modal/PushSubscribeModal.js';

function Main(){
    const [selectedMainService, setSelectedMainService] = useState(0);
    const [selectedSection01, setSelectedSection01] = useState(0);
    const [autoPlay, setAutoPlay] = useState(true);
    const [videoModal, setVideoModal] = useState(false);

    const {focusRef} = useTopButton();
    const childRef = useRef(null);

    //web push
    const [modalOpen, setModalOpen] = useState(false);

    //Download Modal
    const [modalDOpen, setModalDOpen] = useState(false);

    const actionForSubscribe = () => {
        // braze.requestPushPermission();
        setModalOpen(false);
    }

    useEffect(async () => {
        // loadServiceWorker().then( () => {
        //     setModalOpen(true);
        // })
    }, []);

    useEffect(() => {
        if(focusRef && focusRef.current){
            focusRef.current.focus();
        }
        setMetaTags({
            title : PageInfo.Main.title,
            description : PageInfo.Main.description,
            keywords : PageInfo.Main.keywords      
        });

    }, []);

    const handleVideoOpenClick = () => {
        setVideoModal(!videoModal);
    }

    useEffect(() => {
        if(videoModal && childRef && childRef.current){
            childRef.current.focusVideo();
        }else if(focusRef && focusRef.current){
            focusRef.current.focus();
        }
    }, [videoModal])

    useEffect(()=>{
        const timer = setTimeout(()=>{
            if(autoPlay){
                if(selectedSection01 < 1){
                    setSelectedSection01(selectedSection01 + 1);
                    setSelectedMainService(0)
                }else{
                    setSelectedSection01(0);
                    setSelectedMainService(0)
                }
            }
        }, 5000);
        return () => {
            clearTimeout(timer);
        }
    },[autoPlay, selectedSection01])

    return(
        <>
            <h1 style={{display:"none"}}>{PageInfo.Main.h1}</h1>
            <div className="n_m_top_visual">
                <div className="vs_area">
                    <div className="title_area">
                        <div className="t_box">
                            <div className="tt_1" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0" lang="en">Take a REST</div>
                            <div className="tt_1" data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" lang="en">
                                with API <span className="tc1" lang="en">P</span><span className="tc2" lang="en">L</span><span className="tc3" lang="en">E</span><span className="tc4" lang="en">X</span>
                            </div>
                            <div className="tt_2" data-aos="fade-up" data-aos-duration="900" data-aos-delay="400" dangerouslySetInnerHTML={{__html:t('new-main.main-banner')}}></div>
                            <div className="btn" data-aos="fade-up" data-aos-duration="900" data-aos-delay="500">
                                <a href="#" role="button" ref={focusRef} onClick={(e)=>{e.preventDefault(); handleVideoOpenClick();}} className="nm_btn_blue" style={{marginRight:'10px'}}>{t('new-main.getStart')}</a>
                                <button className="nm_btn_white" onClick={() => {setModalDOpen(true);}}>{t('new-main.download')}</button>
                            </div>
                        </div>
                    </div>
                    <div className="img_area">
                        <div className="i_main"><img src={'/images/img_m_vs_img_1.png'} alt=""/></div>
                        <div className="i_main_man ob_ani_man"><img src={'/images/img_m_vs_img_1_man.png'} alt=""/></div>
                        <div className="obj_1 ob_ani_1"><img src={'/images/img_m_vs_ob_1.png'} alt="" /></div>
                        <div className="obj_2 ob_ani_2"><img src={'/images/img_m_vs_ob_2.png'} alt="" /></div>
                        <div className="obj_3 ob_ani_3"><img src={'/images/img_m_vs_ob_3.png'} alt="" /></div>
                        <div className="obj_4 ob_ani_4"><img src={'/images/img_m_vs_ob_4.png'} alt="" /></div>
                        <div className="obj_5 ob_ani_5"><img src={'/images/img_m_vs_ob_5.png'} alt="" /></div>
                        <div className="obj_6 ob_ani_6"><img src={'/images/img_m_vs_ob_6.png'} alt="" /></div>
                    </div>
                </div>
            </div>
            <div className="n_m_mid_info_1">
                <div className="mid_if_area">
                    <div className="left_area">
                        <div className="tit"><h2 lang="en">{`${selectedSection01 === 0 ? 'Service' : 'Marketplace'}`}</h2></div>
                        <div
                            className="sw_btn"
                            onClick={()=>{
                                if(selectedSection01 > 0){
                                    setSelectedSection01(0)
                                }else{
                                    setSelectedSection01(selectedSection01 + 1)
                                }
                                setAutoPlay(autoPlay ? false : true)
                            }}>
                            <span className='on_1' tabIndex={6} role='button' style={{left :selectedSection01 == 0 ? '0' : '50%' }}></span>
                        </div>
                        <div
                            className="play_btn"
                            onClick={()=>{
                                setAutoPlay(autoPlay ? false : true)
                            }}>
                            <span tabIndex={7} role='button'>
                                {
                                    autoPlay ? <img className="btn" src={'/images/icon_pl_btn_off.png'} alt="정지"/> : <img className="btn" src={'/images/icon_pl_btn_on.png'} alt="재생"/>
                                }
                                <span className="pageNum"><span className="on_t">{selectedSection01 + 1}</span> l 2</span>
                            </span>
                        </div>
                    </div>
                    <div className="right_area">
                        {/* 1 */}
                        <div className="tab_area" style={selectedSection01 === 0 ? {display:'block'} : {display:'none'} }>
                            <h3 style={{display:'none'}}>Service</h3>
                            <ul>
                                <li className={selectedMainService === 0 ? 'on' : ''} onMouseEnter={()=>{setSelectedMainService(0)}} onClick={()=>{setAutoPlay(false)}}>
                                    <div className="t_btn" tabIndex={8} role='button'>
                                        <div className="icon_img">
                                            <span>
                                                <img src={'/images/icon_m_mid_ip_1.png'} alt=""/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <span lang="en">Message</span>
                                        </div>
                                    </div>
                                    <div className="talk_box tb_1">
                                        <div className="t_area">
                                            <span dangerouslySetInnerHTML={{__html: t('new-main.messaging')}}></span>
                                        </div>
                                    </div>
                                </li>
                                <li className={selectedMainService === 1 ? 'on' : ''} onMouseEnter={()=>{setSelectedMainService(1)}}  onClick={()=>{setAutoPlay(false)}}>
                                    <div className="t_btn" tabIndex={9} role='button'>
                                        <div className="icon_img">
                                            <span>
                                                <img src={'/images/icon_m_mid_ip_2.png'} alt=""/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <span lang="en">Global<br/>Message</span>
                                        </div>
                                    </div>
                                    <div className="talk_box tb_2">
                                        <div className="t_area">
                                            <span dangerouslySetInnerHTML={{__html: t('new-main.global-messaging')}}></span>
                                        </div>
                                    </div>
                                </li>
                                <li className={selectedMainService === 2 ? 'on' : ''} onMouseEnter={()=>{setSelectedMainService(2)}}  onClick={()=>{setAutoPlay(false)}}>
                                    <div className="t_btn" tabIndex={10} role='button'>
                                        <div className="icon_img">
                                            <span>
                                                <img src={'/images/icon_m_mid_ip_3.png'} alt=""/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <span lang="en">Email & Push</span>
                                        </div>
                                    </div>
                                    <div className="talk_box tb_3">
                                        <div className="t_area">
                                            <span dangerouslySetInnerHTML={{__html: t('new-main.email-push')}}></span>
                                        </div>
                                    </div>
                                </li>
                                <li className={selectedMainService === 3 ? 'on' : ''} onMouseEnter={()=>{setSelectedMainService(3)}}  onClick={()=>{setAutoPlay(false)}}>
                                    <div className="t_btn" tabIndex={11} role='button'>
                                        <div className="icon_img">
                                            <span>
                                                <img src={'/images/icon_m_mid_ip_4.png'} alt=""/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <span lang="en">others</span>
                                        </div>
                                    </div>
                                    <div className="talk_box tb_4">
                                        <div className="t_area">
                                            <span dangerouslySetInnerHTML={{__html: t('new-main.others')}}></span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {/* 1 */}
                        {/* 2 */}
                        <div className="tab_area" style={selectedSection01 === 1 ? {display:'block'} : {display:'none'} }>
                            <h3 style={{display:'none'}}>Marketplace</h3>
                            <ul>
                                <li className={selectedMainService === 0 ? 'on' : ''} onMouseEnter={()=>{setSelectedMainService(0)}}  onClick={()=>{setAutoPlay(false)}}>
                                    <div className="t_btn" tabIndex={12} role='button'>
                                        <div className="icon_img">
                                            <span>
                                                <img src={'/images/icon_m_mid_ip_5.png'} alt=""/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <span lang="en">Naver Cloud Platform Market</span>
                                        </div>
                                    </div>
                                    <div className="talk_box tb_1">
                                        <div className="t_area">
                                            <span dangerouslySetInnerHTML={{__html: t('new-main.NCPM')}}></span>
                                        </div>
                                    </div>
                                </li>
                                <li className={selectedMainService === 1 ? 'on' : ''} onMouseEnter={()=>{setSelectedMainService(1)}}  onClick={()=>{setAutoPlay(false)}}>
                                    <div className="t_btn" tabIndex={13} role='button'>
                                        <div className="icon_img">
                                            <span>
                                                <img src={'/images/icon_m_mid_ip_6.png'} alt=""/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <span lang="en">Partners Market</span>
                                        </div>
                                    </div>
                                    <div className="talk_box tb_2">
                                        <div className="t_area">
                                            <span dangerouslySetInnerHTML={{__html: t('new-main.partners-markets')}}></span>
                                        </div>
                                    </div>
                                </li>
                                <li className={selectedMainService === 2 ? 'on' : ''} onMouseEnter={()=>{setSelectedMainService(2)}}  onClick={()=>{setAutoPlay(false)}}>
                                    <div className="t_btn" tabIndex={14} role='button'>
                                        <div className="icon_img">
                                            <span>
                                                <img src={'/images/icon_m_mid_ip_7.png'} alt=""/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <span lang="en">AI Market</span>
                                        </div>
                                    </div>
                                    <div className="talk_box tb_3">
                                        <div className="t_area">
                                            <span dangerouslySetInnerHTML={{__html: t('new-main.AI-market')}}></span>
                                        </div>
                                    </div>
                                </li>
                                <li className={selectedMainService === 3 ? 'on' : ''} onMouseEnter={()=>{setSelectedMainService(3)}}  onClick={()=>{setAutoPlay(false)}}>
                                    <div className="t_btn" tabIndex={15} role='button'>
                                        <div className="icon_img">
                                            <span>
                                                <img src={'/images/icon_m_mid_ip_8.png'} alt=""/>
                                            </span>
                                        </div>
                                        <div className="text">
                                            <span lang="en">Public API Market</span>
                                        </div>
                                    </div>
                                    <div className="talk_box tb_4">
                                        <div className="t_area">
                                            <span dangerouslySetInnerHTML={{__html: t('new-main.public-api-market')}}></span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {/* 2 */}
                    </div>
                </div>
            </div>
            <div className="n_mid_section">
                <div className="n_m_mid_info_2">
                    <div className="if_area">
                        <div className="vidual">
                            <div className="image">
                                <img src={'/images/img_m_midvs_1.png'} alt=""/>
                            </div>
                        </div>
                        <div className="info_tt_box">
                            <div className="tt_area">
                                <div className="tit" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">{t('new-main.multiple-comm-channels')}</div>
                                <div className="tt_1" data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" dangerouslySetInnerHTML={{__html : t('new-main.multiple-comm-channels-desc')}}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="n_m_mid_info_3">
                    <div className="if_area">
                        <div className="vidual">
                            <div className="image">
                                <img src={'/images/img_m_midvs_2.png'} alt=""/>
                            </div>
                        </div>
                        <div className="info_tt_box">
                            <div className="tt_area">
                                <div className="tit" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0" lang="en">Data Multiplex</div>
                                <div className="tt_1" data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" dangerouslySetInnerHTML={{__html: t('new-main.data-multiplex')}}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="n_m_mid_info_4">
                    <div className="if_area">
                        <div className="vidual">
                            <div className="image">
                                <img src={'/images/img_m_midvs_3.png'} alt=""/>
                            </div>
                        </div>
                        <div className="info_tt_box">
                            <div className="tt_area">
                                <div className="tit" data-aos="fade-up" data-aos-duration="900" data-aos-delay="0">{t('new-main.grow-together')}</div>
                                <div className="tt_1" data-aos="fade-up" data-aos-duration="900" data-aos-delay="200" dangerouslySetInnerHTML={{__html: t('new-main.grow-together-desc')}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="n_mbot_section">
                <div className="bg_area">
                    <div className="bg_img">
                        <img src={'/images/bg_001.gif'} alt=""/>
                    </div>
                </div>
                <div className="tt_area">
                    <div className="tt_box">
                        <div className="tt_1" lang="en">Ready to use API PLEX?</div>
                        <div className="tt_2">{t('new-main.need-service')}</div>
                        <Link to="/service" type="button" className="nm_btn_blue" lang="en">Get Started</Link>
                    </div>
                </div>
            </div>
            {videoModal && <VideoModal ref={childRef} setVideoModal={setVideoModal}/>}
            <PushSubscribeModal isOpen={modalOpen} onClose={() => {setModalOpen(false);}} onButtonClick={() => {actionForSubscribe();}}/>
            <DownloadModal isOpen={modalDOpen} setModalClose={() => {setModalDOpen(false);}}/>
            <MainNotice />
        </>
    )
}

export default Main

function getUserID(){
  //todo : check the user is logged in (depends on client)
  const isLoggedIn = false;
  let userId = 'exampleID';

  if(isLoggedIn){
    return `id_${userId}`;
  } else{
    return generateRandomString(20);
  }
}

const generateRandomString = (num) => {
  const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-=';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < num; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}