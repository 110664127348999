import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {isMobile} from "react-device-detect";
import {getSearchResult, getSiteCategoryDetail} from "../../Util/reqApi";
import setMetaTags from "../../Util/setMetaTags";
import SubHeader from "../Common/Component/SubHeader";
import CompanyListItem from "../Common/Component/CompanyListItem";
import {useParams} from 'react-router-dom';
import Loading from "../../Util/Loading";
import {Link} from "react-router-dom";
import ApiListItems from "../Common/Component/ApiListItem";

const SearchResult = (props) => {
  const { searchStr } = useParams();
  const { setSearchStr } = props;

  const [ cateData, setCateData ] = useState({});
  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState(null);

  const pageInfo = {
    subTitle : `${t('search.resultPrefix')}"${searchStr}"${t('search.resultPostfix')}`,
    subDesc : t('search.subDesc'),
    bgImgSrc : `${!isMobile ? cateData?.pcImageName ? `/uploads/banner/${cateData?.pcImageName}` : '' : cateData?.mobileImageName ? `/uploads/banner/${cateData?.mobileImageName}` : ''}`,
  }

  useEffect(()=>{
    getSiteCategoryDetail(1).then(result => setCateData(result));

    return() => {
      setCateData({});
    }
  },[])

  useEffect(() => {
    setMetaTags({
      title : `${t('search.resultPrefix')}"${searchStr}"${t('search.resultPostfix')}`,
    })

    if(searchStr){
      getSearchResult(searchStr).then((res) => {
        if(typeof res == "string"){
          setData(null);
        }else{
          setData(res);
        }

        setIsLoading(true);
      });
    }

    return() => {
      setData([]);
      setMetaTags({});
    }
  }, [searchStr])

  return (
      <>
        <SubHeader
            subTitle = {pageInfo.subTitle}
            subDesc = {pageInfo.subDesc}
            bgImgSrc = {pageInfo.bgImgSrc}
        />
        {isLoading ?
          ( data?.length > 0 ?
            (
                <div className="sub_api_list">
                  <div className="list_area">
                    <p className="searchResultTitle">
                      {t('search.searchResult')} ( <span className="searchResultNumStretch">{data.length}</span> )
                    </p>
                    <hr className="searchResultHr"/>
                    <p className="searchResultSubTitle">
                      Service ( <span className="searchResultNumStretch">{data.filter(el => el.mainCateNo !== 5).length}</span> )
                    </p>
                    {
                      data.filter(el => el.mainCateNo !== 5).length > 0 ?
                      (<ApiListItems data={data.filter(el => el.mainCateNo !== 5)}/>)
                      : (<p className="searchResultNoAPI">{t('search.noResultSub')}</p>)
                    }
                    <p className="searchResultSubTitle">
                      Marketplace ( <span className="searchResultNumStretch">{data.filter(el => el.mainCateNo === 5).length}</span> )
                    </p>
                    {
                      data.filter(el => el.mainCateNo === 5).length > 0 ?
                      (<ApiListItems data={data.filter(el => el.mainCateNo === 5)}/>)
                      : (<p className="searchResultNoAPI">{t('search.noResultSub')}</p>)
                    }
                  </div>
                </div>
            ) : (
                <div className="s_pt_mid_section">
                  <div className="pt_tit_area">
                    <div className="tt_area">
                      <img src='/images/no-results.png' className="searchNoResultIcon" alt=""/>
                      <div className="searchNoResultTitle">
                        {t('search.noResultPrefix')}"{searchStr}"{t('search.noResultPostfix')}
                      </div>
                      <div className="searchNoResultSubTitle">
                        {t('search.tryAgain')}
                      </div>
                      <Link to={'/'} onClick={() => {setSearchStr('');}}>
                        <button type="button" className="searchNoResultHomeButton">
                          {t('search.homeBtn')}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>)
          ) : (<Loading />)
        }
      </>
  )
}

export default SearchResult;