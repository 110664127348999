import React from 'react';

const Pagination = ({data : {page, setPage, numPages}}) => {
    return (
        <div className="paging_usage">
            <div className="ps_area">
                <span className={page == 1 ? 'disable imgb' : 'imgb'} onClick={()=>{Number(page) !== 1 && setPage(1)}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_first.png`} alt="맨 처음으로"/></a></span>
                <span className={page == 1 ? 'disable imgb' : 'imgb'} onClick={()=>{
                    if(Number(page-10) >= 1) {
                        setPage(Number(page-10))
                    }else if(Number(page-10) < 1){
                        setPage(Number(1))
                    }
                }}><a href="#" onClick={(e) => {e.preventDefault()}}>-10</a></span>
                <span className={page == 1 ? 'disable imgb' : 'imgb'} onClick={()=>{Number(page) !== 1 && setPage(Number(page-1))}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_pre.png`} alt="이전으로"/></a></span>
                {
                    numPages && Array(numPages).fill().map((_, idx)=>{
                        var numLayer = parseInt((page-1)/ 10);

                        if(numLayer == parseInt(idx/10)){
                            return(
                                <span
                                    key={idx+1}
                                    className={page == idx+1 ? 'on' : ''}
                                    onClick={()=>{setPage(idx+1)}}
                                >
                                    <a href="#" onClick={(e) => {e.preventDefault()}} title="현재 페이지">{idx+1}</a>
                                </span>
                            )
                        }else{
                            //make nothing
                        }
                    })
                }
                <span className={page == numPages? 'disable imgb' : 'imgb'} onClick={()=>{Number(page) !== Number(numPages) && setPage(Number(page+1))}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_next.png`} alt="다음으로"/></a></span>
                <span className={page == numPages? 'disable imgb' : 'imgb'} onClick={()=>{
                    if(Number(page+10) <= Number(numPages)) {
                        setPage(Number(page+10))
                    }else if(Number(page+10) > Number(numPages)){
                        setPage(Number(numPages))
                    }
                }}><a href="#" onClick={(e) => {e.preventDefault()}}>+10</a></span>
                <span className={page == numPages? 'disable imgb' : 'imgb'} onClick={()=>{Number(page) !== Number(numPages) && setPage(Number(numPages))}}><a href="#"><img src={`/images/ps_last.png`} alt="맨 마지막으로"/></a></span>
            </div>
        </div>
    );
};

export default Pagination;