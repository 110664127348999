import React from 'react';
import { t } from "i18next";

const ManualPanel = ({data :{apiData, slaveList}}) => {
    if(apiData[t('guideFileName')] || slaveList.filter(el => el[t('guideFileName')]).length > 0){
        return(
            <div className="mn_down_section">
                <div className="m_d_tit">{t('service.manualDownload')}</div>
                <div className="m_d_file">
                    {
                        apiData[t('guideFileName')].split('|').map((el,idx)=>{
                            return(
                                <span className="f_name" key={idx}>
                                    <a href={`/uploads/guide/${encodeURIComponent(el)}`} download={true}>
                                        {el}
                                    </a>
                                </span>
                            )
                        })
                    }
                    {
                        slaveList.filter(el => el[t('guideFileName')]).map((el, idx) => {
                            return(
                                <span className="f_name" key={idx}>
                                    <a href={`/uploads/guide/${encodeURIComponent(el[t('guideFileName')])}`} download={true}>
                                        {el[t('guideFileName')]}
                                    </a>
                                </span>
                            )
                        })
                    }
                </div>
            </div>
        )
    }else return null;
};

export default ManualPanel;