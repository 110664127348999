import React, {useState} from 'react';

const SvcAlert = (props) => {
    const {svcAlertModal, setSvcAlertModal, setSvcApplyModal} = props;
    return(
        <div className="pop_set_c" style={svcAlertModal ? {display:'block'} : {display:'none'}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page">
                        <div className="ppheader">
                            <div className="htit">알림</div>
                            <div className="rclose" onClick={()=>{setSvcAlertModal(false)}}><img src={'/images/icon_pop_close.png'} alt="닫기"/></div>
                        </div>
                        <div>
                            <div className="s_u_p_tit">서비스 신청을 해야 콘솔을 사용할 수 있습니다.</div>
                            <div className="s_u_p_btn">
                                <button
                                    className="login_as"
                                    onClick={()=>{
                                        setSvcAlertModal(false)
                                        setSvcApplyModal(true)
                                    }}
                                >
                                    서비스 신청하기
                                </button>
                            </div>
                        </div>
                        <div className="nt_btn_area_p">
                            <button className="btn_sm4" onClick={()=>{setSvcAlertModal(false)}}>닫기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default SvcAlert;