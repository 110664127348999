import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Loading from '../../Util/Loading';
import { changePassword, checkIdandPw, checkPassword, checkPassword2, getUserInfor } from '../../Util/reqApi';

const ChangePassword = () => {
    const [userInfor, setUserInfor] = useState();
    const [curPwd, setCurPwd] = useState();
    const [newPwd, setNewPwd] = useState();
    const [newPwd2, setNewPwd2] = useState();

    const navigate = useNavigate();

    useEffect(()=>{
        getUserInfor().then(result => setUserInfor(result));
    },[])

    if(userInfor){
    return(
        <div className="acc_section">
            <div className="script_b">회원님의 계정의 비밀번호를 변경할 수 있습니다.</div>
            <div className="table_area">
                <table className="table_mypageA">
                    <colgroup>
                        <col width="200px" />
                        <col width="*" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>아이디</th>
                            <td>{userInfor.userId}</td>
                        </tr>
                        <tr>
                            <th>현재 비밀번호 <span className="point">(필수)</span></th>
                            <td>
                                <input
                                    type="password" style={{width:"400px", border:'1px solid #ddd'}} placeholder="비밀번호를 입력해 주세요."
                                    onChange={(e)=>{setCurPwd(e.target.value)}} value={curPwd}
                                />
                                <p>
                                - 비밀번호는 8자 이상, 16자 이하의 영문자, 숫자 및 특수문자를 조합하여 사용해야 합니다. <br/>
                                - 최근 사용한 3개의 비밀번호는 재사용할 수 없습니다.<br/>
                                - ID와 유사한 비밀번호는 사용할 수 없습니다.<br/>
                                - 패스워드는 1일 1회 이상 변경할 수 없습니다.<br/>
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th>비밀번호 <span className="point">(필수)</span></th>
                            <td>
                                <input
                                    type="password" style={{width:"400px", border:'1px solid #ddd'}} placeholder="비밀번호를 입력해 주세요."
                                    onChange={(e)=>{setNewPwd(e.target.value)}} value={newPwd}
                            />
                            </td>
                        </tr>
                        <tr>
                            <th>비밀번호 확인 <span className="point">(필수)</span></th>
                            <td>
                                <input
                                    type="password" style={{width:"400px", border:'1px solid #ddd'}} placeholder="비밀번호를 다시 입력해 주세요."
                                    onChange={(e)=>{setNewPwd2(e.target.value)}} value={newPwd2}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="ip_btn_area">
                {/* <button style={{width:"200px",marginRight:"10px"}} className="login_1 ip_color3">취소</button> */}
                <button
                    style={{width:"200px"}} className="login_1"
                    onClick={()=>{
                        if(checkPassword(newPwd)){
                            if(checkPassword2(newPwd, newPwd2)){
                                if(!checkIdandPw(userInfor.userId, newPwd)){alert('ID와 유사한 비밀번호는 사용할 수 없습니다.'); return;}
                                changePassword(curPwd, newPwd).then(result=>{
                                    if(result == 'ok'){
                                        alert('비밀번호가 변경되었습니다. 다시 로그인 해주세요.')
                                        localStorage.removeItem('ACCESS_TOKEN');
                                        localStorage.removeItem('REFRESH_TOKEN');
                                        navigate('/', {replace:true});
                                    }else{
                                        alert(result)
                                    }
                                })
                            }else{
                                alert('비밀번호와 확인 비밀번호가 다릅니다.')
                            }
                        }else{
                            alert('비밀번호는 8자 이상, 16자 이하의 영문자, 숫자 및 특수문자를 조합하여 사용해야 합니다.')
                        }
                    }}
                >확인</button>
            </div>
        </div>
    )
    }else{
        return <Loading />
    }

}

export default ChangePassword;