import axios from 'axios';
import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import { IMG_URL } from '../../config';
import ModalTerm from '../../Layouts/Modal/modalTerm';
import Loading from '../../Util/Loading';
import { saveUserAuth, deleteUserAuth, getUserAuthList, checkMobile } from '../../Util/reqApi';

const Security = () => {
    const [userAuthList,setUserAuthList] = useState()
    const [saveAuthModal, closeSaveUserAuthModal] = useState(false)

    const [userAuthName, setUserAuthName] = useState()
    const [userAuthTel, setUserAuthTel] = useState()

    const [modalOpen2, setModalOpen2] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [modalType, setModalType] = useState();
    const [checkConsent, setCheckConsent] = useState();

    useEffect(()=>{
        getUserAuthList().then(result => setUserAuthList(result))
    },[])

    const modalClose = () => {closeSaveUserAuthModal(false); setUserAuthName(''); setUserAuthTel('');};
    const modalOpen = () => closeSaveUserAuthModal(true);

    const getUterm = async (type) => {
        setModalContent();
        setModalType(type)
        const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/main/getTerm?langNo=${t('langNo')}&pageNumber=0&pageSize=9999&type=${type}`);
        setModalContent(result.data.data);
    }

    if(userAuthList){
        return(
            <>
                <div className="acc_section">
                    <div className="table_area">
                        <div className="script_d"><b>2차 인증 담당자 설정</b>({userAuthList.length}명의 인증 사용자가 있습니다.)</div>
                        <table className="table_mypage">
                            <colgroup>
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>번호</th>
                                    <th>이름</th>
                                    <th>휴대폰</th>
                                    <th>신청일</th>
                                    {/* <th>승인</th> */}
                                    <th>삭제</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(()=>{
                                    if(userAuthList.length !== 0){
                                        return userAuthList.map((userAuth,idx)=>{
                                            return(
                                                <tr key={idx}>
                                                    <td>{idx+1}</td>
                                                    <td>{userAuth.managerName}</td>
                                                    <td>{userAuth.authTel}</td>
                                                    <td>{userAuth.regDate[0]}-{userAuth.regDate[1]}-{userAuth.regDate[2]}</td>
                                                    {/* <td>
                                                        <div
                                                            className={userAuth.enabled ? "toggle_btn active" : "toggle_btn"}
                                                            onClick={()=>{
                                                                changeUserAuthStatus(userAuth.authNo, userAuth.enabled).then(
                                                                    ()=>getUserAuthList().then(result => setUserAuthList(result))
                                                                );
                                                            }}
                                                        >
                                                            <input type="checkbox" className="cb-value" />
                                                            <span className="round-btn"></span>
                                                        </div>
                                                    </td> */}
                                                    <td>
                                                        <button
                                                            className="btn_sm"
                                                            onClick={()=>{
                                                                deleteUserAuth(userAuth.authNo).then(
                                                                    ()=>getUserAuthList().then(result => setUserAuthList(result))
                                                                )
                                                            }}
                                                        >삭제</button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }else return <tr><td colSpan={7}>데이터가 없습니다.</td></tr>
                                })()}                                
                            </tbody>
                        </table>
                    </div>
                    <div className="ip_btn_area2">
                        <button style={{width:"200px"}} className="login_1" onClick={modalOpen}>인증 담당자 추가</button>
                    </div>
                </div>

                {saveAuthModal &&
                    <div id="" className="pop_set_b" style={saveAuthModal ? {display:"block"} : {display:"none"}}>
                        <div className="pop_section">
                            <div className="parea">
                                <div className="page">
                                    <div className="ppheader">
                                        <div className="htit">2차 인증 담당자 추가</div>
                                        <div className="rclose" onClick={modalClose}><img src={`/images/icon_pop_close.png`} alt="닫기"/></div>
                                    </div>
                                    <div className="">
                                        <table className="table_mypageB" style={{width:"80%",margin:"0 auto"}}>
                                            <colgroup>
                                                <col width="100px" />
                                                <col width="*" />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <td>담당자 이름</td>
                                                    <td>
                                                        <input
                                                            type="text" style={{width:"100%",maxWidth:"300px"}} placeholder="이름을 입력해주세요."
                                                            onChange={(e)=>{setUserAuthName(e.target.value)}} value={userAuthName}
                                                    />
                                                    </td>
                                                </tr>
                                                {/* <tr>
                                                    <td>이메일</td>
                                                    <td><input type="text"style={{width:"100%",maxWidth:"300px"}} placeholder="이메일을 입력해주세요." /></td>
                                                </tr> */}
                                                <tr>
                                                    <td>휴대폰 번호</td>
                                                    <td>
                                                        <input
                                                            type="text" style={{width:"100%",maxWidth:"300px"}} placeholder="휴대폰 번호를 “-“ 빼고 입력해 주세요."
                                                            onChange={(e)=>{setUserAuthTel(e.target.value)}} value={userAuthTel}
                                                        />
                                                        {/* <button className="btn_sm3" style={{width:"96px"}}>인증요청</button> */}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div style={{margin: "25px auto", width:"80%"}}>
                                            <div style={{float:"left", marginRight:"10px", lineHeight: 2.0}}>
                                                <input id="checkConsent" type="checkbox" checked={checkConsent} onClick={() => {setCheckConsent(!checkConsent)}}/>
                                            </div>
                                            <div style={{fontFamily: "Roboto,Noto Sans KR,-apple-system,BlinkMacSystemFont,sans-serif"}}>
                                                <label htmlFor="checkConsent" className="script_a" style={{width: "80%", margin: "20px auto"}}>수집하는 개인 정보[(필수) 2차 인증 담당자 이름 및 휴대전화번호]는 회원 관리를 위해 사용됩니다. 회원은 동의를 거부하실 수 있으며, 동의 거부 시 2차 인증 서비스의 이용이 제한될 수 있습니다.</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nt_btn_area_p">
                                        <button className="btn_sm4" onClick={modalClose}>취소</button>
                                        <button className={(userAuthName && checkConsent && userAuthTel)?"btn_sm6":"btn_sm6_disabled" }
                                            disabled={!(userAuthName && checkConsent && userAuthTel)}
                                            onClick={()=>{
                                                if(userAuthName){
                                                    if(!checkMobile(userAuthTel)){alert('휴대폰번호를 확인해주세요.'); return}
                                                    saveUserAuth(userAuthName,String(userAuthTel).replace(`\-\g`,'')).then(()=>{                                                    
                                                        getUserAuthList().then(result => {
                                                            setUserAuthName('');
                                                            setUserAuthTel('');
                                                            modalClose();
                                                            setUserAuthList(result)
                                                        })
                                                    })
                                                }else{
                                                    setUserAuthName('');
                                                    setUserAuthTel('');
                                                    modalClose();                                                
                                                }
                                            }}
                                        >추가하기</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <ModalTerm modalOpen={modalOpen2} setModalClose={() => {setModalOpen2(false)}} setModalOpen={setModalOpen2} modalContent={modalContent} type={modalType}/>
            </>
        )
    }else return <Loading />
}

export default Security;