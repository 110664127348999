import React, {useState, useEffect} from 'react'
import { IMG_URL } from "../../config";
import Loading from '../../Util/Loading';
import { getPaymentLog, getUserInfor } from '../../Util/reqApi';
import PayModal from './Component/PayModal';

const Payment = () => {
    const [cash, setCash] = useState(0);
    const [callModal, setCallModal] = useState(false);
    const [paymentLogs, setPaymentLogs] = useState();
    const [selectedYear, setSelectedYear] = useState(`${new Date().getFullYear()}년`);
    const [selectedMonth, setSelectedMonth] = useState(`${new Date().getMonth()+1}월`);
    const [totalAmount, setTotalAmount] = useState(0);
    const [userInfor, setUserInfor] = useState();

    let years = []
    const month = [1,2,3,4,5,6,7,8,9,10,11,12];
    const nowYear = new Date().getFullYear();
    const startYear = 2020;
    for(let i = nowYear; i >= startYear; i --){
        years.push(i)
    }

    useEffect(()=>{
        getUserInfor().then(result => {
            setUserInfor(result);
        });
        setSelectedMonth(`${new Date().getMonth()+1}월`)
        return () => {setUserInfor();}
    },[])

    useEffect(()=>{
        getUserInfor().then(result => setUserInfor(result));
        let targetYear = selectedYear.replace('년','');
        let targetMonth = selectedMonth.replace('월','')
        let targetStateDate = '01'
        let targetEndDate = new Date(targetYear, targetMonth, 0).getDate();
        getPaymentLog(`${targetYear}-${targetMonth < 10 ? `0${targetMonth}` : targetMonth}-${targetStateDate} 00:00:00`, `${targetYear}-${targetMonth < 10 ? `0${targetMonth}` : targetMonth}-${targetEndDate} 23:59:59`).then(result => {
            setPaymentLogs(result);
            setTotalAmount(sumTotalAmount(result));
        })
    },[callModal])

    useEffect(()=>{
        setTotalAmount(0);
        let targetYear = selectedYear.replace('년','');
        let targetMonth = selectedMonth.replace('월','')
        let targetStateDate = '01'
        let targetEndDate = new Date(targetYear, targetMonth, 0).getDate();
        getPaymentLog(`${targetYear}-${targetMonth < 10 ? `0${targetMonth}` : targetMonth}-${targetStateDate} 00:00:00`, `${targetYear}-${targetMonth < 10 ? `0${targetMonth}` : targetMonth}-${targetEndDate} 23:59:59`).then(result => {
            setPaymentLogs(result);
            setTotalAmount(sumTotalAmount(result));
        })
    },[selectedYear, selectedMonth])

    const sumTotalAmount = (result) => {
        if(result === 'err') return []
        return result.reduce((acc,cur)=>{
            if(cur.total_amount){
                if(cur.payment_status === 'completed'){
                    return acc + cur.total_amount
                }else{
                    return acc
                }
            }else{
                return acc
            }
        },0);
    }

    if(paymentLogs && userInfor){
        return(
            <>
                <div id="tab-1" className="tab-content current">
                    {
                        userInfor.paymentType !== 'postpayment' &&
                        <div className="usageBox">
                            <div className="Price_tt">
                                사용 가능 금액 :<span>{userInfor.remainingAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>원
                            </div>
                            <div className="graph">
                                <div className="percent_bar" style={{width:(()=>{
                                    if(userInfor.lastMonthPayment){
                                        return `${(userInfor.remainingAmount / userInfor.lastMonthPayment) * 100}%`
                                    }else{
                                        return `${(userInfor.remainingAmount / 1000) * 100}%`
                                    }
                                })()}}></div>
                            </div>
                            <div className="r_btn" onClick={()=>{
                                if(userInfor?.userStatus?.paymentAvailable){
                                    setCallModal(true)
                                }
                                else{
                                    alert('결제는 정식 계정 전환 후 가능 합니다.');
                                }
                            }}><button><img src={`${IMG_URL}/images/icon_card.png`} alt=""/>충전하기</button></div>
                        </div>
                    }
                    <div className="option_bar">
                        <span>기간선택</span>
                        <select value={selectedYear} onChange={(e)=>setSelectedYear(e.target.value)}>
                            {
                                years.map((el,idx)=>{
                                    return(
                                        <option key={idx}>{`${el}년`}</option>
                                    )
                                })
                            }
                        </select>
                        <select value={selectedMonth} onChange={(e)=>setSelectedMonth(e.target.value)}>
                            {
                                month.map((el,idx)=>{
                                    return(
                                        <option key={idx}>{`${el}월`}</option>
                                    )
                                })
                            }
                        </select>
                        {/* <div className="r_area">총 이용 기간 : <span className="ac">2021년 9월 1일 ~ 2021년 9월 30일</span></div> */}
                    </div>
                    <div className="table_area">
                        <table className="table_mypage">
                            <colgroup>
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                                <col width="*" />
                            </colgroup>
                            <thead>
                                    {
                                        userInfor.paymentType !== 'postpayment'
                                        ?
                                            <tr>
                                                <th>결제일</th>
                                                <th>결제 계정</th>
                                                <th>충전금액(원)</th>
                                                <th>결제상태</th>
                                                <th>결제수단</th>
                                            </tr>
                                        :
                                            <tr>
                                                <th>충전일</th>
                                                <th>충전 계정</th>
                                                <th>충전금액(원)</th>
                                                <th>충전상태</th>
                                                <th>충전수단</th>
                                            </tr>
                                    }
                            </thead>
                            <tbody>
                                {
                                    paymentLogs !== 'server error' && paymentLogs !== 'err' && paymentLogs.length !== 0
                                    ?
                                    paymentLogs.map((el,idx)=>{
                                        return <PayLog key={idx}>{el}</PayLog>
                                    })
                                    :
                                    <tr>
                                        <td colSpan={5}>
                                            데이터가 없습니다.
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="option_bar2">
                        <div className="r_area2">총 충전 금액 : <span className="ac">{totalAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>원</div>
                    </div>
                    <div className="script_b">
                        - 자세한 요금 납부 기간은 자주하는 질문(FAQ)을 참고하시기 바랍니다. <br/>
                        - 금액의 10% 가 남아 있는 경우 알림톡으로 알려드립니다.<br/><br/><br/>
                    </div>
                </div>
                {callModal && <PayModal callModal={callModal} setCallModal={setCallModal} userInfor={userInfor}/>}
            </>
        )
    }else{
        return <Loading />
    }
}

const PayLog = (props) => {
    const { children : { requested_datetime,manager_name, total_amount, payment_status, payment_method, trade_id } } = props;
    const paymentStatus = {requested : "요청중", completed : "완료", canceled_or_failed : "실패 혹은 취소"}
    const paymentMethod = {card : "신용카드", account : "계좌이체", virtual_account : "가상계좌", mobile : "핸드폰 결제", kakao: '카카오 페이', naver: '네이버 페이'}
    // console.log(props.children)
    return(
        <tr>
            <td>{requested_datetime.slice(0,-4)}</td>
            <td>{manager_name}</td>
            <td>{`${total_amount ? total_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}원`}</td>
            <td>{paymentStatus[payment_status] || null}</td>
            <td>{paymentMethod[payment_method] || payment_method}
                {(()=>{
                    if(trade_id){
                        return(
                            <button
                            className="btn_sm"
                            id="tradeId"
                            onClick={()=>{
                                let url    = `https://cjpay.cjsystems.co.kr/cjs/pg/adj/receipt.fo?SHOP_SYS_NO=${trade_id}&SHOP_ID=CJ2205200001`;
                                let title  = "구매 영수증";
                                let status = "toolbar=no,scrollbars=no,resizable=yes,status=no,menubar=no,width=425, height=550, top=250,left=435"; 
                                window.open(url, title,status);
                            }}
                            >영수증</button>
                        )
                    }else return null
                })()}
            </td>
        </tr>
    )
}

export default Payment;