import React from 'react';

const Pagination = ({data : {page, setPage, numPages}}) => {
    return (
        <div className="paging">
            <div className="ps_area">
                <span className={page == 0 ? 'disable imgb' : 'imgb'} onClick={()=>{Number(page) !== 0 && setPage(0)}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_first.png`} alt="맨 처음으로"/></a></span>
                <span className={page == 0 ? 'disable imgb' : 'imgb'} onClick={()=>{Number(page) !== 0 && setPage(Number(page-1))}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_pre.png`} alt="이전으로"/></a></span>
                {
                    numPages && Array(numPages).fill().map((_, idx)=>{
                        return(
                            <span
                                key={idx}
                                className={page == idx ? 'on' : ''}
                                onClick={()=>{setPage(idx)}}
                            >
                                <a href="#" onClick={(e) => {e.preventDefault()}} title="현재 페이지">{idx + 1}</a>
                            </span>
                        )
                    })
                }
                <span className={page == numPages-1 ? 'disable imgb' : 'imgb'} onClick={()=>{Number(page) !== Number(numPages-1) && setPage(Number(page+1))}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_next.png`} alt="다음으로"/></a></span>
                <span className={page == numPages-1 ? 'disable imgb' : 'imgb'} onClick={()=>{Number(page) !== Number(numPages-1) && setPage(Number(numPages-1))}}><a href="#" onClick={(e) => {e.preventDefault()}}><img src={`/images/ps_last.png`} alt="맨 마지막으로"/></a></span>
            </div>
        </div>
    );
};

export default Pagination;