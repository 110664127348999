export const validatePostPassword = (pwd) => {
  const maxLen = 16;
  const minLen = 4;
  const alphanumericRegex = /^[a-zA-Z0-9]+$/;

  if (pwd && pwd.length <= maxLen && pwd.length >= minLen) {
    return alphanumericRegex.test(pwd);
  } else {
    return false;
  }
}






