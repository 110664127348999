import React, {useEffect, useState} from "react";
import {t} from "i18next";
import CompanyListItem from "../Common/Component/CompanyListItem";
import SubHeader from "../Common/Component/SubHeader";
import {isMobile} from "react-device-detect";
import {getSiteCategoryDetail} from "../../Util/reqApi";
import setMetaTags from "../../Util/setMetaTags";
const Company = () => {
  const [ cateData, setCateData ] = useState({});

  const pageInfo = {
    subTitle : t('company.title'),
    subDesc : '',
    bgImgSrc : `${!isMobile ? cateData?.pcImageName ? `/uploads/banner/${cateData?.pcImageName}` : '' : cateData?.mobileImageName ? `/uploads/banner/${cateData?.mobileImageName}` : ''}`,
  }

  useEffect(()=>{
    getSiteCategoryDetail(3).then(result => setCateData(result))
    setMetaTags({
      title : t('company.title'),
    })

    return() => {
      setCateData();
      setMetaTags({});
    }
  },[])

  return (
    <>
      <SubHeader
          subTitle = {pageInfo.subTitle}
          subDesc = {pageInfo.subDesc}
          bgImgSrc = {pageInfo.bgImgSrc}
          noti_text = {true}
      />
      <div className="page_title_area">
        <div className="title">{t('main.partnerTitle')}</div>
        <div className="sub_script">{t('main.partnerDesc')}</div>
      </div>
      <div className="company_list_section">
        <div className="logo_list">
          <ul>
            <CompanyListItem />
          </ul>
        </div>
      </div>
    </>
  )
}

export default Company