import React from 'react';

const UsageTableHeader = () => {
    return(
        <>
            <colgroup>
                <col width="*" />
                <col width="*" />
                <col width="*" />
                <col width="*" />
                <col width="*" />
                <col width="*" />
            </colgroup>
            <thead>
                <tr>
                    <th>서비스</th>
                    <th>당월 사용금액(원)</th>
                    <th>사용량</th>
                    <th>프로모션</th>
                    <th>상태</th>
                    <th>세부내용</th>
                </tr>
            </thead>
        </>
    )
}

export default UsageTableHeader;