import React,{ useEffect, useState } from "react";
import Loading from "../../Util/Loading";
import ApiListItems from "../Common/Component/ApiListItem";
import SubHeader from "../Common/Component/SubHeader";
import { getApiCategorys, getApiItems, getSiteCategoryDetail, getSvcMainCategory } from "../../Util/reqApi";
import { t } from "i18next";
import { isMobile } from "react-device-detect";
import setMetaTags from "../../Util/setMetaTags";
import {useLocation} from "react-router-dom";
import PageInfo from '../../page_info.json';

const Service = () => {
    const [ cateData, setCateData ] = useState({});
    const [ apiCategorys, setApiCategorys] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const location = useLocation(); // 푸터 사이트맵에서 진입 시 초기 페이지 설정

    useEffect(()=>{
        getSiteCategoryDetail(1).then(result => setCateData(result));
        getApiCategorys().then(result => {
            Promise.all(result
                .filter(el => {return el.svcMainCategory.mainCateNo !== 5})
                .map(el => {
                return getApiItems(el.cateNo).then(apiItems => {
                    if(apiItems.length !== 0){
                        el.visible = true;
                        el.data = apiItems;
                    }
                })
            })).then(()=> {
                setApiCategorys(result);
                setIsLoading(true);
                document.getElementById(location.state?.url)?.scrollIntoView();
            })
        });

        setMetaTags({
            title: PageInfo.Service.title,
            description: PageInfo.Service.description,
            keywords: PageInfo.Service.keywords
        });

        return () => {
            setCateData({});
            setApiCategorys([]);
            setMetaTags({});
        }
    },[])

    useEffect(()=>{
        document.getElementById(location.state?.url)?.scrollIntoView();
    },[location.state])


    return (
        <>
            <h1 style={{display:"none"}}>{PageInfo.Service.h1}</h1>
            <SubHeader
                subTitle = {cateData[t('subBanner.headingText')]}
                subDesc = {cateData[t('subBanner.content')]}
                bgImgSrc = {!isMobile ? cateData.pcImageName ? `/uploads/banner/${cateData.pcImageName}` : '' : cateData.mobileImageName ? `/uploads/banner/${cateData.mobileImageName}` : ''}
            />
            {
                isLoading
                ?
                    apiCategorys.map((el, idx) => {
                        if(el.visible){
                            return(
                                <div className="sub_api_list" key={idx} id={`service_${el.cateNo}`}>
                                    <div className="list_area">
                                        <div className="title">
                                            <img src={`/uploads/category/${el.iconFileName}`} alt=""/>
                                            <span>{el[t('apiCategory.cateName')]}</span>
                                        </div>
                                        <ApiListItems key={idx} data={ el.data } />
                                    </div>
                                </div>
                            )
                        }
                    })
                : <Loading />
            }
        </>
    );
}

export default Service;