import React, {useEffect, useState} from 'react';
import { CSVLink } from 'react-csv';
import Loading from '../../../Util/Loading';
import { getUsageDetail } from '../../../Util/reqApi';

const UsageDetail = (props) => {
    const {
        usage,
        usageDetailModal,
        setUsageDetailModal,
        modalDate,
        startDate,
        endDate
    } = props;

    const [usageData, setUsageData] = useState();
    const [csvData, setCsvData] = useState([]);

    const closeModal = () => {
        setUsageDetailModal(false);
    }

    useEffect(()=>{
        getUsageDetail(
            {
                fromRequestedDatetime : `${modalDate.modalStart} 00:00:00`,
                toRequestedDatetime : `${modalDate.modalEnd} 23:59:59`,
                serviceNo : usage.service_no,
                promotionNo : usage.promotion_no,
                statusName : usage.status_name
            }
        ).then(result=>{
            if(result === 'NO_DATA') return
            // console.log(result)
            const tableRow = [{
                "계정" : result.user_id,
                "서비스" : result.service_name_kr,
                "시작일" : modalDate.modalStart,
                "종료일" : modalDate.modalEnd,
                "상태" :(()=>{
                    switch(result.status){
                        case '10' :
                            return "서비스 요청 접수"
                        case '20' :
                            return "서비스 전송"
                        case '30' :
                            return "서비스 성공"
                        case '40' :
                            return "FAILBACK 성공"
                        case '60' :
                            return "실패-여신부족"
                        case '70' :
                            return "서비스 실패"
                        case '80' :
                            return "결과 수신 실패"
                        default : return result.status
                    }
                })(),
                "사용량" : result.use_count,
                "프로모션" : result.promotion_no,
            }]
            setCsvData(tableRow)
            setUsageData(result)
        })
    },[])
    if(usageData === "err"){return(
        <div className="pop_set_c" style={{display:'block'}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page">
                        <div className="ppheader">
                            <div className="htit">알림</div>
                            <div className="rclose" onClick={()=>{setUsageDetailModal(false)}}><img src={'/images/icon_pop_close.png'} alt="닫기"/></div>
                        </div>
                        <div>
                            <div className="s_u_p_tit">Sever Error : 500</div>
                            <div className="s_u_p_btn">
                                <button
                                    className="login_as"
                                    onClick={()=>{
                                        setUsageDetailModal(false)
                                    }}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )}
    if(usageData){
        return(
            <div id="" className="pop_set_b">
                <div className="pop_section">
                    <div className="parea">
                        <div className="page">
                            <div className="ppheader">
                                <div className="htit">{usageData.service_name_kr} 상품 조회</div>
                                <div className="rclose" onClick={closeModal}><img src={`/images/icon_pop_close.png`} alt="닫기"/></div>
                            </div>
                            <div className="">
                                <div className="ur_pp_tit">청구 월: {modalDate.modalMonth +1 }월</div>
                                <table className="table_mypage">
                                    <colgroup>
                                        <col width="*" />
                                        <col width="*" />
                                        <col width="*" />
                                        <col width="*" />
                                        <col width="*" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>계정</th>
                                            <th>서비스</th>
                                            <th>시작일</th>
                                            <th>종료일</th>
                                            <th>상태</th>
                                            <th>사용량</th>
                                            <th>프로모션</th>
                                        </tr>
                                    </thead>    
                                    <tbody>
                                        <tr>
                                            <td>{usageData.user_id}</td>
                                            <td>{usageData.service_name_kr}</td>
                                            <td>{modalDate.modalStart}</td>
                                            <td>{modalDate.modalEnd}</td>
                                            <td>{
                                                    (()=>{                                                        
                                                        switch(usageData.status){
                                                            case '10' :
                                                                return "서비스 요청 접수"
                                                            case '20' :
                                                                return "서비스 전송"
                                                            case '30' :
                                                                return "서비스 성공"
                                                            case '40' :
                                                                return "FAILBACK 성공"
                                                            case '60' :
                                                                return "실패-여신부족"
                                                            case '70' :
                                                                return "서비스 실패"
                                                            case '80' :
                                                                return "결과 수신 실패"
                                                            default : return usageData.status
                                                        }
                                                    })()
                                            }</td>
                                            <td>{usageData.use_count}</td>
                                            <td>{usageData.promotion_no}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="script_b">
                                - 서비스 이용 요금 정산은 한국 표준시간대(UTC+9)를 기준으로 산정됩니다.
                                </div>
                            </div>
                            <div className="nt_btn_area_p">
                                <button className="btn_sm4" onClick={closeModal}>닫기</button>
                                <CSVLink
                                    filename={`${modalDate.modalStart}-${modalDate.modalEnd}_${usageData.service_name_kr}`}
                                    data={csvData}
                                >
                                    <button className="btn_sm6">엑셀다운</button>
                                </CSVLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else{
        return <div style={{position:'fixed', zIndex:'999', width:'100%', height:'25vh',top:'50%', left:'50%', transform:'translate3d(-50%,-50%,0)'}}><Loading /></div>
    }
}

export default UsageDetail;