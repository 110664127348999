import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logout from '../../Util/logout';
import MenuH1 from './Components/MenuH1';
import { useTranslation  } from 'react-i18next';
import i18n from '../../lang/i18n';
import { getApiDetail } from '../../Util/reqApi'
import { useGlobal } from '../../shared/globalContext';
import SearchBar from "../../Pages/Search/SearchBar";
import SearchSuggestion from "../../Pages/Search/SearchSuggestion";

const Header = ( props ) => {
    const global = useGlobal();
    const {login, setLogin, menus} = global;
    const {searchStr, setSearchStr} = props;

    const { pathname } = useLocation();
    const { t } = useTranslation();
    const [ apiData, setApiData] = useState();

    const changeLang = (lang) =>{
        i18n.changeLanguage(lang).then(()=>localStorage.setItem('lan',lang));
    }

    const closeHamburgurMenu = () => {
        document.getElementById('toggle').checked = false;
    }

    const toggleLangMenu = () => {
        setDropDown(!dropDown);
        document.getElementById('toggle-arrow').checked = !dropDown;
    }

    const closeLangMenu = () => {
        setDropDown(false);
        document.getElementById('toggle-arrow').checked = false;
    }

    useEffect(()=>{
        // URL 변경 시 검색창 초기화
        setSearchStr('');

        if(window.location.href.indexOf('/service/apiservices') === -1){
            document.querySelector('body').setAttribute('oncontextmenu', 'return false')
            document.querySelector('body').setAttribute('onselectstart', 'return false')
            document.querySelector('body').setAttribute('ondragstart', 'return false')
        }else{
            document.querySelector('body').removeAttribute('oncontextmenu')
            document.querySelector('body').removeAttribute('onselectstart')
            document.querySelector('body').removeAttribute('ondragstart')
        }
    },[pathname])

    const [dropDown, setDropDown] = useState(false);

    useEffect(()=>{
        window.scrollTo(0,0);

        if(pathname.indexOf('/service/apiservices/') !== -1){
            getApiDetail(pathname.split('/')[3]).then(result => {
                setApiData(result);
            });
        }
        return () => {
        }
    },[pathname])

    return(
        <header>
            <div className="logo" tabIndex={0}>
                <Link to="/">
                    <img src={'/images/logo.png'} alt="API PLEX logo"/>
                </Link>
            </div>
            <div id="top_menu" className="top_menu">
                <ul>
                    {menus && menus.map((menu, idx) =>
                            <MenuH1
                                key={idx}
                                LinkTo={menu.url}
                                innerText={menu[t('cateName')]}
                                on={
                                    (()=>{
                                        if(pathname.indexOf('/service/apiservices/') !== -1){
                                            if(apiData?.svcCategory.svcMainCategory.mainCateNo === 5){
                                                if(menu.url.replace('/','') == '/marketplace'){
                                                    return true;
                                                }
                                            }else{
                                                if(menu.url.replace('/','') == '/service'){
                                                    return true;
                                                }
                                            }
                                        }else{
                                            return `${pathname.split('/')[1]}` === menu.url.replace('/','').split('/')[0]
                                        }
                                    })()
                                }
                            />
                        )
                    }
                </ul>
            </div>
            <div className = "header_right">
                <div className="lanselect">
                    <div className="langName" onClick={(e)=>{toggleLangMenu(); closeHamburgurMenu();}}>
                        <span className="countryCode">{t("header.selectedLang")}</span>
                        <label htmlFor="toggle-arrow" className="arrow-toggle">
                            <input type="checkbox" id="toggle-arrow" style={{display:"none"} }/>
                            <div className="arrow-down"></div>
                        </label>
                    </div>
                    <div className="langseltBox" style={dropDown ? {display:"block"} : null}>
                        <button id="lanaSelt_1" onClick={() => {changeLang('ko'); setDropDown(false); window.location.href ='/'}}>KOR</button>
                        <button id="lanaSelt_2" onClick={() => {changeLang('en'); setDropDown(false); window.location.href ='/'}}>ENG</button>
                    </div>
                </div>
                <div className='headerRightAlign'>
                    {
                        (()=>{
                            if(t('langCode') !== "ko") return null
                            if(!login){return(
                                <>
                                    <span><Link to='/login'>{t('header.login')}</Link></span>
                                    {/* <span><Link to='/signUp/1'>{t('header.signup')}</Link></span> */}
                                </>
                            )}else{return(
                                <>
                                    <span onClick={()=>{logout(setLogin)}}><Link to='/login'>{t('header.logout')}</Link></span>
                                    <span><Link to='/mypage/payment'>{t('header.mypage')}</Link></span>
                                </>
                            )
                            }
                        })()
                    }
                </div>
                <label htmlFor="toggle" className="hamburger">
                    <input type="checkbox" id="toggle" onClick={closeLangMenu}/>
                    <span></span>
                    <span></span>
                    <span></span>
                    <div className='headerRightAlign_hamburgerMenu'>
                    {
                        (()=>{
                            if(t('langCode') !== "ko") return null
                            if(!login){return(
                                <>
                                    <button onClick={()=>{closeHamburgurMenu();}}><Link to='/login'>{t('header.login')}</Link></button>
                                    {/* <button onClick={()=>{closeHamburgurMenu();}}><Link to='/signUp/1'>{t('header.signup')}</Link></button> */}
                                </>
                            )}else{return(
                                <>
                                    <button onClick={()=>{closeHamburgurMenu(); logout(setLogin);}}><Link to='/login'>{t('header.logout')}</Link></button>
                                    <button onClick={()=>{closeHamburgurMenu();}}><Link to='/mypage/payment'>{t('header.mypage')}</Link></button>
                                </>
                            )
                            }
                        })()
                    }
                    </div>
                </label>
                <SearchBar searchStr={searchStr} setSearchStr={setSearchStr} />
                <SearchSuggestion searchStr={searchStr} />
            </div>
        </header>
    )
}

export default Header;