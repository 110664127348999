import  { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA4 from "react-ga4";
import noIndex from './setMetaTagsNoindex';
import TagManager from "react-gtm-module";

const RouteChangeTracker = () => {
    const location = useLocation();
    const [init, setInit] = useState(false);

    useEffect(()=>{
        if(!window.location.href.includes('localhost')){
            ReactGA4.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
            TagManager.initialize({gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID});
        }
        setInit(true);
    },[])

    useEffect(()=>{
        if(ReactGA4){
            ReactGA4.send({ hitType: "pageview", page: location.pathname + location.search });
        }
        noIndex(false);
        if(location.pathname.indexOf('/login') !== -1){noIndex(true); return false;}
        // if(location.pathname.indexOf('/signUp') !== -1){noIndex(true); return false;}
        if(location.pathname.indexOf('/mypage') !== -1){noIndex(true); return false;}

    },[init, location])

    return <></>;
};

export default RouteChangeTracker;