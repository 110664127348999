import React from 'react';
import { Link } from 'react-router-dom';
import { t } from 'i18next';

const SubInfoArea = (props) => {
    const { type, cate } = props;

    return(
        <div className={`sup_info_area sub`}>
            <ul className={`sub`}>
                <li className = {type == "sub" && cate == 'notice' ? 'on' : undefined }>
                    <Link to="/support/notice?cate=0&page=0">
                        <div className="info_box">
                        <div className="i_img">
                                <div className="img_area">
                                    <img src={`/images/img_sup_info_n1.png`} alt=""/>
                                </div>
                            </div>
                            <div className="i_title">{t('support.notice')}</div>
                            {!type && <div className="i_infoText">{t('support.notice_desc')}</div>}
                        </div>
                    </Link>
                </li>
                <li className = {type == "sub" && cate == 'faq' ? 'on' :undefined }>
                    <Link to="/support/faq">
                        <div className="info_box">
                            <div className="i_img">
                                <div className="img_area">
                                    <img src={`/images/img_sup_info_n2.png`} alt=""/>
                                </div>
                            </div>
                            <div className="i_title">{t('support.faq')}</div>
                            {!type && <div className="i_infoText">{t('support.faq_desc')}</div>}
                        </div>
                    </Link>
                </li>
                <li className = {type == "sub" && cate == 'requests' ? 'on' :undefined }>
                    <Link to="/support/requests">
                        <div className="info_box">
                        <div className="i_img">
                                <div className="img_area">
                                    <img src={`/images/img_sup_info_n3.png`} alt=""/>
                                </div>
                            </div>
                            <div className="i_title">{t('support.inquiry')}</div>
                            {!type && <div className="i_infoText">{t('support.inquiry_desc')}</div>}
                        </div>
                    </Link>
                </li>
                <li className = {type == "sub" && cate == 'requestsSearch' ? 'on' :undefined }>
                    <Link to="/support/requestsSearch">
                        <div className="info_box">
                        <div className="i_img">
                                <div className="img_area">
                                    <img src={`/images/img_sup_info_n4.png`} alt=""/>
                                </div>
                            </div>
                            <div className="i_title">{t('support.inquiry_a')}</div>
                            {!type && <div className="i_infoText">{t('support.inquiry_a_desc')}</div>}
                        </div>
                    </Link>
                </li>
                <li className = {type == "sub" && cate == 'apiAll' ? 'on' :undefined }>
                    <Link to="/support/apiAll">
                        <div className="info_box">
                        <div className="i_img">
                                <div className="img_area">
                                    <img src={`/images/img_sup_info_n5.png`} alt=""/>
                                </div>
                            </div>
                            <div className="i_title">{t('support.inquiry_api')}</div>
                            {!type && <div className="i_infoText">{t('support.inquiry_api_desc')}</div>}
                        </div>
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default SubInfoArea;