import React, {useState, useEffect} from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import SubHeader from '../Common/Component/SubHeader';
import SubInfoArea from './Component/SubInfoArea';
import {changeUserInfo, checkEmail, checkMobile, getReqCategory, getSiteCategoryDetail, getSvcMainCategory, getUserInfor, sendReq } from '../../Util/reqApi';
import Loading from '../../Util/Loading';
import { isMobile, isIOS } from 'react-device-detect';
import { useGlobal } from '../../shared/globalContext';
import setMetaTags from '../../Util/setMetaTags';
import PageInfo from '../../page_info.json';
import {validatePostPassword} from "../../Util/customValidate";
import axios from "axios";
import ModalTerm from "../../Layouts/Modal/modalTerm";

const ApiAll = () => {
    const global = useGlobal();
    const { login, setLogin } = global;

    const [cateData, setCateData] = useState({});
    const [useNomemberReq, setUseNomemberReq] = useState(false);

    const apiAll = {
        subTitle : t('support.inquiry_api'),
        subDesc : t('support.inquiry_api_desc'),
        bgImgSrc : `${!isMobile ? cateData?.pcImageName ? `/uploads/banner/${cateData?.pcImageName}` : '' : cateData?.mobileImageName ? `/uploads/banner/${cateData?.mobileImageName}` : ''}`,
    }    

    useEffect(()=>{
        setMetaTags({
            title : PageInfo.ApiAll.title,
            description : PageInfo.ApiAll.description,
            keywords : PageInfo.ApiAll.keywords
        })
        isMobile && setLogin(false);
        getSiteCategoryDetail(4).then(result => { setCateData(result) })
    },[])

    return(
        <>
        <h1 style={{display:"none"}}>{PageInfo.ApiAll.h1}</h1>
            <SubHeader
                subTitle = {apiAll.subTitle}
                subDesc = {apiAll.subDesc}
                bgImgSrc = {apiAll.bgImgSrc}
                noti_text = {true}
            />
            <SubInfoArea
                type="sub"
                cate="apiAll"
            />
            <div className="support_content">
            {
                (()=>{
                    if(t('langNo') !== '1'){return <NomemberReqFrom />}
                    if(isMobile || isIOS){return <NomemberReqFrom />}

                    if(login){
                        // 로그인 상태일 경우
                        return <ReqFrom />
                    }else{
                        // 로그인 상태가 아닐 경우
                        if(useNomemberReq){
                            // 비회원 문의 체크
                            return <NomemberReqFrom />
                        }else{
                            // 비회원 문의 X
                            return <ReqLogin setUseNomemberReq={setUseNomemberReq}/>
                        }                        
                    }
                })()
            }
            </div>
        </>
    )
}

const ReqFrom = () => {
    const [reqCates, setReqCates] = useState();
    const [reqType, setReqType] = useState('');
    const [reqEmail, setReqEmail] = useState('');
    const [reqNumber, setReqNumber] = useState('');
    const [reqNumberAlarm, setReqNumberAlarm] = useState(false);
    const [reqSubject, setReqSubject] = useState('');
    const [reqContent, setReqContent] = useState('');
    const [reqAgr, setReqAgr] = useState(true);
    const [reqFiles, setReqFiles] = useState();
    const [consent, setConsent] = useState(false);
    const [modalOpen, setModalOpen] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [modalType, setModalType] = useState('');
    const [userConsented, setUserConsented] = useState('');
    const [reqSType, setReqSType] = useState('');
    const [reqSCates, setReqSCates] = useState([]);

    const [userInfo, setUserInfo] = useState({});

    const navigate = useNavigate();

    const getTerm = async (type) => {
        setModalContent();

        setModalType(type)
        const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/main/getTerm?langNo=${t('langNo')}&pageNumber=0&pageSize=9999&type=${type}`);
        setModalContent(result.data.data);
    }

    useEffect(()=>{        
        getSvcMainCategory().then(result => setReqSCates(result))
        getUserInfor().then(result => {
            setUserInfo(result);
            setReqEmail(result.email);
            setReqNumber(result.phone);
            setUserConsented(result.overseasTransferOfPInfo);
            setConsent(result.overseasTransferOfPInfo);
        })
        getReqCategory(t('langNo')).then(result => {setReqCates(result); setReqType(result[3].cateName)});
    },[])

    if(reqCates){
        return(
            <div className="support_section">
                <div className="req_tit">
                {t('support.reqFormTitleApi')}
                </div>
                <div className="">
                    <table className="table_Req">
                        <tbody>
                            <tr>
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr>
                                <th>
                                    <span style={{display:'none'}}>{t('support.req')}</span>
                                    <span className="import" aria-hidden="true">*</span>
                                    <label htmlFor="select_qtype">{t('support.reqForm01')}</label>
                                </th>
                                <td colSpan={3}>
                                    <select id="select_qtype" style={{width:"200px"}} value={reqType} disabled={true} onChange={(e)=>{setReqType(e.target.value)}}>
                                        <option value={reqCates[3].cateName}>{reqCates[3].cateName}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                            <th>
                                <label htmlFor="select_stype">{t('support.reqForm08')}</label>
                            </th>
                            <td colSpan={3}>
                                <select id="select_stype" style={{width: "200px"}} value={reqSType} onChange={(e) => {
                                    setReqSType(e.target.value)
                                }}>
                                    <option value={t('support.unselected')}>{t('support.unselected')}</option>
                                    {reqSCates && reqSCates.map((el, idx) => {
                                        return (
                                            <option key={idx} value={el.cateName}>{el.cateName}</option>
                                        )
                                    })}
                                </select>
                            </td>
                        </tr>
                            <tr>
                                <th>
                                    <span style={{display:'none'}}>{t('support.req')}</span>
                                    <span className="import" aria-hidden="true">*</span>
                                    <label htmlFor="input_email">{t('support.reqForm02')}</label>
                                </th>
                                <td>
                                    <input
                                        id="input_email"
                                        type="text"
                                        style={{width:"200px"}}
                                        placeholder=""
                                        defaultValue={reqEmail}
                                        readOnly={true}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <span style={{display:'none'}}>{t('support.req')}</span>
                                    <span className="import" aria-hidden="true">*</span>
                                    <label htmlFor="input_phone">{t('support.reqForm03')}</label>
                                </th>
                                <td>
                                    <input
                                        id="input_phone"
                                        type="text"
                                        style={{width:"200px"}}
                                        placeholder=""
                                        defaultValue={reqNumber}
                                        readOnly={true}
                                        onChange={(e)=>{
                                            setReqNumber(e.target.value);
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr className="blankLine">
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr>
                                <th>
                                    <span style={{display:'none'}}>{t('support.req')}</span>
                                    <span className="import" aria-hidden="true">*</span>
                                    <label htmlFor="input_title">{t('support.reqForm05')}</label>
                                </th>
                                <td colSpan={3}>
                                    <input
                                        id="input_title"
                                        type="text"
                                        style={{width:"100%",maxWidth:"600px"}}
                                        placeholder={t('support.reqForm05_placeholder')}
                                        value={reqSubject}
                                        onChange={(e)=>{setReqSubject(e.target.value)}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <span style={{display:'none'}}>{t('support.req')}</span>
                                    <span className="import" aria-hidden="true">*</span>
                                    <label htmlFor="input_content">{t('support.reqForm06')}</label>
                                </th>
                                <td colSpan={3}>
                                    <textarea
                                        id="input_content"
                                        type="text"
                                        style={{width:"100%",maxWidth:"600px",height:"150px", resize: "none", boxSizing : "border-box"}}
                                        placeholder={t('support.reqForm06_placeholder')}
                                        value={reqContent}
                                        onChange={(e)=>{setReqContent(e.target.value)}}
                                    ></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr className="blankLine">
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr>
                                <th>{t('support.reqForm07')}</th>
                                <td colSpan={3}>
                                    <FileForm setReqFiles={setReqFiles}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr className="blankLine">
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            {
                            !userConsented && reqSType === 'Global Messaging' ? (
                                <tr>
                                    <td colSpan={4}>
                                        <div className="script2">
                                            <input
                                                type="checkbox"
                                                id="email"
                                                name=""
                                                checked={consent}
                                                onChange={(e) => {
                                                    setConsent(e.target.checked)
                                                }}
                                            />
                                            <label htmlFor="email">{t('support.reqOAgr')}</label>
                                            <button style={{
                                                marginLeft: '10px',
                                                fontSize: '13px',
                                                color: '#43738D',
                                                textDecoration: 'underline'
                                            }} onClick={() => {
                                                getTerm('O');
                                                setModalOpen(true)
                                            }} aria-label={t('support.viewOAgr')}>{t('support.view')}
                                            </button>
                                        </div>
                                    </td>
                                </tr>) : null
                        }
                        </tbody>
                    </table>
                </div>
                <div className="nt_btn_area">
                    <button className="type_3" onClick={()=>{
                        if(!reqType){alert(t('alert.alert01')); return}
                        if(!reqEmail){alert(t('alert.alert02')); return}
                        if(!checkEmail(reqEmail)){alert(t('alert.alert03')); return}
                        if(!reqNumber){alert(t('alert.alert04')); return}
                        if(!checkMobile(reqNumber)){alert(t('alert.alert05')); return}
                        if(!reqSubject){alert(t('alert.alert07')); return}
                        if(!reqContent){alert(t('alert.alert08')); return}
                        if(reqContent.length > 5000){alert(t('alert.alert12')); return}

                        sendReq({
                            reqType : reqCates.find(el => el.cateName == reqType).cateNo,
                            reqEmail,
                            reqNumber,
                            reqNumberAlarm,
                            reqSubject,
                            reqContent
                        },reqFiles).then((res)=>{
                            if(reqSType == 'Global Messaging' && !userConsented){
                                changeUserInfo({
                                    post : userInfo.post,
                                    companyName : userInfo.companyName,
                                    companyRegNumber : userInfo.companyRegNumber,
                                    companyAddress : userInfo.post,
                                    companyAddressDetail : userInfo.companyAddress,
                                    managerName : userInfo.managerName,
                                    phone : userInfo.phone,
                                    email : userInfo.email,
                                    ceoName : userInfo.ceoName,
                                    businessType : userInfo.businessType,
                                    businessItem : userInfo.businessItem,
                                    recvNewsletter : userInfo.recvNewsletter,
                                    recvSms : userInfo.recvSms,
                                    consentUserInfoTerm : userInfo.consentUserInfoTerm,
                                    consent3rdInfo : userInfo.consent3rdInfo,
                                    overseasTransferOfPInfo : true,
                                    privacyAgr : userInfo.privacyAgr,
                                }, reqFiles).then(
                                    result => {
                                        if(result.code == 2){alert(result.data); return}
                                    }
                                )
                            }                            
                            if(res.code != 0){
                                alert(res.data);
                            }else{
                                alert(t('alert.alert10'))
                                navigate('/support/requestsSearch')
                            }
                        });
                    }}>{t('support.reqSubmit')}</button>
                    <button className="type_2"
                        onClick={()=>{window.history.back();}}
                    >{t('cancle')}</button>
                </div>
                <ModalTerm modalOpen={modalOpen} setModalOpen={setModalOpen} setModalClose={() => {setModalOpen(false)}} modalContent={modalContent} type={modalType}/>
            </div>
        )
    }else{
        return <Loading />
    }
}

const NomemberReqFrom = () => {
    const [reqCates, setReqCates] = useState();
    const [reqType, setReqType] = useState('');
    const [reqEmail, setReqEmail] = useState('');
    const [reqNumber, setReqNumber] = useState('');
    const [reqNumberAlarm, setReqNumberAlarm] = useState(false);
    const [reqPwd, setReqPwd] = useState('');
    const [reqSubject, setReqSubject] = useState('');
    const [reqContent, setReqContent] = useState('');
    const [reqAgr, setReqAgr] = useState(true);
    const [reqFiles, setReqFiles] = useState();
    const [consent, setConsent] = useState(false);
    const [modalOpen, setModalOpen] = useState('');
    const [modalContent, setModalContent] = useState('');
    const [modalType, setModalType] = useState('');
    const [reqSType, setReqSType] = useState('');
    const [reqSCates, setReqSCates] = useState([]);

    const [termText, setTermText] = useState('');

    const navigate = useNavigate();

    useEffect(()=>{
        getSvcMainCategory().then(result => setReqSCates(result));
        getReqCategory(t('langNo')).then(result => {setReqCates(result); setReqType(result[3].cateName)});
        getTermText('Q').then(res => {
            setTermText(res.data.data.termList[0].content);
        })
    },[])

    const getTerm = async (type) => {
        setModalContent();

        setModalType(type)
        const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/main/getTerm?langNo=${t('langNo')}&pageNumber=0&pageSize=9999&type=${type}`);
        setModalContent(result.data.data);
    }

    const getTermText = async (type) => {
        const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/main/getTerm?langNo=${t('langNo')}&pageNumber=0&pageSize=9999&type=${type}`);
        return result;
    }

    if(reqCates){
        return(
            <div className="support_section">
                <div className="req_tit">
                {t('support.reqFormTitle')}
                </div>
                <div className="">
                    <table className="table_Req">
                        <tbody>
                            <tr>
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr>
                                <th>
                                    <span style={{display: 'none'}}>{t('required')}</span>
                                    <span className="import" aria-hidden="true">*</span>
                                    <label htmlFor="select_qtype"> {t('support.reqForm01')}</label>
                                </th>
                                <td colSpan={3}>
                                    <select id="select_qtype" style={{width:"200px"}} value={reqType} onChange={(e)=>{setReqType(e.target.value)}}>
                                        <option value={reqCates[3].cateName}>{reqCates[3].cateName}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                            <th>
                                <label htmlFor="select_stype"> {t('support.reqForm08')}</label>
                            </th>
                            <td colSpan={3}>
                                <select id="select_stype" style={{width: "200px"}} value={reqSType} onChange={(e) => {
                                    setReqSType(e.target.value)
                                }}>
                                    <option>{t('support.unselected')}</option>
                                    {reqSCates && reqSCates.map((el, idx) => {
                                        return (
                                            <option key={idx} value={t('langNo') === '1'?el.cateName:el.cateNameEn}>{t('langNo') === '1'?el.cateName:el.cateNameEn}</option>
                                        )
                                    })}
                                </select>
                            </td>
                        </tr>
                            <tr>
                                <th>
                                    <span style={{display: 'none'}}>{t('required')}</span>
                                    <span className="import" aria-hidden="true">*</span>
                                    <label htmlFor="input_email"> {t('support.reqForm02')}</label>
                                </th>
                                <td>
                                    <input
                                        id="input_email"
                                        type="text"
                                        style={{width:"200px"}}
                                        placeholder=""
                                        value={reqEmail}
                                        onChange={(e)=>{setReqEmail(e.target.value)}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <span style={{display: 'none'}}>{t('required')}</span>
                                    <span className="import" aria-hidden="true">*</span>
                                    <label htmlFor="input_phone"> {t('support.reqForm03')}</label>
                                </th>
                                <td>
                                    <input
                                        id="input_phone"
                                        type="text"
                                        style={{width:"200px"}}
                                        placeholder=""
                                        value={reqNumber}
                                        onChange={(e)=>{
                                            setReqNumber(e.target.value);
                                        }}
                                    />                                    
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <span style={{display: 'none'}}>{t('required')}</span>
                                    <span className="import" aria-hidden="true">*</span>
                                    <label htmlFor="input_title"> {t('support.reqForm04')}</label>
                                </th>
                                <td>
                                    <input
                                        id="input_title"
                                        type="password"
                                        style={{width:"200px"}}
                                        value={reqPwd}
                                        onChange={(e)=>{setReqPwd(e.target.value)}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr className="blankLine">
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr>
                                <th>
                                    <span style={{display: 'none'}}>{t('required')}</span>
                                    <span className="import" aria-hidden="true">*</span>
                                    <label htmlFor="input_content"> {t('support.reqForm05')}</label>
                                </th>
                                <td colSpan={3}>
                                    <input
                                        id="input_content"
                                        type="text"
                                        style={{width:"100%",maxWidth:"600px"}}
                                        placeholder={t('support.reqForm05_placeholder')}
                                        value={reqSubject}
                                        onChange={(e)=>{setReqSubject(e.target.value)}}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th><span className="import">*</span> {t('support.reqForm06')}</th>
                                <td colSpan={3}>
                                    <textarea
                                        type="text"
                                        style={{width:"100%",maxWidth:"600px",height:"150px", resize: "none"}}
                                        placeholder={t('support.reqForm06_placeholder')}
                                        value={reqContent}
                                        onChange={(e)=>{setReqContent(e.target.value)}}
                                    ></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr className="blankLine">
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr>
                                <th>{t('support.reqForm07')}</th>
                                <td colSpan={3}>
                                    <FileForm setReqFiles={setReqFiles}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr className="blankLine">
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                    <div className="script">
                                        <div dangerouslySetInnerHTML={{__html : termText.replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}}></div>
                                    </div>
                                    <div className="script2">
                                        <input
                                            type="checkbox"
                                            id="email"
                                            name=""
                                            checked={reqAgr}
                                            onChange={(e)=>{setReqAgr(e.target.checked)}}
                                        />
                                        <label htmlFor="email">{t('support.reqAgr')}</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            <tr className="blankLine">
                                <td className="blanktd" colSpan={4}></td>
                            </tr>
                            {
                            reqSType === 'Global Messaging'? (
                            <>
                                <tr>
                                    <td colSpan={4}>
                                        <div className="script2">
                                            <input
                                                type="checkbox"
                                                id="email"
                                                name=""
                                                checked={consent}
                                                onChange={(e) => {
                                                    setConsent(e.target.checked)
                                                }}
                                            />
                                            <label htmlFor="email">{t('support.reqOAgr')}</label>
                                            <button style={{
                                                marginLeft: '10px',
                                                fontSize: '13px',
                                                color: '#43738D',
                                                textDecoration: 'underline'
                                            }} onClick={() => {
                                                getTerm('O');
                                                setModalOpen(true)
                                            }} aria-label={t('support.viewOAgr')}>{t('support.view')}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="blanktd" colSpan={4}></td>
                                </tr>
                                <tr className="blankLine">
                                    <td className="blanktd" colSpan={4}></td>
                                </tr>
                            </>
                            ) : ''}
                        </tbody>
                    </table>
                </div>
                <div className="nt_btn_area">
                <button className={((((reqSType == 'Global Messaging') && consent) || reqSType !== 'Global Messaging') && reqAgr) ? "type_3" : "type_3_disabled"}
                            disabled={!((((reqSType == 'Global Messaging') && consent) || reqSType !== 'Global Messaging') && reqAgr)} onClick={() => {
                        if(!reqType){alert(t('alert.alert01')); return}
                        if(!reqEmail){alert(t('alert.alert02')); return}
                        if(!checkEmail(reqEmail)){alert(t('alert.alert03')); return}
                        if(!reqNumber){alert(t('alert.alert04')); return}
                        if(!checkMobile(reqNumber)){alert(t('alert.alert05')); return}
                        if(!reqPwd){alert(t('alert.alert06')); return}
                        if(!validatePostPassword(reqPwd)){alert(t('alert.alert13')); return}
                        if(!reqSubject){alert(t('alert.alert07')); return}
                        if(!reqContent){alert(t('alert.alert08')); return}
                        if(!reqAgr){alert(t('alert.alert09')); return}

                        sendReq({
                            reqType : reqCates.find(el => el.cateName == reqType).cateNo,
                            reqSType,
                            reqEmail,
                            reqNumber,
                            reqNumberAlarm,
                            reqPwd,
                            reqSubject,
                            reqContent,
                            reqAgr,
                        },reqFiles).then((res)=>{
                            if(res.code != 0){
                                alert(res.data);
                            }else{
                                alert(t('alert.alert10'))
                                navigate('/support/requestsSearch')
                            }
                        });                        
                    }}>{t('support.reqSubmit')}</button>
                    <button className="type_2"
                        onClick={()=>{window.history.back();}}
                    >{t('cancle')}</button>
                </div>
                <ModalTerm modalOpen={modalOpen} setModalOpen={setModalOpen} setModalClose={() => {setModalOpen(false)}} modalContent={modalContent} type={modalType}/>
            </div>
        )
    }else{
        return <Loading />
    }
}

const FileForm = (props) => {
    const {setReqFiles} = props;
    const [files, setFiles] = useState();
    const [viewFiles, setViewFiles] = useState();

    useEffect(()=>{
        setReqFiles(files)
    },[files])

    return(
        <>
            <input
                type="file"
                style={{display:'none'}}
                id="addFile"
                name="addFile"
                multiple="multiple"
                onClick={(e)=>{ e.target.value = '' }}
                onChange={(e)=>{
                    let fileData = new FormData();

                    for(const element of e.target.files){
                        if(element.size > 1024*1024*10){
                            alert(t('alert.alert14'));
                            return;
                        }
                        fileData.append('attachment', element , element.name);
                    }
                    setFiles(fileData)
                    setViewFiles(fileData.getAll('attachment'))
                }}
            />
            <button className="btn_sm4" onClick={()=>{document.querySelector('#addFile').click();}}>{t('support.reqForm07_btn')}</button>
            <div className="file_list">
                {
                    viewFiles && viewFiles.length !== 0 &&
                       viewFiles.map((file,idx)=>{
                            return <FileItem key={idx} idx={idx} files={file} setFiles={setViewFiles}>{file.name}</FileItem>
                        })
                }
            </div>
        </>
    )
}

const FileItem = (props) => {
    const {children, files, setFiles, idx} = props;
    return(
        <div className={`file ${idx}`}>
            {children}
            <button>
                <img
                    style={{verticalAlign: 'baseline'}}
                    src={`/images/icon_f_close.png`}
                    alt={t('alt.alt1')}
                    onClick={()=>{
                        if(files.length == 1){setFiles(); return}
                        setFiles([...files.slice(0,idx), ...files.slice(idx+1, files.length)])
                    }}
                />
            </button>
        </div>
    )
}

const ReqLogin = (props) => {
    const {setUseNomemberReq} = props;
    const navigate = useNavigate();

    return(
        // 회원로그인 : langNo = '1'(KOR)일 때만 보여짐
        <div className="support_section">
            <div className="req_login">
                <div className="l_tit">
                    로그인 후 문의하시면<br/> 문의 내역을 더 정확한 상담을 받을 수 있습니다.
                </div>
                <div className="l_btn">
                    <button className="basic banC" onClick={()=>{navigate('/login')}}>로그인</button>
                </div>
                <div className="l_script">
                    비회원 문의는 <button onClick={()=>{setUseNomemberReq(true)}}>[비회원 문의]</button>를 선택하세요.
                </div>
            </div>
        </div>
    )
}

export default ApiAll