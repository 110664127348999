import PageInfo from '../page_info.json';

const setMetaTags = ({ title, description, keywords }) => {
    //set title    
    if(!title || title === ''){title = PageInfo.default.title}
    if(!description){description = PageInfo.default.description}
    if(!keywords){keywords = PageInfo.default.keywords}

    document.querySelector('title').innerText = `${title} - API PLEX`;
    document.querySelector('meta[name="description"]').setAttribute("content", description);
    document.querySelector('meta[name="keywords"]').setAttribute("content", keywords);
};

export default setMetaTags;