import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ReactGA4 from "react-ga4";
import TagManager from 'react-gtm-module';
import * as Sentry from "@sentry/react";

const TRACKING_ID =  process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
const GTM_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

ReactGA4.initialize(TRACKING_ID);
TagManager.initialize({gtmId: GTM_ID});

// Sentry.init({
//   dsn: "https://a0d9685a1d3fcfe52ef60e9fd80bf0f3@us.sentry.io/4506754427977728",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", "/^\/api/"],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

ReactDOM.render(
  <App />,
  document.getElementById('root')
);