import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getApiItems } from '../../../Util/reqApi';

const SideApiBlock = ({ apiCategory, curSvcNo, openedCategory, setOpenedCategory }) => {
    const [ apiItems, setApiItems] = useState([]);

    useEffect(()=>{
        getApiItems(apiCategory.cateNo).then(result => {
            setApiItems(result);
        });

        return () => setApiItems([null]);
    },[])

    if(apiItems){
        return(
            <>
                <div
                    className={openedCategory ==  apiCategory.cateNo ? 'm_sub_title' : 'm_sub_title mnOn'}
                    onClick={()=>{setOpenedCategory(apiCategory.cateNo)}}
                >
                    <img src={`/uploads/category/${apiCategory.iconFileName}`} alt=""/>
                    <span>{apiCategory[t('apiCategory.cateName')]}</span>
                </div>
                <ul style={openedCategory ==  apiCategory.cateNo ? {display:'block'} : {display:'none'}}>
                    {
                        apiItems.map((apiItem,idx) => {
                            return(
                                <li className={curSvcNo == apiItem.svcNo ? 'Lm_name on' : 'Lm_name'} key={idx}>
                                    <Link to={`/service/apiservices/${apiItem.svcNo}`}><img src={`/uploads/svcDetail/${apiItem.iconFileName}`} alt=""/><span dangerouslySetInnerHTML={{__html:apiItem[t('apiDetail.name')]}}></span></Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </>
        )
    }else return null
};

export default SideApiBlock;