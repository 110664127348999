import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";
import Loading from "../../Util/Loading";
import { getEventDetail } from "../../Util/reqApi";
import setMetaTags from "../../Util/setMetaTags";

const Event = () => {
    const { id } = useParams();
    const [eventDetail, setEventDetail] = useState();

    useEffect(()=>{
        getEventDetail(id).then(result => setEventDetail(result))
    },[])

    useEffect(() => {
        setMetaTags({
            title : eventDetail.subject,
            description : "",
            keywords : ""
        })
    }, [eventDetail])


    if(eventDetail == 'no-data'){
        alert('잘못된 접근입니다.')
        window.location.href = "/";
    }
    if(eventDetail){
        return(
            <div 
                dangerouslySetInnerHTML={{__html : eventDetail.content.replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}}
            />
        )
    }else return <Loading />
}

export default Event;