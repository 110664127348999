import React, { useState, useEffect } from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import { Link } from 'react-router-dom';
import Loading from '../../Util/Loading';
import { getSubAccount } from '../../Util/reqApi';
import { useGlobal} from "../../shared/globalContext";

const Account = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const global = useGlobal();
    const [subAccounts, setSubAccounts] = useState();

    const {login} = global;

    useEffect(()=>{
        if(!login) {
            navigate('/login', { state: { from: pathname } });
            return;
        }else{
            getSubAccount().then(result => setSubAccounts(result));
            return ()=>{setSubAccounts()}
        }
    },[])

    if(subAccounts){
        return(
            <>
                <div style={{display:"none"}}>{/*로그인전*/}
                    <div className="script_b">회원님의 정보 보호를 위하여 다시 한 번 로그인해 주시기 바랍니다</div>
                    <div className="sub_login">
                        <table>
                            <colgroup>
                                <col width="*" />
                                <col width="*" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th>아이디</th>
                                    <td>abcde@gmail.com</td>
                                </tr>
                                <tr>
                                    <th>현재 비밀번호</th>
                                    <td><input type="text" style={{width:"96%"}} placeholder="비밀번호를 입력해 주세요." /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="ip_btn_area">
                        <button style={{width:"400px",maxWidth:"100%"}} className="login_1">로그인</button>
                    </div>
                </div>
                <div>{/*로그인이후*/}
                    <div className="tab subtab">
                        <ul>
                            <Link to="/mypage/account/changeProfile">
                                <li className={`${pathname === '/mypage/account/changeProfile' ? 'tab-link current' : 'tab-link'}`}>
                                    회원정보변경
                                </li>
                            </Link>
                            <Link to="/mypage/account/changePassword">
                                <li className={`${pathname === '/mypage/account/changePassword' ? 'tab-link current' : 'tab-link'}`}>
                                    비밀번호변경
                                </li>
                            </Link>
                            <Link to="/mypage/account/security">
                                <li className={`${pathname === '/mypage/account/security' ? 'tab-link current' : 'tab-link'}`}>
                                    2차 인증 담당자 관리
                                </li>
                            </Link>
                            <Link to="/mypage/account/keyManagement">
                                <li className={`${pathname === '/mypage/account/keyManagement' ? 'tab-link current' : 'tab-link'}`}>
                                    Sub Account 관리
                                </li>
                            </Link>
                            <Link to="/mypage/account/secession">
                                <li className={`${pathname === '/mypage/account/secession' ? 'tab-link current' : 'tab-link'}`}>
                                    회원탈퇴
                                </li>
                            </Link>
                        </ul>
                    </div>                
                    <Outlet />
                </div>
            </>
        )
    }else{
        return <Loading />
    }
}

export default Account;