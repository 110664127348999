import React, {useEffect, useState} from "react";
import SearchSuggestionBlock from "./SearchSuggestionBlock";
import {
  getApiCategorys,
  getApiDetail, getApiItems, getSearchResult,
  getSiteCategoryDetail
} from "../../Util/reqApi";

const SearchSuggestion = ({searchStr}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if(searchStr){
      getSearchResult(searchStr).then((res) => {
        if(typeof res == "string"){
            setData(null);
        }else{
            setData(res);
        }
      });
    }
  }, [searchStr]);

  return(
      <div id='searchSuggestion' className='searchSuggestion' hidden={!data || !searchStr}>
        {
          data?.slice(0, 3).map( (el, idx)=> {
            return(<SearchSuggestionBlock key={idx} el={el} mo={false}/>);
          })
        }
      </div>
  );
}

export default SearchSuggestion;