import React,{ useEffect,useState } from "react";
import Loading from "../../../Util/Loading";
import { t } from "i18next";
import { Link } from "react-router-dom";

const ApiListItems = ({ data }) => {
    const [svcDetailList, setSvcDetailList] = useState([]);

    const parseTag = (tags) => {
        if(!tags) return '';

        const res = tags.split(',').map( (tag) => {
            return '#' + tag.trim();
        });

        return res.join(' ');
    }

    useEffect(()=>{
        setSvcDetailList(data);
        
        return () => setSvcDetailList();
    },[data])

    if(svcDetailList){
        return(
            <ul>
                {
                    svcDetailList.map((el, idx)=>{
                        return(
                            <li key={idx}>
                                <Link to={`/service/apiservices/${el.masterSvcNo}`}>
                                    <div className="api_product">
                                        <div className="p_name">
                                            <img src={`/uploads/svcDetail/${el.iconFileName}`} alt=""/>
                                            <span dangerouslySetInnerHTML={{__html: el[t('apiDetail.name')]}}></span>
                                        </div>
                                        <div className="p_info" dangerouslySetInnerHTML={{ __html: el[t('apiDetail.introduction')]}}></div>
                                        <div className="p_tags">{localStorage.getItem('lan') == 'ko'? (parseTag(el.tag)) : (parseTag(el.tagEn))}</div>
                                    </div>
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
        )
    }else{
        return <Loading height={130} />
    }
}

export default ApiListItems;