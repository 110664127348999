import React, {useState, useEffect} from 'react'
import { checkSubAccountName, checkSubAccountRule, getSubAccount, regiSubAccount } from '../../../../Util/reqApi';

const CreateSubAccount = (props) => {
    const {svcNo, subAccountModal, setSubAccountModal, svcName, setSubAccountData} = props;

    const [subAccountNameCheck, setSubAccountNameCheck] = useState();
    const [subAccountName, setSubAccountName] = useState('');
    const [subAccountDesc, setSubAccountDesc] = useState('');

    const duplicateCheck = (subAccountName) => {
        checkSubAccountName(subAccountName).then(result => {
            if(result){
                alert('사용가능한 이름입니다.')                
            }else{
                alert('사용 불가능한 이름입니다.')
            }
            setSubAccountNameCheck(result)
        })
    }

    useEffect(()=>{
        setSubAccountNameCheck(false);
    },[subAccountName])

    return(
        <div id="" className="pop_set_p" style={subAccountModal ? {display:"block"} : {display:"none"}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page">
                        <div className="ppheader">
                            <div className="htit">Sub Account 추가하기</div>
                            <div
                                className="rclose"
                                onClick={()=>{setSubAccountModal(false)}}
                            ><img src='/images/icon_pop_close.png' alt="닫기"/></div>
                        </div>
                        <div className="p_table_section">
                            <table className="table_mypageBp">
                                <colgroup>
                                    <col width="80px" />
                                    <col width="*%" />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td>서비스 ·</td>
                                        <td>{svcName}</td>
                                    </tr>
                                    <tr>
                                        <td>이름 ·</td>
                                        <td>
                                            <input
                                                type="text"
                                                style={{width:"calc(90% - 110px)"}}
                                                placeholder="알파벳, 숫자, 특수기호 “_”만 사용가능 최대 20자 중복 불가"
                                                onChange={(e)=>{
                                                    setSubAccountName(e.target.value)
                                                    setSubAccountNameCheck(false)
                                                }}
                                                value={subAccountName}
                                            />
                                            <button
                                                className="ip_color1"
                                                style={{width:"100px"}}
                                                onClick={()=>{
                                                    if(checkSubAccountRule(subAccountName)){
                                                        duplicateCheck(subAccountName)
                                                    }else{
                                                        alert('알파벳, 숫자, 특수기호 “_”만 사용가능 최대 20자 중복 불가')
                                                    }                                                    
                                                }}
                                            >
                                                중복확인
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>설명 ·</td>
                                        <td>
                                            <textarea
                                                type="text"
                                                style={{width:"100%",height:"120px"}}
                                                placeholder="최대 120자 이상 입력할 수 없습니다."
                                                onChange={(e)=>{setSubAccountDesc(e.target.value)}}
                                                value={subAccountDesc}
                                                maxLength={120}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="s_u_padding20"></div>
                        </div>
                        <div className="nt_btn_area_p">
                            <button
                                className="btn_sm4"
                                onClick={()=>{
                                    setSubAccountName('')
                                    setSubAccountDesc('')
                                    setSubAccountModal(false)
                                    setSubAccountNameCheck(false)
                                }}
                            >닫기</button>
                            <button
                                className="btn_sm6"
                                onClick={()=>{
                                    if(subAccountDesc.length > 120) {alert("120자 이상 입력할 수 없습니다.")}

                                    if(subAccountNameCheck){  
                                        regiSubAccount(svcNo, subAccountName, subAccountDesc)
                                        .then(result => {
                                            setSubAccountName('')
                                            setSubAccountDesc('')
                                            getSubAccount(svcNo).then(result => setSubAccountData(result))
                                            setSubAccountModal(false)
                                            setSubAccountNameCheck(false)
                                        })
                                    }else{
                                        alert('이름 중복 확인을 해주세요.')
                                    }
                                }}
                            >
                                생성하기
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateSubAccount;