import React, {useEffect, useState} from 'react';
import Loading from '../../Util/Loading';
import { getSecessionType, withdrawal } from '../../Util/reqApi';

const Secession = () => {
    const [secessionType, setSecessionType] = useState();
    const [selectedSecction,setSelectedSecction] = useState();
    const [simpleText, setSimpleText] = useState('');
    const [simpleTextModal, setSimpleTextModal] = useState('');
    const [callbackFunc, setCallbackFunc] = useState();

    const simpleModalClose = () => {
        setCallbackFunc()
        setSimpleText();
        setSimpleTextModal(false);
    }


    useEffect(()=>{
        getSecessionType().then(result => {
            setSecessionType(result)
            setSelectedSecction(result[0].typeName)
        })
    },[])

    if(secessionType){
        return(
            <div className="acc_section">
                <div className="km_tit">
                회원 탈퇴를 신청하기 전에 아래 내용을 꼭 확인해 주세요.
                </div>
                <div className="km_info_box">
                    <div className="in_tit">회원께서는 아래 사항이 모두 확인되어야 회원 탈퇴 신청 및 탈퇴가 완료됩니다</div>
                    <div className="in_tt">
                    - 이용중인 서비스는 취소됩니다. <br/>
                    - 이용하신 서비스 요금이 모두 납부되어야 회원탈퇴가 완료됩니다.<br/>
                    - 탈퇴 완료 시, 서비스 이용이 즉시 중지되며 서비스페이지에 로그인 할 수 없습니다.<br/>
                    - 회원 탈퇴 시, 개인정보는 즉시 파기되며 다음의 정보는 관계 법령에 의거 일정기간 보유 후 즉시 파기합니다.<br/>
                        <div className="inbox">
                        - 아이디, 휴대폰번호 : 부정이용 방지를 위해 탈퇴 후 6개월 보관, 결제 회원의 경우 5년간 보관. (전자상거래 등에서의 소비자보호에 관한 법률)<br/>
                        - 이용자 IP주소 : 3개월 보관. (통신비밀보호법)<br/>
                        </div>
                    </div>
                </div>
                <div className="table_area" style={{marginTop:"30px",marginBottom:"30px"}}>
                    <table className="table_mypage">
                        <colgroup>
                            <col width={"*%"} />
                            <col width={"*%"} />
                            <col width={"*%"} />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>정보</th>
                                <th>보존 이유</th>
                                <th>보존 기간</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>계약 또는 청약철회 등에 관한 기록</td>
                                <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                                <td>5년</td>
                            </tr>
                            <tr>
                                <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                                <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                                <td>5년</td>
                            </tr>
                            <tr>
                                <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                                <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                                <td>3년</td>
                            </tr>
                            <tr>
                                <td>표시/광고에 관한 기록</td>
                                <td>전자상거래 등에서의 소비자 보호에 관한 법률</td>
                                <td>6개월</td>
                            </tr>
                            <tr>
                                <td>세법이 규정하는 모든 거래에 관한 장부 및 증빙서류</td>
                                <td>국세기본법, 법인세법</td>
                                <td>5년</td>
                            </tr>
                            <tr>
                                <td>웹사이트 방문에 관한 기록</td>
                                <td>통신비밀보호법</td>
                                <td>3개월</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="km_info_box">
                    <div className="in_tit">탈퇴 후 재가입</div>
                    <div className="in_tt">
                    - 탈퇴한 아이디로 재가입은 가능하나, 기존 정보는 삭제되어 복구할 수 없으니 유의하시기 바랍니다.
                    </div>
                </div>
                <div className="ses_selt_area">
                    <span>탈퇴 이유 선택</span>
                    <select style={{width:"100%",maxWidth:"250px"}} onChange={(e)=>{setSelectedSecction(e.target.value)}}>
                        {
                            (()=>{
                                if(secessionType.length == 0){return <option>탈퇴 사유를 선택해주세요.</option>}
                                return secessionType.map((el,idx)=>{
                                    return <option key={idx} value={el.typeName} >{el.typeName}</option>
                                })
                            })()
                        }
                    </select>
                </div>
                <div className="ip_btn_area2">
                    <div className="info_tt">회원 탈퇴 신청을 하시려면 [탈퇴 신청]버튼을 클릭해주세요.</div>
                    <button className="login_1" style={{width:"200px"}}
                        onClick={()=>{
                            setSimpleText('정말 탈퇴하시겠습니까?')
                            setSimpleTextModal(true)
                            setCallbackFunc(
                                (pwd)=>
                                (pwd)=>{
                                    withdrawal(selectedSecction, secessionType.filter(el=>el.typeName == selectedSecction)[0].typeNo, pwd).then(result => {
                                        if(result == 'ok'){
                                            alert('탈퇴신청이 완료되었습니다.')
                                            localStorage.removeItem('ACCESS_TOKEN');
                                            localStorage.removeItem('REFRESH_TOKEN');
                                            window.location.href = '/'
                                        }
                                        else{alert(result)}
                                    })
                                }
                            )
                        }}
                    >탈퇴 신청</button>
                </div>
                {simpleTextModal ? <SimpleModal simpleTextModal={simpleTextModal} setSimpleTextModal={setSimpleTextModal} callbackFunc={callbackFunc}>{simpleText}</SimpleModal> : null}
            </div>
        )
    }else{
        return <Loading />
    }
}

export default Secession;

const SimpleModal = (props) => {
    const {children, simpleTextModal, setSimpleTextModal, callbackFunc} = props;
    const [pwd, setPwd] = useState();
    return(
        <div className="pop_set_c" style={simpleTextModal ? {display:'block'} : {display:'none'}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page">
                        <div className="ppheader">
                            <div className="htit">알림</div>
                            <div className="rclose" onClick={()=>{setSimpleTextModal(false)}}><img src={'/images/icon_pop_close.png'} alt="닫기"/></div>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                            <div className="s_u_p_tit">{children}</div>
                            <input type="password" style={{border: '1px solid #dddddd', background: '#F7F7F7', fontSize: '14px', width:'80%'}} placeholder="비밀번호를 입력해주세요." value={pwd} onChange={(e)=> setPwd(e.target.value)}/>
                            <div className="s_u_p_btn">
                                <button
                                    className="login_2"
                                    onClick={()=>{
                                        if(pwd){
                                            callbackFunc(pwd);
                                            setSimpleTextModal(false);
                                        }else{
                                            alert('비밀번호를 입력해주세요.')
                                        }
                                    }}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}