import React from 'react';

const SvcPermit = (props) => {
    const {svcPermitModal, setSvcPermitModal} = props;
    return(
        <div id="" className="pop_set_c" style={svcPermitModal ? {display:'block'} : {display:'none'}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page">
                        <div className="ppheader">
                            <div className="htit">알림</div>
                            <div className="rclose" onClick={()=>{setSvcPermitModal(false)}}><img src='/images/icon_pop_close.png' alt="닫기"/></div>
                        </div>
                        <div>
                            <div className="s_u_p_tit">서비스 신청이 완료 되었습니다.</div>
                            <div className="s_u_padding20"></div>
                        </div>
                        <div className="nt_btn_area_p">
                            <button className="btn_sm4" onClick={()=>{setSvcPermitModal(false)}}>닫기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SvcPermit;