import { t } from 'i18next';
import React, {useState, useEffect} from 'react';
import Loading from '../../../Util/Loading';
import axios from 'axios';
import ModalTerm from '../../../Layouts/Modal/modalTerm';
import {saveDownloadInfo} from '../../../Util/reqApi';

const DownloadModal = (props) => {
    const {setModalClose, isOpen} = props;
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [team, setTeam] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [svcTerm, setSveTerm] = useState('');

    const [termOpen, setTermOpen] = useState(false);

    const [termEmail, setTermEmail] = useState(false);
    const [termMsg, setTermMsg] = useState(false);

    const [termConsent, setTermConsent] = useState(false);

    const getTerm = async (type) => {
        const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/main/getTerm?langNo=${t('langNo')}&pageNumber=0&pageSize=9999&type=${type}`);
        return result.data.data;
    }

    useEffect(() => {
        getTerm("F").then( data => {
            setSveTerm(data);
        })
    }, [])

    const handleConfirm = () => {
        if(!termConsent) {
            alert(t("download.alert.consent"));
            return;
        }
        else if(!name){
            alert(t("download.alert.name"));
            return;
        }
        else if(!company){
            alert(t("download.alert.company"));
            return;
        }
        else if(!team){
            alert(t("download.alert.team"));
            return;
        }
        else if(!phone){
            alert(t("download.alert.phone"));
            return;
        }
        else if(!email){
            alert(t("download.alert.email"));
            return;
        }
        else if(!comment){
            alert(t("download.alert.comment"));
            return;
        }

        saveDownloadInfo(
            {
                name: name,
                company: company,
                team: team,
                phone: phone,
                email: email,
                comment: comment,
                termEmail: termEmail,
                termMsg : termMsg
            }
        ).then(res => {
            if(res.code == 0){
                var a = document.createElement('a');
                a.href = t('langNo') == 1? '/media/APIPLEX_서비스소개서.pdf' : '/media/APIPLEX_intro(EN).pdf'
                a.download = t("download.filename");
                a.id = 'a_down';
                a.style.display = 'none';
                document.getElementById("modal_div").appendChild(a);
                
                a.click();
                document.getElementById("modal_div").removeChild(a);

                setModalClose();
            }else{
                alert(t("download.alert.error"));
                setModalClose();
            }
        }).catch((e) => {
            console.log(e);
            alert(t("download.alert.error"));
            setModalClose();
        });
    }

    if(isOpen && svcTerm){
        return(
            <div id="modal_div" className="pop_set_b" style={isOpen ? {display:'block'} : {display:'none'}}>
                <div className="pop_section">
                    <div className="parea">
                        <div className="page">
                            <div className="ppheader">
                                <div className="htit">{t("download.title")}</div>
                                <div className="rclose" onClick={()=>{setModalClose()}}><img src='/images/icon_pop_close.png' alt={t("download.closeAlt")}/></div>
                            </div>
                            <div>
                                <div className="s_u_p_tit1">
                                    <span>{t("download.term01Title")}</span><button onClick={() => setTermOpen(true)}>{t("download.view")}</button>
                                    <br/>
                                    <input style={{marginTop:'5px'}} type="radio" id="term_o" name="term_user" value={true} onChange={(e) => {setTermConsent(true);}}/><label htmlFor="term_o"><span></span>{t("download.radio.ok")}</label>
                                    <input type="radio" id="term_x" name="term_user" value={false} onChange={(e) => {setTermConsent(false);}}/><label htmlFor="term_x"><span></span>{t("download.radio.notok")}</label>
                                    <br/>
                                    <br/>
                                    <span>{t("download.term02Title")}</span><button onClick={() => setTermOpen(true)}>{t("download.view")}</button>
                                    <br/>
                                    <div style={{marginTop:'7px'}}>
                                        <label><input type="checkbox" id="term_o" name="term_all" value='all' checked={termEmail && termMsg} onChange={(e) => {setTermEmail(e.target.checked);setTermMsg(e.target.checked);}}></input>{t("download.checkbox.all")}</label>
                                        <label><input type="checkbox" id="term_email" name="term_x" value='email' checked={termEmail} onChange={(e) => {setTermEmail(e.target.checked);}}></input>{t("download.checkbox.email")}</label>
                                        <label><input type="checkbox" id="term_x" name="term_msg" value='msg' checked={termMsg} onChange={(e) => {setTermMsg(e.target.checked);}}></input>{t("download.checkbox.msg")}</label>
                                    </div>
                                </div>
                                <br/>
                                <div className="s_u_p_tit1">
                                    <label htmlFor="modal_d_name" style={{marginRight:"20px", display: "inline-block",minWidth:"180px"}}>{t("download.label.name")}<span style={{color:'red'}}> *</span></label>
                                    <input type="text" id='modal_d_name' value={name} style={{width:'200px'}} onChange={(e) => setName(e.target.value)} placeholder={t("download.placeholder.name")}/>
                                </div>
                                <div className="s_u_p_tit1">
                                    <label htmlFor="modal_d_company" style={{marginRight:"20px", display: "inline-block",minWidth:"180px"}}>{t("download.label.company")}<span style={{color:'red'}}> *</span></label>
                                    <input type="text" id='modal_d_company' value={company} style={{width:'200px'}} onChange={(e) => setCompany(e.target.value)} placeholder={t("download.placeholder.company")}/>
                                </div>
                                <div className="s_u_p_tit1">
                                    <label htmlFor="modal_d_team" style={{marginRight:"20px", display: "inline-block",minWidth:"180px"}}>{t("download.label.team")}<span style={{color:'red'}}> *</span></label>
                                    <input type="text" id='modal_d_team' value={team} style={{width:'200px'}} onChange={(e) => setTeam(e.target.value)} placeholder={t("download.placeholder.team")}/>
                                </div>
                                <div className="s_u_p_tit1">
                                    <label htmlFor="modal_d_phone" style={{marginRight:"20px", display: "inline-block",minWidth:"180px"}}>{t("download.label.phone")}<span style={{color:'red'}}> *</span></label>
                                    <input type="text" id='modal_d_phone' value={phone} style={{width:'200px'}} onChange={(e) => setPhone(e.target.value)} placeholder={t("download.placeholder.phone")}/>
                                </div>
                                <div className="s_u_p_tit1">
                                    <label htmlFor="modal_d_email" style={{marginRight:"20px", display: "inline-block",minWidth:"180px"}}>{t("download.label.email")}<span style={{color:'red'}}> *</span></label>
                                    <input type="text" id='modal_d_email' value={email} style={{width:'200px'}} onChange={(e) => setEmail(e.target.value)} placeholder={t("download.placeholder.email")}/>
                                </div>
                                <div className="s_u_p_tit1">
                                    <label htmlFor="modal_d_comment" style={{display: "flex", alignItems:'start'}}><span id="modal_d_comment_text" style={{maxWidth:'180px', marginRight:'20px'}}>{t("download.label.comment")}<span style={{color:'red'}}> *</span></span></label>
                                    <br />
                                    <textarea type="text" id='modal_d_comment' value={comment} onChange={(e) => setComment(e.target.value)} style={{width: '100%', height: '150px'}} placeholder={t("download.placeholder.comment")} />
                                </div>
                            </div>
                            <div className="nt_btn_area_p">
                                <button className="btn_sm4" onClick={() => {setModalClose()}}>{t("download.closeAlt")}</button>
                                <button className="btn_sm6" onClick={() => {handleConfirm()}}>{t("download.download")}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalTerm modalOpen={termOpen} modalContent={svcTerm || ''} type='F' setModalClose={() => {setTermOpen(false)}}></ModalTerm>
            </div>
        )
    }else{
        return (
            <div id="" className="pop_set_b" style={isOpen ? {display:'block'} : {display:'none'}}>
                <div className="pop_section">
                    <div className="parea">
                        <div className="page">
                            <Loading />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DownloadModal;