import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { IMG_URL } from "../../config";
import Loading from "../../Util/Loading";
import { cancleReq, chkMySvc, getApiCategorys, getApiDetail, getApiItems, getMySvc, getSalePrice, getUserInfor } from "../../Util/reqApi";
import SvcAlert from "./Component/Modal/SvcAlert";
import SvcApply from "./Component/Modal/SvcApply";
import SvcPermit from "./Component/Modal/SvcPermit";
import SvcNeedApproval from "./Component/Modal/SvcNeedApproval";
import ServiceConsole from "./ServiceConsole";
import setMetaTags from "../../Util/setMetaTags";
import { isMobile } from "react-device-detect";
import { useGlobal } from "../../shared/globalContext";
import SideApiBlock from "./Component/SideApiBlock";
import DevGuidePanel from "./Component/Modal/DevGuidePanel";
import ManualPanel from "./Component/Modal/ManualPanel";
import PricePanel from "./Component/Modal/PricePanel";

const ApiServices = () => {
    const global = useGlobal();
    const {login, setLogin} = global;

    const { api } = useParams();
    const [ apiData, setApiData] = useState();
    const [ apiCategoryData, setApiCategoryData] = useState([]);
    const [ selectedTab, setSelectedTab] = useState('infor');
    const [ svcStatus, setSvcStatus] = useState(1);
    const [ mySvc, setMySvc] = useState([]);
    const [ dropDown, setDropDown ] = useState(true);
    const [ svcAlertModal, setSvcAlertModal] = useState(false);
    const [ svcApplyModal, setSvcApplyModal] = useState(false);
    const [ svcPermitModal, setSvcPermitModal] = useState(false);
    const [ svcNeedApproval, setSvcNeedApproval] = useState(false);
    const [ discountPrice, setDiscountPrice] = useState();
    const [ discountPrices, setDiscountPrices] = useState();
    const [ requireNumber, setRequireNumber] = useState(false);
    const [ requireTemplate, setRequireTemplate] = useState(false);
    const [ openedCategory, setOpenedCategory] = useState();
    const [ isMaster, setIsMaster] = useState(false);
    const [ hasSlave, setHasSlave] = useState(false);
    const [ slaveList, setSlaveList] = useState([]);
    const [ masterApiInfor, setMasterApiInfor] = useState();
    const [ showGuide, setShowGuide] = useState(false);

    const [selectedSlave, setSelectedSlave] = useState(0);

    const [ sideMenuLoading, setSideMenuLoading] = useState(false);
    const [ contentLoading, setContentLoading] = useState(false);

    const [userStatus, setUserStatus] = useState(4);    // 기본값 : 4(시험판계정) - 로그인해서 사용할 수 있는 상황중에서 권한이 가장 낮음

    let navigate = useNavigate()

    useEffect(()=>{
        isMobile && setLogin(false);
    },[])

    useEffect(() => {
        //로그인되어있을 경우, 미승인 계정인지 아닌지 체크
        if(login){
            getUserInfor().then((result)=>{
                setUserStatus(result.userStatus.statusNo);
            })
        }
    }, [login, userStatus])

    useEffect(()=>{
        setContentLoading(false);
        // 라우터 api가 변경될떄 마다 실행
        setSelectedTab('infor');
        setSvcStatus(1)

        getApiCategorys().then(loginResult => {
            setApiCategoryData(loginResult);
            if(login){
                getMySvc().then(mySvc => {
                    setMySvc(mySvc.filter(el => el.masterSvcNo == el.svcNo));
                    setSideMenuLoading(true)
                })
            }else{
                setSideMenuLoading(true)
            }

            getApiDetail(api).then(result => {
                setRequireNumber(result.requireNumber); //result에 있는 requireNumber를 저장
                setRequireTemplate(result.requireTemplate); //result에 있는 requireTemplate를 저장
                setOpenedCategory(result.svcCategory.cateNo); //result에 있는 svcCategory.cateNo를 저장
                setMetaTags({title: result.pageTitle, description: result.metaDescription, keyword: result.tag}); //result에 있는 pageTitle, metaDescription, tag를 저장
                setShowGuide(result.showGuide); //result에 있는 showGuide를 저장
                setApiData(result); // result를 apiData에 저장
                login ? chkMySvc(result.svcNo).then(mySvc => {
                    setSvcStatus(mySvc);
                    if(result.svcNo == result.masterSvcNo){
                    //     // 결과의 svcNo와 결과의 마스터 svcNo이 같으면 즉, 마스터 api라면
                        setMasterApiInfor(result); // 마스터 api 정보를 결과로 바꿔줌
                        setIsMaster(true); // 마스터 api라고 설정
                        if(result.slaveSvcList.length > 0){ // 슬레이브 api가 있으면
                            setHasSlave(true); // 슬레이브 api가 있다고 설정
                            setSelectedSlave(result.slaveSvcList[0].svcNo)
                            Promise.all(result.slaveSvcList.map(slaveApi => {
                                return getSalePrice(slaveApi.svcNo).then(salePrice => {
                                    slaveApi.regularPrice = salePrice.regularPrice
                                    slaveApi.discountPrice = salePrice.discountPrice
                                    return {...salePrice, svcNo: slaveApi.svcNo}
                                });
                            })).then (salePrices => {
                                setSlaveList(result.slaveSvcList)
                                setContentLoading(true);
                            });
                        }else{
                            setSlaveList([])
                            setHasSlave(false);
                            setContentLoading(true);
                            getSalePrice(result.svcNo).then(salePrice => {
                                setDiscountPrice(salePrice)
                            })
                        }
                    }else{
                        setHasSlave(false)
                        setSlaveList([])
                        navigate(`/service/apiservices/${result.masterSvcNo}`);
                        setContentLoading(true);
                    }
                })
                :
                    setContentLoading(true);
                    if(result.svcNo == result.masterSvcNo){
                    //     // 결과의 svcNo와 결과의 마스터 svcNo이 같으면 즉, 마스터 api라면
                        setMasterApiInfor(result); // 마스터 api 정보를 결과로 바꿔줌
                        setIsMaster(true); // 마스터 api라고 설정
                        if(result.slaveSvcList.length > 0){ // 슬레이브 api가 있으면
                            setHasSlave(true); // 슬레이브 api가 있다고 설정
                            setSelectedSlave(result.slaveSvcList[0].svcNo)
                            Promise.all(result.slaveSvcList.map(slaveApi => {
                                return getSalePrice(slaveApi.svcNo).then(salePrice => {
                                    slaveApi.regularPrice = salePrice.regularPrice
                                    slaveApi.discountPrice = salePrice.discountPrice
                                    return {...salePrice, svcNo: slaveApi.svcNo}
                                });
                            })).then (salePrices => {
                                setSlaveList(result.slaveSvcList)
                                setContentLoading(true);
                            });
                        }else{
                            setSlaveList([])
                            setHasSlave(false);
                            setContentLoading(true);
                            getSalePrice(result.svcNo).then(salePrice => {
                                setDiscountPrice(salePrice)
                            })
                        }
                    }else{
                        setHasSlave(false)
                        setSlaveList([])
                        navigate(`/service/apiservices/${result.masterSvcNo}`);
                        setContentLoading(true);
                    }
            });
        });
    },[api, login])

    if(apiData && masterApiInfor){
        return(
            <>
                <div className="apiservice_content">
                    <div className="service_leftMenu">
                        <div className="menu_title">Service</div>
                        {
                            sideMenuLoading
                            ?
                                <>
                                    {
                                        login && mySvc && mySvc.length !== 0 ?
                                        <>
                                            <div className={dropDown ? 'm_sub_title' : 'm_sub_title mnOn'} onClick={()=>{setDropDown(dropDown ? false : true)}}>
                                                <img src={`${IMG_URL}/images/icon_sa_mn_my.png`} alt=""/>
                                                <span>{t('service.using_service')}</span>
                                            </div>
                                            <ul style={dropDown ? {display:'block', borderBottom: '1px solid #ddd'} : {display:'none'}}>
                                                {
                                                    mySvc.map((apiItem,idx)=>{
                                                        return(
                                                            <li className="Lm_name" key={idx}>
                                                                <Link to={`/service/apiservices/${apiItem.svcNo}`}><img src={`/uploads/svcDetail/${apiItem.iconFileName}`} alt=""/>{apiItem[t('apiDetail.name')]}</Link>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </>
                                        : null
                                    }
                                    {
                                        apiCategoryData?.filter(el => el.svcMainCategory.mainCateNo !== 5)
                                        .map((apiCategory, idx) => <SideApiBlock apiCategory={apiCategory} login={login} key={idx} curSvcNo={apiData.svcNo} curCateNo={apiData.svcCategory.cateNo} openedCategory={openedCategory} setOpenedCategory={setOpenedCategory} /> )
                                    }
                                    <div className="menu_title" style={{paddingTop:'25px', marginTop:'15px', borderTop: '1px solid #ddd'}}>Marketplace</div>
                                    {
                                        apiCategoryData?.filter(el => el.svcMainCategory.mainCateNo === 5)
                                        .map((apiCategory, idx) => <SideApiBlock apiCategory={apiCategory} login={login} key={idx} curSvcNo={apiData.svcNo} curCateNo={apiData.svcCategory.cateNo} openedCategory={openedCategory} setOpenedCategory={setOpenedCategory} /> )
                                    }
                                </>
                            : <Loading />
                        }
                    </div>

                    <div className="service_section">
                        {
                            contentLoading
                            ?
                                <div className="ss_content">
                                    <div className="Section_top">
                                        <h3>
                                            <img src={`/uploads/svcDetail/${masterApiInfor.iconFileName}`} alt=""/>
                                            <span dangerouslySetInnerHTML={{__html: masterApiInfor[t('apiDetail.name')].replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}}></span>
                                        </h3>
                                        <p dangerouslySetInnerHTML={{__html:masterApiInfor[t('apiDetail.introduction')].replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}}></p>
                                    </div>
                                    <div className="tab">
                                        <ul role="tablist">
                                            <li 
                                                role="none"
                                                className={selectedTab == 'infor' ? 'tab-link current' : 'tab-link'}
                                                onClick = {()=>{setSelectedTab('infor')}}
                                            >
                                                <a href="#" onClick={(e) => {e.preventDefault()}} id="tab1" role="tab" aria-selected="true" aria-controls="tabpanel-1">{t('service.description')}</a>
                                            </li>
                                            {
                                                showGuide
                                                ?
                                                <li 
                                                    role="none"
                                                    className={selectedTab == 'guide' ? 'tab-link current' : 'tab-link'}
                                                    onClick = {()=>{window.open(localStorage.getItem('lan') === 'ko'? masterApiInfor.devGuidePath : masterApiInfor.devGuidePathEn, '_blank')}}
                                                >
                                                    {t('service.guideHtml')}
                                                    <img src={'/images/icon_arrow_out_black.png'} className="icon_outlink" alt='outlink'/>
                                                </li>
                                                :
                                                null
                                            }
                                            {
                                                (()=>{
                                                    if(!apiData.showServiceConsole) return null
                                                    if(t('langCode') !== "ko") return null
                                                    if(login){
                                                        return(
                                                            <li
                                                                role="none"
                                                                className = {selectedTab == 'console' ? 'tab-link current' : 'tab-link'}
                                                                onClick = {()=>{
                                                                    if(userStatus == 1 || userStatus == 11){
                                                                        if(svcStatus == 0){
                                                                            setSvcAlertModal(true)
                                                                        }else if(svcStatus == 1){
                                                                            setSelectedTab('console')
                                                                        }else{
                                                                            alert('승인대기중인 서비스입니다.')
                                                                        }
                                                                    }else{
                                                                        setSvcNeedApproval(true)
                                                                    }
                                                                }}
                                                            >
                                                            <a href="#" onClick={(e) => {e.preventDefault()}} id="tab3" role="tab" aria-selected="true" aria-controls="tabpanel-3">{t('service.console')}</a>
                                                            </li>
                                                        )
                                                    }else{
                                                        return null
                                                    }
                                                })()
                                            }
                                        </ul>
                                    </div>
                                    <div className="apiInfo_section">
                                        {
                                            selectedTab === 'infor'
                                            ?
                                                <div
                                                    className="tab-content current _guide_area"
                                                    dangerouslySetInnerHTML={{__html: isMobile 
                                                        ? masterApiInfor[t('apiDetail.descriptionMobile')].replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL) 
                                                        : masterApiInfor[t('apiDetail.description')].replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)
                                                    }}
                                                />
                                            :
                                            selectedTab == 'guide'
                                            ?
                                                hasSlave && slaveList.filter(el => el.showGuide).length > 0
                                                ?
                                                    <div
                                                        className="tab-content current _guide_area"
                                                        dangerouslySetInnerHTML={{__html: slaveList.find(el => el.svcNo === selectedSlave)[t('apiDetail.guideHtml')].replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}}
                                                    />
                                                :
                                                    <div
                                                        className="tab-content current _guide_area"
                                                        dangerouslySetInnerHTML={{__html: masterApiInfor[t('apiDetail.guideHtml')].replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}}
                                                    />
                                            :
                                            selectedTab == 'console'
                                            ?
                                                t('langCode') === "ko"
                                                ? <ServiceConsole svcNo={masterApiInfor.svcNo} svcData={masterApiInfor} svcName={apiData[t('apiDetail.name')]} requireNumber={requireNumber} requireTemplate={requireTemplate}/>
                                                : null
                                            : null
                                        }
                                    </div>
                                        <div className="p_left_section">
                                            <div className="apiPrice_section">
                                                {/* 우측 인포 패널 시작 */}
                                                {
                                                    isMaster && (selectedTab == 'infor' || selectedTab == 'console')
                                                    ? // infor, console 탭일 경우 - 가격 패널 노출
                                                        apiData.showPrice
                                                        ? <PricePanel data={{apiData, slaveList, discountPrice, svcStatus, login, setSvcApplyModal}}/>
                                                        : null
                                                    :
                                                    isMaster && selectedTab == 'guide'
                                                    ? // guide 탭일 경우 - 개발 가이드 패널 노출
                                                        hasSlave && slaveList.filter(el => el.showGuide).length > 0
                                                        ?
                                                            apiData.showGuide
                                                            ? <DevGuidePanel data={{masterApiInfor,slaveList,selectedSlave,setSelectedSlave}} />
                                                            : null
                                                        :
                                                            apiData.showPrice
                                                            ? <PricePanel data={{apiData, slaveList, discountPrice, svcStatus, login, setSvcApplyModal}}/>
                                                            : null
                                                    :   null
                                                }
                                                {
                                                    apiData[t('apiDetail.memo')] &&
                                                    <>
                                                        <div className="script">Info</div>
                                                        <div className="script">
                                                            {apiData[t('apiDetail.memo')]}
                                                        </div>
                                                    </>
                                                }
                                                <div className="tt_blank20"></div>
                                                <div className="a_s_btn">
                                                    {
                                                        (()=>{
                                                            if(t('langCode') !== 'ko') return null;
                                                            if(!apiData.showServiceConsole  ) return <button className="login_as" onClick={()=>{navigate('/support/requests')}}>사용 문의하기</button>
                                                            if(login){
                                                                if(svcStatus == 0){
                                                                    return <button className="login_as"
                                                                            onClick={()=>{
                                                                                    if(userStatus == 1 || userStatus == 11){
                                                                                        setSvcApplyModal(true)
                                                                                    }else{
                                                                                        setSvcNeedApproval(true)
                                                                                    }
                                                                                }
                                                                            }>
                                                                            {t('service.applyService')}
                                                                            </button>
                                                                }else if(svcStatus == 1){
                                                                    return null
                                                                }else if(svcStatus == 2){
                                                                    return <button className="login_wait">승인 대기</button>
                                                                }
                                                            }else{
                                                                return null
                                                            }
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                            <ManualPanel data={{apiData, slaveList}}/>
                                        </div>
                                </div>
                            :   <div style={{minHeight:'900px', display:'flex', justifyContent:'center', alignItems: "center"}}><Loading /></div>
                        }
                    </div>
                </div>
                {svcAlertModal && <SvcAlert svcAlertModal={svcAlertModal} setSvcAlertModal={setSvcAlertModal} setSvcApplyModal={setSvcApplyModal}/>}
                {svcApplyModal && <SvcApply svcApplyModal={svcApplyModal} setSvcApplyModal={setSvcApplyModal} svcNo={masterApiInfor.svcNo} setSvcStatus={setSvcStatus} setMySvc={setMySvc} setSvcPermitModal={setSvcPermitModal}/>}
                {svcPermitModal && <SvcPermit svcPermitModal={svcPermitModal} setSvcPermitModal={setSvcPermitModal}/>}
                {svcNeedApproval && <SvcNeedApproval svcNeedApproval={svcNeedApproval} setSvcNeedApprovalModal={setSvcNeedApproval}/>}
            </>
        )
    }else return <Loading />
}

export default ApiServices;