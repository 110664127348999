import { t } from 'i18next';
import React,{useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import './css/common_mob.css'
import { useTranslation  } from 'react-i18next';
import i18n from '../lang/i18n';
import RouteChangeTracker from '../Util/RouteChangeTracker';
import { useGlobal } from '../shared/globalContext';
import {getSearchResult} from "../Util/reqApi";
import SearchSuggestionBlock from "../Pages/Search/SearchSuggestionBlock";
import { Swiper, SwiperSlide } from "swiper/react";

const MobileHeader = () => {
    const global = useGlobal();
    const {pathname} = useLocation();
    const {menus} = global;
    const [sideMenu, setSideMenu] = useState(false);
    const [searchArea, setSearchArea] = useState(false);
    const [dropDown, setDropDown] = useState(false);
    const { t } = useTranslation();
    const [searchStr, setSearchStr] = useState('');
    const [data, setData] = useState(null);

    useEffect(() => {
      // URL 변경 시 검색창 초기화
      setSearchStr('');
      setSearchArea(false);
    }, [pathname]); // location.pathname이 변경될 때마다 useEffect 실행

    useEffect(() => {
      if(searchStr){
        getSearchResult(searchStr).then((res) => {
          if(typeof res == "string"){
            setData(null);
          }else{
            setData(res);
          }
        });
      }
    }, [searchStr]);

    const changeLang = (lang) =>{        
        i18n.changeLanguage(lang).then(()=>localStorage.setItem('lan',lang));
    }
    
    return (
        <header>
            <button className="searchBtnMo" type="button" onClick={()=>{ setSideMenu(false); !searchArea ? setSearchArea(true) : setSearchArea(false);}}>
              <img src='/images/icon-search-white.svg'/>
            </button>
            <div className={sideMenu ? 'mobMenuBtn_close' : 'mobMenuBtn'} onClick={()=>{ setSearchArea(false); !sideMenu ? setSideMenu(true) : setSideMenu(false)}}>
                <div className="menubtn"></div>
            </div>
            <div className="logo"><a href="/"><img src="/images/logo.png" alt="API PLEX logo"/></a></div>
            <div className="searchArea" style={(!sideMenu && searchArea) ? {display:"block"} : {display:"none"}}>
              <label htmlFor='headerMobileSearch' className='hidden'></label>
              <input id='headerMobileSearch' className="searchMobileBar" type="text" placeholder="검색어를 입력해주세요" value={searchStr} onChange={(e) => {setSearchStr(e.target.value);}}/>
              <div className="SearchSwipeArea">
                <Swiper className='SearchSwiper-container' spaceBetween={0} slidesPerView={1} loop={false} >
                  {
                    searchStr? (!data ? (<p className="noResultP">검색 결과가 없습니다.</p>) : data?.map((el, idx)=> {
                      return(
                        <SwiperSlide key = {idx}>
                          <SearchSuggestionBlock key={idx} el={el} mo={true}/>
                        </SwiperSlide>
                      )
                    })) : (<></>)
                  }
                </Swiper>
              </div>
            </div>
            <div className="top_menu" style={(sideMenu && !searchArea) ? {display:"block"} : {display:"none"}}>
                <ul>
                    {menus && menus.map((menu, idx) =>
                        <li className="menu_h1" key={idx}>
                            <Link to={menu.url} onClick={()=>{setSideMenu(false)}}>{menu[t('cateName')]}</Link>
                        </li>
                    )}
                </ul>
                <div className="mob_select_lang">
                    <div className="lanselect" onClick={()=>{dropDown ? setDropDown(false) : setDropDown(true)}}>
                        <span>{t('language')}</span>
                        <div className="langName">
                            <span className="countryCode">{t("header.selectedLang")}</span>
                            <span className="arr"><img src="/images/icon_seltop.png" alt=""/></span>
                        </div>
                        <div className="langseltBox" style={dropDown ? {display:"block"} : null}>
                            <button id="lanaSelt_1" onClick={() => {changeLang('ko'); setDropDown(false); window.location.href ='/'}}><strong>KOR</strong></button>
                            <button id="lanaSelt_2" onClick={() => {changeLang('en'); setDropDown(false); window.location.href ='/'}}><strong>ENG</strong></button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default MobileHeader;