import React from 'react';
import { t } from "i18next";

const DevGuidePanel = ({data : {masterApiInfor,slaveList,selectedSlave,setSelectedSlave}}) => {
    return (
        <>
            <h4 dangerouslySetInnerHTML={{__html: masterApiInfor[t('apiDetail.name')].replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}} className="tit"></h4>
            <div className="p_info_box">
                <ul>
                    {
                        slaveList.filter(el => el.showGuide).map(slaveApi => {
                            return(
                                <a state={{tab:"guide"}} key={slaveApi.svcNo} onClick={() => setSelectedSlave(slaveApi.svcNo)}>
                                    <li className="p_name" style={selectedSlave == slaveApi.svcNo ? {color:'#6c5ad6', cursor:"pointer"} : {cursor:"pointer"}} dangerouslySetInnerHTML={{__html: slaveApi[t('apiDetail.name')].replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}}></li>
                                </a>
                            )
                        })
                    }
                </ul>
            </div>
        </>
    );
};

export default DevGuidePanel;