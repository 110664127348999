import React, {useEffect, useState} from 'react';
import { getServiceHistory, getSubAccount } from '../../Util/reqApi';
import Loading from '../../Util/Loading';
import DatePicker from "react-datepicker";
import { subDays, addDays } from "date-fns";
import { useParams } from 'react-router-dom';
import { formatCurrentDateZero } from '../../Util/DateFormatter';
import Pagination from './Component/Pagination'

const ServiceConsoleTab02 = () => {
    const [subAccount, setSubAccount] = useState();
    const [serviceHistory, setServiceHistory] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [selectedSubAccount, setSelectedSubAccount] = useState();

    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(1);
    const [numPages, setNumPages] = useState(0);
    const [record, setRecord] = useState(0);

    const [selectCombo, setSelectCombo] = useState("");
    const [queryTxt, setQueryTxt] = useState("");

    const [serviceHistoryLoading, setServiceHistoryLoading] = useState(false);

    const { api } = useParams();

    const findSubAccount = (e) => {
        if(subAccount){
            return subAccount.filter((el)=>{
                return el.subject == e
            })
        }
    }

    const checkOver7Days = (date) => {
        if(startDate < subDays(date, 6)){
            alert("최대 검색 가능한 기간은 7일입니다.")
            setEndDate(addDays(startDate, 6));
        }else{
            setEndDate(date);
        }
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const handlePageChange = (page) => {
        setPage(page);
        setServiceHistoryLoading(true);
        scrollToTop();
        if(selectedSubAccount && selectedSubAccount[0]){
            getServiceHistory(selectedSubAccount[0].accountNo,formatCurrentDateZero(startDate), page, limit ,formatCurrentDateZero(endDate, 'end'), selectCombo, queryTxt).then(result=> {
                setServiceHistory(result.data)
                setRecord(result.record);
                setServiceHistoryLoading(false);
            })
        }
    }

    useEffect(()=>{
        getSubAccount(api).then(result => {
            setSubAccount(result.subAccountList)
            setSelectedSubAccount([result.subAccountList[0]])
        })

        return () => {
            setSubAccount();
            setServiceHistory();
            setSelectedSubAccount();
        }
    },[])

    useEffect(() => {
        if(record < 1)   setNumPages(0);
        // setNumPage를 1개 ~ limit개 까지 1, limit+1개 ~ limit*2개까지 2... limit*n+1 ~ limit*(n+1) 개까지 n 으로 설정
        else {
            setNumPages(Math.floor((record-1)/limit) + 1);
        }
    }, [record, serviceHistory])

    // useEffect(()=>{
    //     if(selectedSubAccount){
    //         if(!selectedSubAccount[0]){setServiceHistory(); return}
    //         getServiceHistory(selectedSubAccount[0].accountNo,formatCurrentDateZero(startDate),1, 999 ,formatCurrentDateZero(endDate, 'end')).then(result=> {
    //             setServiceHistory(result)
    //         })
    //     }
    // },[selectedSubAccount, startDate, endDate])

    if(subAccount && !serviceHistoryLoading){
        return(
            <div id="cons-2" className="tab-content current">
                <div>
                    <div id="consoleMenuTitle" className="tt_h2">사용내역</div>
                    <div className="tt_script">
                    </div>
                </div>
                <div className="tt_blank20"></div>
                <div className="pj_selt" style={{marginBottom:"20px", display:"inline-block", marginRight:"20px"}}>
                    <div className="tit">Sub Account 선택</div>
                    <div className="selt">
                        <select
                                style={{width:'215px'}}
                                onChange={(e)=>{
                                    setSelectedSubAccount(findSubAccount(e.target.value))
                                }}
                        >
                            {
                                (()=>{
                                    if(subAccount.length == 0) return <option>등록된 Sub Account가 없습니다.</option>
                                    return subAccount.map((el,idx)=>{
                                        return <option key={idx}>{el.subject}</option>
                                    })
                                })()
                            }
                        </select>
                    </div>
                </div>
                <div style={{display:"inline-flex", justifyContent:"space-between", width:'260px', marginBottom:'20px'}}>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {setStartDate(date); setEndDate(date);}}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            dateFormat = {`yyyy-MM-dd`}
                            className='datePicker'
                        />
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => checkOver7Days(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            dateFormat = {`yyyy-MM-dd`}
                            className='datePicker'
                        />
                </div>
                <div style={{display:"inline-flex", justifyContent:"space-between", width:'260px', marginBottom:'20px', marginRight:'5px'}}>
                    <select
                        style={{minWidth:"80px"}}
                        value={selectCombo}
                        onChange={(e)=>{
                            setSelectCombo(e.target.value);
                            setQueryTxt("");
                        }}
                    >
                        <option value={''}>검색항목</option>
                        <option value={'user_key'}>유저키</option>
                    </select>
                    <input
                        type="text"
                        style={{minWidth:"60px",marginRight:'10px',border:"1px solid #ddd"}}
                        value={queryTxt}
                        onChange={(e)=>{
                            setQueryTxt(e.target.value);
                        }}
                    />
                </div>
                {/* <div className="option_bar4" style={{padding:'15px'}}>
                    <div className="t_price">Total Count : <span>250,000</span></div>
                    <div className="t_date_option">
                        <span>기간선택</span>
                        <select>
                            <option>지난 1주일</option>
                        </select>
                        <span className="t_datepicker">
                            <DateRange />
                        </span>
                    </div>
                </div> */}
                <button
                    className="btn_sm6"
                    onClick={()=>{
                        setPage(1);
                        if(selectedSubAccount){
                            if(!selectedSubAccount[0]){setServiceHistory(); return}
                            scrollToTop();
                            setServiceHistoryLoading(true);
                            getServiceHistory(selectedSubAccount[0].accountNo,formatCurrentDateZero(startDate), page, limit ,formatCurrentDateZero(endDate, 'end'), selectCombo, queryTxt).then(result=> {
                                setServiceHistory(result.data)
                                setRecord(result.record);
                                setServiceHistoryLoading(false);
                            })
                        }
                    }}
                >
                    조회하기
                </button>
                <div style={{marginBottom:'10px'}}>
                    <span>검색결과 (총 {record}개)</span>
                </div>
                <table className="table_mypage">
                    <colgroup>
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>상품명</th>
                            <th>요청일시</th>
                            <th>Webhook 일시</th>
                            <th>user key</th>
                            <th>메세지 key</th>
                            <th>요청상태</th>
                            <th>설명</th>
                            <th>발신번호</th>
                            <th>수신번호</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (()=>{
                                if(serviceHistory) {
                                    if(typeof(serviceHistory) == 'string') return <tr><td colSpan={9}>사용내역이 없습니다.</td></tr>
                                    if(serviceHistory.length == 0) return <tr><td colSpan={9}>사용내역이 없습니다.</td></tr>
                                    return serviceHistory.slice(0, page == 1 ? limit : page * limit).map((el,idx)=>{
                                        return(
                                            <tr key={idx}>
                                                <td>{el.service_name_kr}</td>
                                                <td>{el.requested_datetime}</td>
                                                <td>{el.webhook_datetime}</td>
                                                <td>{el.user_key}</td>
                                                <td>{el.service_use_sequence_string}</td>
                                                <td>{el.status}</td>
                                                <td>{el.status_name}</td>
                                                <td>{el.sender_no}</td>
                                                <td>{el.receiver_no}</td>
                                            </tr>
                                        )
                                    })
                                }else return <tr><td colSpan={9}>사용내역이 없습니다.</td></tr>
                            })()
                        }
                    </tbody>
                </table>
                {  numPages !== 0 && <Pagination data={{ page, setPage:handlePageChange, numPages}} /> }
{/*                {
                    serviceHistory?.length >= page * limit
                    ?   <div style={{display:'flex', width:"100%", justifyContent:"center"}}>
                            <button
                                className="btn_sm6"
                                onClick={(e)=>{
                                    e.preventDefault();
                                    setPage(page + 1)
                                }}
                                style={{margin:'20px 0'}}
                            >더보기</button>
                        </div>
                    : null
                }*/}
                <div className="tt_blank80"></div>
            </div>
        )
    }else{
        return <Loading />
    }
}

export default ServiceConsoleTab02;