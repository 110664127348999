const getAccessToken = (setLogin) => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');

    if(accessToken){
        setLogin(true);
    }else{
        setLogin(false);
    }
}

export default getAccessToken;