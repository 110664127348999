import React, {useState, useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';
import { getNoticeDetail } from '../../Util/reqApi';
import Loading from '../../Util/Loading';
import setMetaTags from '../../Util/setMetaTags';
import PageInfo from '../../page_info.json';

const NoticeView = (props) => {
    const {selectedCate} = props
    const { id } = useParams();
    const navigate = useNavigate();
    const [noticeDetail, setNoticeDetail] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        setIsLoading(false);
        getNoticeDetail({cateNo : selectedCate, noticeNo : id, langNo: t('langNo')})
        .then(result => {
            setNoticeDetail(result);
            setIsLoading(true);
        })
    },[id])

    useEffect(() => {
        setMetaTags({
            title: noticeDetail?noticeDetail.subject:'' + " - " + PageInfo.Notice.title
        })
    }, [])

    return(
        isLoading
            ?
        <>
        <h1 style={{display:"none"}}>{noticeDetail.subject}</h1>
            <div id="tab-1" className="tab-content current">
                        <div className="mp_table">
                            <table>
                                <colgroup>
                                    <col width="30px" />
                                    <col width="60px" />
                                    <col width="*%" />
                                    <col width="150px" />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td><img src={`/images/icon_noti_${noticeDetail.noticeCategory.cateNo}.png`} alt={noticeDetail.noticeCategory.cateName}/></td>
                                        <td>{noticeDetail && noticeDetail.subject}</td>
                                        <td>{noticeDetail && noticeDetail.regDate[0]}{t('year')} {noticeDetail && noticeDetail.regDate[1]}{t('month')} {noticeDetail && noticeDetail.regDate[2]}{t('day')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="noti_view">
                            <div className="textBox" dangerouslySetInnerHTML={{__html: noticeDetail.content.replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}} />
                        </div>
                        {
                            noticeDetail.nextNotice
                            ?   <div className="page_go" onClick={()=>{navigate(`/support/noticeView/${noticeDetail.nextNotice.noticeNo}`)}}>
                                    <div className="cate">{noticeDetail.nextNotice.noticeCategory.cateName}</div>
                                    <div className="title">{noticeDetail.nextNotice.subject}</div>
                                    <div className="date">{noticeDetail.nextNotice.regDate[0]}{t('year')} {noticeDetail.nextNotice.regDate[1]}{t('month')} {noticeDetail.nextNotice.regDate[2]}{t('day')}</div>
                                </div>
                            :   <div className="page_none">{t('support.empty_next')}</div>
                        }
                        {
                            noticeDetail.prevNotice
                            ?   <div className="page_go" onClick={()=>{navigate(`/support/noticeView/${noticeDetail.prevNotice.noticeNo}`)}}>
                                    <div className="cate">{noticeDetail.prevNotice.noticeCategory.cateName}</div>
                                    <div className="title">{noticeDetail.prevNotice.subject}</div>
                                    <div className="date">{noticeDetail.prevNotice.regDate[0]}{t('year')} {noticeDetail.prevNotice.regDate[1]}{t('month')} {noticeDetail.prevNotice.regDate[2]}{t('day')}</div>
                                </div>
                            :   <div className="page_none">{t('support.empty_prev')}</div>
                        }
                <div className="nt_btn_area" onClick={()=>{navigate('/support/notice?cate=0&page=0')}}>
                    <button className="type_2">{t('support.list')}</button>
                </div>
            </div>
        </>
        : <Loading />
    )
}

export default NoticeView