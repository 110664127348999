import React, {useEffect, useState} from 'react'
import Loading from '../../Util/Loading';
import { changeSubAccountUse, deleteSubAccount, getSubAccount } from '../../Util/reqApi'

const KeyManagement = () => {
    const [subAccounts, setSubAccounts] = useState();

    useEffect(()=>{
        getSubAccount().then(result => setSubAccounts(result))
    },[])


    return(
        <div className="acc_section">
            <div className="km_info_box">
                <div className="in_tit">Sub Account 관리 이용안내</div>
                <div className="in_tt">
                - 서비스별 Sub Account를 관리할 수 있습니다.<br/>
                - 새 Sub Account는 각 서비스 신청 메뉴에서 생성할 수 있습니다. <br/>
                - Sub account를 사용하지 않을 때는 ‘사용 중지’로 설정할 수 있으며, 삭제는 ‘사용 중지’ 상태에서만 가능합니다.<br/>
                </div>
            </div>
            <div className="table_area">
                    <div className="script_d">Sub Account 관리</div>
                    <table className="table_mypage">
                        <colgroup>
                            <col width="15%" />
                            <col width="*" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="10%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>이름</th>
                                <th>설명</th>
                                <th>서비스</th>
                                <th>사용중지</th>
                                <th>삭제</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (()=>{
                                    if(subAccounts){
                                        if(subAccounts.length !== 0){
                                            return(
                                                subAccounts[0].subAccount.map((subAccount, idx)=>{
                                                    if(!subAccount.enabled) return null
                                                    return(
                                                        <tr key={idx}>
                                                            <td>{subAccount.subject}</td>
                                                            <td style={{textAlign:"left"}}>{subAccount.description}</td>
                                                            <td>{subAccount?.serviceId?.split('|')[1]}</td>
                                                            <td>
                                                                <div
                                                                    className={subAccount.use ? "toggle_btn active" : "toggle_btn"}
                                                                    onClick = {()=>{
                                                                        changeSubAccountUse(subAccount.accountNo, !subAccount.use).then(() => {
                                                                            getSubAccount().then(result => {
                                                                                setSubAccounts(result)
                                                                            })
                                                                        })
                                                                    }}
                                                                >
                                                                    <input type="checkbox" className="cb-value" />
                                                                    <span className="round-btn"></span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className="btn_sm"
                                                                    onClick={()=>{
                                                                        if(!subAccount.use){
                                                                            deleteSubAccount(subAccount.accountNo).then((log)=>{
                                                                                getSubAccount().then(result => {
                                                                                    setSubAccounts(result)
                                                                                })
                                                                            })
                                                                        }else{
                                                                            alert('사용중지중인 Sub Account만 삭제 가능합니다.')
                                                                        }
                                                                    }}
                                                                >삭제</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            )
                                        }else{
                                            return(
                                                <tr>
                                                    <td colSpan={5}>No data</td>
                                                </tr>
                                            )
                                        }
                                    }else{
                                        return <tr><td colSpan={5}><Loading  /></td></tr>
                                    }
                                })()
                            }
                        </tbody>
                    </table>
                </div>
        </div>
    )
}

export default KeyManagement;