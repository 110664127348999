import React, {useState, useEffect} from 'react';
import { getQueryString } from './getQueryString';

const MobileCheckResult = () => {
    const [rec_cert, setRec_cert] = useState();
    const [certNum, setCertNum] = useState();

    useEffect(()=>{
        if(getQueryString('rec_cert')){
            setRec_cert(getQueryString('rec_cert'))
            
        }
        if(getQueryString('certNum')){
            setCertNum(getQueryString('certNum'))
            
        }    
    },[])

    useEffect(()=>{
        if(rec_cert && certNum){
            window.opener.document.querySelector('#rec_cert').value = rec_cert;
            window.opener.document.querySelector('#certNum').value = certNum;
            window.opener.document.querySelector('#rec_cert').click();
            window.close();
        }
    })

    return(
        <></>
    )
}

export default MobileCheckResult;