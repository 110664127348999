import React, {useState, useEffect} from 'react'
import { getSubAccount, modifySubAccount } from '../../../../Util/reqApi';

const EditSubAccount = (props) => {
    const {
        svcNo,
        svcName,
        subAccountEditModal,
        setSubAccountEditModal,
        subAccountEditTarget,
        setSubAccountEditTarget,
        setSubAccountData
    } = props;

    const [subAccountDesc, setSubAccountDesc] = useState();

    useEffect(()=>{
        if(subAccountEditTarget){
            setSubAccountDesc(subAccountEditTarget.description);
        }
    },[subAccountEditTarget])

    if(subAccountEditTarget){
        return(
            <div id="" className="pop_set_p" style={subAccountEditModal ? {display:"block"} : {display:"none"}}>
                <div className="pop_section">
                    <div className="parea">
                        <div className="page">
                            <div className="ppheader">
                                <div className="htit">Sub Account 수정</div>
                                <div
                                    className="rclose"
                                    onClick={()=>{
                                        setSubAccountEditModal(false)
                                        setSubAccountEditTarget()
                                    }}
                                ><img src='/images/icon_pop_close.png' alt="닫기"/></div>
                            </div>
                            <div className="p_table_section">
                                <table className="table_mypageBp">
                                    <colgroup>
                                        <col width="80px" />
                                        <col width="*%" />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td>서비스 ·</td>
                                            <td>{svcName}</td>
                                        </tr>
                                        <tr>
                                            <td>이름 ·</td>
                                            <td>
                                                {subAccountEditTarget.subject}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>설명 ·</td>
                                            <td>
                                                <textarea
                                                    type="text"
                                                    style={{width:"100%",height:"120px"}}
                                                    placeholder="최대 120자 이상 입력할 수 없습니다."
                                                    onChange={(e)=>{setSubAccountDesc(e.target.value)}}
                                                    value={subAccountDesc}
                                                    maxLength={120}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="s_u_padding20"></div>
                            </div>
                            <div className="nt_btn_area_p">
                                <button
                                    className="btn_sm4"
                                    onClick={()=>{
                                        setSubAccountEditModal(false)
                                        setSubAccountEditTarget()
                                    }}
                                >닫기</button>
                                <button
                                    className="btn_sm6"
                                    onClick={()=>{
                                        if(subAccountDesc.length > 120) {alert("120자 이상 입력할 수 없습니다.")}

                                        modifySubAccount(subAccountEditTarget.accountNo, null, subAccountDesc).then(()=>{
                                            getSubAccount(svcNo).then(result => setSubAccountData(result))
                                        })
                                        setSubAccountEditTarget()
                                    }}
                                >
                                    수정하기
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else{
        return null;
    }
}

export default EditSubAccount;