import React, {useEffect, useState} from 'react';
import { t } from 'i18next';

const PushSubscribeModal = ({ isOpen, onClose, onButtonClick }) => {
    return (
        isOpen === true ?
            <div className="pop_set_s mobile_disabled">
                <div className="pop_section">
                    <div className="parea">
                        <div className="page shadow">
                            <div className="ppheader">
                                <div className="htit_t16">{t('modal.pushSubscribeTitle')}</div>
                            </div>
                            <div className="subscribe">
                                <p>{t('web-push.subscribeTitle')}</p>
                            </div>
                            <div className="btn_area">
                                 <button className="btn_sm3" onClick={onClose}>
                                    {t('web-push.subscribeBtnLater')}
                                 </button>
                                 <button className="btn_sm2" onClick={onButtonClick}>
                                    {t('web-push.subscribeBtnSubscribe')}
                                 </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        : null
    );
}

export default PushSubscribeModal;