import React, {useState, useEffect} from 'react';
import Loading from '../../Util/Loading';
import { deleteTemplate, exportTemplt, getAlimProfile, getATTemplateDetail, getATTemplateList, getUserInfor, requestTemplateInspect } from '../../Util/reqApi';

const ServiceConsoleTab_templt = ({modal, setModal, modal02, setModal02, setModifyData}) => {
    const [simpleText, setSimpleText] = useState('');
    const [simpleTextModal, setSimpleTextModal] = useState('');
    const [reasonData, setReasonData] = useState('');
    const [rejectionReasonModal, setRejectionReasonModal] = useState('');
    const [userId, setUserId] = useState('');
    const [alimProfile, setAlimProfile] = useState('');
    const [selectedATProfile, setSelectedATProfile] = useState('');
    const [senderKey, setSenderKey] = useState('');
    const [templateList, setTemplateList] = useState([]);
    const [handleCheckList, setHandleCheckList] = useState([]);
    const [callbackFunc, setCallbackFunc] = useState();
    const [templtCode, setTempltCode] = useState('')
    const [templtName, setTempltName] = useState('')
    const [inspStatus, setInspStatus] = useState(null)
    const [status, setStatus] = useState(null)
    const [block, setBlock] = useState('')
    const [dormant, setDormant] = useState(null)
    const [selectCombo, setSelectCombo] = useState('')

    useEffect(()=>{
        getUserInfor().then(result => {
            setUserId(result.userId);
            getAlimProfile({userId : result.userId}).then(resultProfile => {
                if(resultProfile.code === "200"){
                    setAlimProfile(resultProfile.data.list || '')
                    setSenderKey(resultProfile.data.list[0].senderKey)
                }else{
                    setAlimProfile(resultProfile.message || '')
                    setTemplateList(resultProfile.message)
                }
            });
        })
        
    },[])

    useEffect(()=>{
        getATTList();
    },[senderKey])

    useEffect(()=>{
        if(!modal){
            getATTList();
        }
    },[modal])

    useEffect(()=>{
        if(!modal02){
            getATTList();
        }
    },[modal02])

    const getATTList = () => {
        if(senderKey && userId){
            setTemplateList();
            getATTemplateList({userId: userId,senderKey: senderKey}).then(result=> {

                if(result.data.code !== "200"               // KakaoTalk Template Server response with failure
                    || result.code == 2                     // API Gateway response with failure
                    || result.data.data.list.length == 0)   // Success but No template Data
                {
                    setTemplateList('데이터가 없습니다.');
                    return;
                }

                setTemplateList(result.data.data.list);
                setSelectedATProfile(alimProfile.find(el => el.senderKey == senderKey).name)
                setHandleCheckList(result.data.data.list.map((el,idx) =>{
                    return {templtCode : el.templtCode, checkedProp: false, idx : idx}
                }))
            });
        }
    }

    const searchTemplate = () => {
        if(!userId) return;
        if(!senderKey) return;
        setTemplateList([]);
        setSelectedATProfile('');
        getATTemplateList({userId: userId,senderKey: senderKey, templtCode, templtName, inspStatus, status, block, dormant}).then(result=> {

            if(result.data.code !== "200"               // KakaoTalk Template Server response with failure
                || result.code == 2                     // API Gateway response with failure
                || result.data.data.list.length == 0)   // Success but No template Data
            {
                setTemplateList('데이터가 없습니다.');
                return;
            }

            setTemplateList(result.data.data.list);
            setSelectedATProfile(alimProfile.find(el => el.senderKey == senderKey).name)
            setHandleCheckList(result.data.data.list.map((el,idx) =>{
                return {templtCode : el.templtCode, checkedProp: false, idx : idx}
            }));
        });
    }

    const simpleModalClose = () => {
        setSimpleText();
        setSimpleTextModal(false);
    }

    const checkCheckBox = () => {
        const result = handleCheckList.filter(el => {
            return el.checkedProp
        })
        return result.length !== 0;
    }

    const reqInspection = () => {
        const result = handleCheckList.filter(el => {
            return el.checkedProp
        })

        result.forEach(el => {
            requestTemplateInspect({senderKeyType:"S", templtCode: el.templtCode, userId: userId, senderKey: senderKey}).then( result =>{
                if(result.data.code !== 200){
                    alert('일부 템플릿의 검수요청에 실패하였습니다. 관리자에게 문의해주세요.')
                    getATTList();
                    return;
                }
            })
        })

        alert('모든 템플릿이 정상적으로 검수요청 되었습니다.');
        getATTList();
    }

    const reqDelete = () => {
        const result = handleCheckList.filter(el => {
            return el.checkedProp
        })
        result.forEach(el => {
            deleteTemplate({senderKeyType:"S", templtCode: el.templtCode, userId: userId, senderKey: senderKey}).then( result =>{
                if(result.data.code !== 200){
                    alert('일부 템플릿의 삭제에 실패하였습니다. 관리자에게 문의해주세요.')
                    getATTList();
                    return;
                }
            })
        })

        alert('모든 템플릿이 정상적으로 삭제 되었습니다.');
        getATTList();
    }

    const reqDownload = () => {
        let tempList = [];
        const result = handleCheckList.filter(el => {
            return el.checkedProp
        })
        result.forEach(el => {
            tempList.push(el.templtCode);
        })
        
        window.open(`/mnt/nas/export/templt?userId=${userId}&senderKey=${senderKey}&templtCodeList=${tempList.join(',')}`)
        // exportTemplt({
        //     userId : userId,
        //     senderKey : senderKey,
        //     profileName : selectedATProfile,
        //     templtCodeList : tempList,
        // })
    }

    if(alimProfile){
        return(
            <div id="cons-2" className="tab-content current">
                <div className="tt_h2">알림톡 템플릿 관리</div>
                <div className="option_bar5">
                    <div className="t_date_option_arT" temp={'bordertop'} style={{display:"flex"}}> 
                        <span className="op_box" style={{marginRight:'10px', display:"inline-block"}}>
                            <span>발신 프로필</span>
                            <select style={{minWidth:"100px"}}
                                onChange={(e)=>{
                                    setSenderKey(e.target.value || '')
                                }}
                                value={senderKey || ''}
                            >
                                <option value={''}>발신프로필</option>
                                {(()=>{
                                    if(typeof(alimProfile) !== 'string'){
                                        return alimProfile.map((el,idx)=>{
                                            return <option key={idx} value={el.senderKey}>{el.name}</option>
                                        })
                                    }
                                })()}
                            </select>
                        </span>
                        <span className="op_box" style={{marginRight:'10px', display:"inline-block"}}>
                            <span>검수상태</span>
                            <select
                                style={{minWidth:"100px"}}
                                onChange={(e)=>{setInspStatus(e.target.value)}}
                                value={inspStatus || ''}
                            >
                                <option value={''}>검수상태</option>
                                <option value={"REG"}>등록</option>
                                <option value={"REQ"}>심사요청</option>
                                <option value={"APR"}>승인</option>
                                <option value={"REJ"}>반려</option>
                            </select>
                        </span>
                        <span className="op_box" style={{marginRight:'10px', display:"inline-block"}}>
                            <span>템플릿상태</span>
                            <select
                                style={{minWidth:"100px"}}
                                value={status || ''}
                                onChange={(e)=>{
                                    setStatus(e.target.value);
                                }}
                            >
                                <option value={''}>템플릿상태</option>
                                <option value={'A'}>정상</option>
                                <option value={'S'}>중지</option>
                                <option value={'R'}>대기(발송전)</option>
                            </select>
                        </span>
                        <span className="op_box" style={{marginRight:'10px', display:"inline-block"}}>
                            <span>휴면상태</span>
                            <select
                                style={{minWidth:"100px"}}
                                value={dormant || ''}
                                onChange={(e)=>{
                                    setDormant(e.target.value);
                                }}
                            >
                                <option value={''}>휴면상태</option>
                                <option value={true}>휴면</option>
                                <option value={false}>휴면해제</option>
                            </select>
                        </span>
                        <span className="op_box" style={{marginRight:'10px', display:"inline-block", marginLeft:'auto'}}>
                            <select
                                style={{minWidth:"80px"}}
                                value={selectCombo || ''}
                                onChange={(e)=>{
                                    setSelectCombo(e.target.value);
                                }}
                            >
                                <option value={''}>검색항목</option>
                                <option value={'templtCode'}>템플릿코드</option>
                                <option value={'templtName'}>템플릿명</option>
                            </select>
                            <input
                                type="text"
                                style={{minWidth:"60px",marginRight:'10px'}}
                                onChange={(e)=>{
                                    setTempltCode()
                                    setTempltName()
                                    if(selectCombo == 'templtCode'){                                        
                                        setTempltCode(e.target.value);
                                    }else if(selectCombo == 'templtName'){
                                        setTempltName(e.target.value);
                                    }else{
                                        setTempltCode(e.target.value);
                                        setTempltName(e.target.value);
                                    }
                                }}
                            />
                            <button className="btn_sm6" style={{height:'28px', padding:'0 10px'}} onClick={()=>{
                                searchTemplate();
                            }}>
                                조회하기
                            </button>
                        </span>
                    </div>
                </div>
                <div className="option_bar" style={{display : "flex"}}>
                    <button
                        className="btn_sm4"
                        style={{marginRight: "10px", border: "1px solid #0096c6", background:"#0096c6", color:"#fff"}}
                        onClick={()=>{
                            if(checkCheckBox()){
                                setCallbackFunc(()=>reqInspection)
                                setSimpleText('선택한 템플릿을 검수요청 하시겠습니까?')
                                setSimpleTextModal(true)
                            }else{
                                setCallbackFunc(()=>simpleModalClose)
                                setSimpleText('검수 요청할 템플릿을 선택해주세요.')
                                setSimpleTextModal(true)
                            }                            
                        }}
                    >
                        선택 템플릿 검수요청
                    </button>
                    <button
                        className="btn_sm4"
                        style={{marginRight: "10px", border: "1px solid #d2001a", background:"#d2001a", color:"#fff"}}
                        onClick={()=>{
                            if(checkCheckBox()){
                                setCallbackFunc(()=>reqDelete)
                                setSimpleText('선택한 템플릿을 삭제 하시겠습니까?')
                                setSimpleTextModal(true)
                            }else{
                                setCallbackFunc(()=>simpleModalClose)
                                setSimpleText('삭제할 템플릿을 선택해주세요.')
                                setSimpleTextModal(true)
                            }    
                        }}
                    >
                        선택 템플릿 삭제
                    </button>
                    <button
                        className="btn_sm4"
                        style={{marginRight: "10px"}}
                        onClick={()=>{
                            if(checkCheckBox()){
                                setCallbackFunc(()=>reqDownload)
                                setSimpleText('선택한 템플릿을 다운로드 하시겠습니까?')
                                setSimpleTextModal(true)
                            }else{
                                setCallbackFunc(()=>simpleModalClose)
                                setSimpleText('다운로드할 템플릿을 선택해주세요.')
                                setSimpleTextModal(true)
                            }   
                        }}
                    >
                        선택 템플릿 다운로드
                    </button>
                    <button className="btn_sm6" style={{marginLeft:"auto"}} onClick={()=>setModal(true)}>
                        신규 등록
                    </button>
                </div>
                {(()=>{
                    if(templateList){
                        return(
                            <>
                                <table className="table_mypageArt">
                                    <colgroup>
                                        <col width="40px" />
                                        <col width="140px" />
                                        <col width="180px" />
                                        <col width="140px" />
                                        <col width="*" />
                                        <col width="80px" />
                                        <col width="80px" />
                                        <col width="80px" />
                                        <col width="100px" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    checked={                                        
                                                        handleCheckList.find(el => el.checkedProp === false) ? false : true
                                                    }
                                                    
                                                    onChange={(e)=>{
                                                        setHandleCheckList(handleCheckList.map(el => {
                                                            if(!e.target.checked){
                                                                el.checkedProp = false;
                                                            }else{
                                                                el.checkedProp = true;
                                                            }
                                                            return el
                                                        }))
                                                    }}
                                                />
                                            </th>
                                            <th>발신프로필</th>
                                            <th>템플릿코드</th>
                                            <th>템플릿명</th>
                                            <th>메시지내용</th>
                                            <th>버튼정보</th>
                                            <th>검수상태</th>
                                            <th>템플릿상태</th>
                                            <th>상세</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(()=>{
                                            if(typeof(templateList) !== 'string'){
                                                return templateList.map((el,idx) => {
                                                    return <TemplateRow key={idx} idx={idx} selectedATProfile={selectedATProfile} data={el} userId={userId} senderKey={senderKey} handleCheckList={handleCheckList} setHandleCheckList={setHandleCheckList} modal02={modal02} setModal02={setModal02} setModifyData={setModifyData} setRejModal={setRejectionReasonModal} setReasonData={setReasonData}/>
                                                })
                                            }else{
                                                return <tr><td colSpan={9}>{templateList}</td></tr>
                                            }
                                        })()}
                                    </tbody>
                                </table>
                                <div className="tt_blank10"></div>
                            </>
                        )
                    }else return <Loading />
                })()}
                {simpleTextModal ? <SimpleModal simpleTextModal={simpleTextModal} setSimpleTextModal={setSimpleTextModal} callbackFunc={callbackFunc}>{simpleText}</SimpleModal> : null}
                {rejectionReasonModal ? <RejectionReasonModal rejectionReasonModal={rejectionReasonModal} setRejectionReasonModal={setRejectionReasonModal} reasonData={reasonData}/> : null}
            </div>
        )
    }else return <Loading />
}

const SimpleModal = (props) => {
    const {children, simpleTextModal, setSimpleTextModal, callbackFunc} = props;
    return(
        <div className="pop_set_c" style={simpleTextModal ? {display:'block'} : {display:'none'}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page">
                        <div className="ppheader">
                            <div className="htit">알림</div>
                            <div className="rclose" onClick={()=>{setSimpleTextModal(false)}}><img src={'/images/icon_pop_close.png'} alt="닫기"/></div>
                        </div>
                        <div>
                            <div className="s_u_p_tit">{children}</div>
                            <div className="s_u_p_btn">
                                <button
                                    className="login_2"
                                    onClick={()=>{
                                        callbackFunc();
                                        setSimpleTextModal(false);
                                    }}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const RejectionReasonModal = (props) => {
    const {rejectionReasonModal, setRejectionReasonModal, reasonData} = props;
    return(
        <div className="pop_set_p" style={rejectionReasonModal ? {display:'block'} : {display:'none'}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page" style={{width:'900px'}}>
                        <div className="ppheader">
                            <div className="htit">템플릿 반려사유 확인</div>
                            <div className="rclose" onClick={()=>{setRejectionReasonModal(false)}}><img src={'/images/icon_pop_close.png'} alt="닫기"/></div>
                        </div>
                        <div>
                            <table className="tt_tableA">
                              <colgroup>
                                <col width="100px" style={{textAlign:"center"}}/>
                                <col width="80px" style={{textAlign:"center"}}/>
                                <col width="*"/>
                              </colgroup>
                              <thead>
                                <tr>
                                  <th>생성일</th>
                                  <th>작성자</th>
                                  <th>내용</th>
                                </tr>
                              </thead>
                              <tbody>
                                {reasonData.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.createdAt}</td>
                                    <td>{item.userName}</td>
                                    <td><textarea className="textarea_disabled" disabled={true}>{item.content}</textarea></td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            <div className="button_area_center">
                                <button
                                    className="btn_sm6"
                                    onClick={()=>{
                                        setRejectionReasonModal(false);
                                    }}
                                >
                                    닫기
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const TemplateRow = ({data : el, selectedATProfile,userId, senderKey, handleCheckList, setHandleCheckList, idx, setModal02, setModifyData, setRejModal, setReasonData}) => {
    const inspStatusText = {REG:"등록", REQ:"심사요청", APR:"승인", REJ: "반려"}
    const statusText = {S:"중단", A:"정상", R:"대기(발송전)"}
    const [templateDetail, setTemplateDetail] = useState()

    useEffect(()=>{
        if(userId && senderKey && el.templtCode){
            getATTemplateDetail({userId: userId, senderKey: senderKey, templtCode : el.templtCode}).then(result => {
                if(result.data.data){
                    setTemplateDetail(result.data.data)   
                }else{
                    setTemplateDetail(508)
                }
            })
        }
    },[])

    if(templateDetail === 508){
        return null
    }

    if(templateDetail){
        return(
            <tr>
                <td>
                    <input
                        type="checkbox"
                        checked={handleCheckList[idx].checkedProp}
                        onChange={(e)=>{
                            setHandleCheckList(handleCheckList.map((el, _idx)=>{
                                if(idx == _idx){el.checkedProp = e.target.checked}
                                return el;
                            }))
                        }}
                    />
                </td>
                <td>{selectedATProfile}</td>
                <td>{templateDetail.templtCode}</td>
                <td>{templateDetail.templtName}</td>
                <td>{templateDetail.templtContent}</td>
                <td>{templateDetail.buttons && templateDetail.buttons.length}개</td>
                <td>
                {inspStatusText[templateDetail.inspStatus]}
                {
                    inspStatusText[templateDetail.inspStatus] === "반려" ? (<button className="btn_sm9" onClick={() => {setReasonData(templateDetail.comments); setRejModal(true);}}>사유확인</button>) : ""
                }
                </td>
                <td>{statusText[templateDetail.status]}</td>
                <td>
                    <button
                        className="btn_sm7"
                        onClick={()=>{
                            setModifyData({...templateDetail, selectedATProfile: selectedATProfile, userId: userId, senderKey: senderKey})
                            setModal02(true);
                        }}
                    >상세조회</button>
                </td>
            </tr>
        )
    }else return <tr><td colSpan={9} style={{background:"#fff"}}><Loading tableLoading={true}/></td></tr>
}

export default ServiceConsoleTab_templt;