import React, { useState, useEffect, useRef } from 'react';
import Loading from '../../../../Util/Loading';
import { getSubAccountDetail, reissueSecretKey } from '../../../../Util/reqApi';

const SubAccountDetail = (props) => {
    const {
        subAccountDetail,
        setSubAccountDetail,
        subAccountDetailData,
    } = props;

    const [subAccountData, setSubAccountData] = useState();

    const copyText = useRef();

    useEffect(()=>{
        getSubAccountDetail(subAccountDetailData).then(result=>setSubAccountData(result.subAccount))
    },[])

    const modalClose = () => {
        setSubAccountDetail(false);
        setSubAccountData();
    }

    if(subAccountData){
        return(
            <div id="" className="pop_set_p" style={subAccountDetail ? {display:"block"} : {display:"none"}}>
                <div className="pop_section">
                    <div className="parea">
                        <div className="page">
                            <div className="ppheader">
                                <div className="htit">상세조회</div>
                                <div className="rclose" onClick={modalClose}><img src='/images/icon_pop_close.png' alt="닫기"/></div>
                            </div>
                            <div className="p_table_section">
                                <table className="table_mypageBp">
                                    <colgroup>
                                        <col width="120px" />
                                        <col width="*%" />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td>Sub Account</td>
                                            <td>
                                                {subAccountData.subject}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Secret Key</td>
                                            <td>
                                                <input
                                                    type="text"
                                                    style={{width:"calc(90% - 170px)"}}
                                                    value={`${subAccountData.secretKey}`}
                                                    ref={copyText}
                                                    readOnly
                                                />
                                                <button
                                                    className="ip_color4"
                                                    style={{width:"80px"}}
                                                    onClick={()=>{                         
                                                        copyText.current.select();                               
                                                        document.execCommand("copy");
                                                        alert('복사 되었습니다.')
                                                    }}
                                                >
                                                    복사
                                                </button>
                                                <button
                                                    className="ip_color1"
                                                    style={{width:"80px"}}
                                                    onClick={()=>{
                                                        reissueSecretKey(subAccountData.accountNo).then(()=>{
                                                            getSubAccountDetail(subAccountDetailData).then(result=>{
                                                                setSubAccountData(result.subAccount)
                                                                alert('재발급 되었습니다.')
                                                            });
                                                        })
                                                    }}
                                                >
                                                    재발급
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="s_u_padding20"></div>
                            </div>
                            <div className="nt_btn_area_p">
                                <button className="btn_sm4" onClick={modalClose}>확인</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else{
        return(
            <div id="" className="pop_set_p" style={subAccountDetail ? {display:"block"} : {display:"none"}}>
                <Loading />       
            </div>
        )
    }
}

export default SubAccountDetail;