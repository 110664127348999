import React from 'react';
import { t } from "i18next";
import { comma } from '../../../../Util/comma';
import { useNavigate } from 'react-router-dom';

const PricePanel = ({data : {apiData, slaveList, discountPrice, svcStatus, login, setSvcApplyModal}}) => {
    let navigate = useNavigate()
    return (
        <>
            <div className="tit">{t('service.price_infor')}</div>
                {
                    slaveList.filter(el => {
                        return el.showPrice
                    }).length === 0 &&
                    apiData && apiData.showPrice &&
                    <div className="p_info_box">
                        <div className="p_name" dangerouslySetInnerHTML={{__html:apiData[t('apiDetail.name')]}} />
                        <div className="p_price">
                            {
                                !discountPrice || discountPrice?.regularPrice === discountPrice?.discountPrice
                                ?
                                    <div className="pr_2">
                                        {comma(apiData.defaultPrice)}{t('currency')} / {t('case')}
                                    </div>
                                :
                                    <>
                                        <div className="pr_1"><span>{comma(discountPrice?.regularPrice)}{t('currency')} / {t('case')}</span></div>
                                        <div className="pr_2">
                                            {comma(discountPrice?.discountPrice)}{t('currency')} / {t('case')}
                                        </div>
                                    </>
                            }
                        </div>
                        {
                            Number(apiData.basicPrice) !== 0
                            ?
                                <div className="p_price">
                                    <div className="pr_2">
                                        {comma(apiData.basicPrice)}{t('currency')} / {t('month')}
                                    </div>
                                </div>
                            : null
                        }
                    </div>
                }
                {
                    slaveList.map(slaveApi => {
                        if(slaveApi.showPrice){
                            return(
                                <div className="p_info_box" key={slaveApi.svcNo}>
                                    <div className="p_name" dangerouslySetInnerHTML={{__html:slaveApi[t('apiDetail.name')]}} />
                                    <div className="p_price">
                                        {
                                            slaveApi.regularPrice === slaveApi.discountPrice
                                            ?
                                                <div className="pr_2">
                                                    {comma(slaveApi.regularPrice)}{t('currency')} / {t('case')}
                                                </div>
                                            :
                                                <>
                                                    <div className="pr_1"><span>{comma(slaveApi.regularPrice)}{t('currency')} / {t('case')}</span></div>
                                                    <div className="pr_2">
                                                        {comma(slaveApi.discountPrice)}{t('currency')} / {t('case')}
                                                    </div>
                                                </>
                                        }
                                    </div>
                                    {
                                        Number(slaveApi.basicPrice) !== 0
                                        ?
                                            <div className="p_price">
                                                <div className="pr_2">
                                                    {comma(slaveApi.basicPrice)}{t('currency')} / {t('month')}
                                                </div>
                                            </div>
                                        : null
                                    }
                                    </div>
                            )
                        }else return null
                    })
                }
            <div className="script Rtt">{t('VAT_excluded')}</div>
        </>
    );
};

export default PricePanel;