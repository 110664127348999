import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import ModalTerm from '../Modal/modalTerm';
import { getApiCategorys, getFooterData, getMainBanner, getSiteCategory } from '../../Util/reqApi';
import Loading from '../../Util/Loading';
import { t } from 'i18next';
import _ from 'lodash';
import { useTopButton } from '../../shared/topButtonContext';


function Footer(){
    const [siteMenus, setSiteMenus] = useState();
    const [familySite, setFamilySite] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [apiCategory, setApiCategory] = useState();
    const [subBn, setSubBn] = useState();
    const [modalType, setModalType] = useState();
    const [footerData, setFooterData] = useState();

    const {setFocus} = useTopButton();
    const button1FocusRef = useRef(null);
    const button2FocusRef = useRef(null);
    const childRef = useRef(null);
    
    const getUterm = async (type) => {
        setModalContent();
        setModalType(type)
        const result = await axios.post(process.env.REACT_APP_API_SERVER_URL+`/api/main/getTerm?langNo=${t('langNo')}&pageNumber=0&pageSize=9999&type=${type}`);
        setModalContent(result.data.data);
    }

    useEffect(()=>{
        getSiteCategory().then(result => {setSiteMenus(result)});
        getApiCategorys().then(result => {setApiCategory(result)});
        getMainBanner('S',t('langNo')).then(result => setSubBn(result));
        getFooterData(t('langNo')).then(result => {
            setFooterData({...result, affiliate : result?.affiliate.replaceAll('&quot;','"')})
        })
    },[])

    const handleClickModalTermOpen = (termletter) => {
        getUterm(termletter).then(() => {
            setModalOpen(true);
            if(childRef.current){
                childRef.current.focusTitle();
            }
        })
    }

    const handleClickModalTermClose = () => {
        if(modalType === 'U' && button1FocusRef && button1FocusRef.current){
            button1FocusRef.current.focus();
        }
        else if(modalType === 'P' && button2FocusRef && button2FocusRef.current){
            button2FocusRef.current.focus();
        }
        setModalOpen(false);
    }
 
    if(footerData && subBn && siteMenus){
        return(
            <>
                <div className="basic_section_2">
                    <div className="info_area">
                        <table className="table_type1">
                            <caption>{t('footer.caption_title')}</caption>
                            <colgroup>
                                <col width="30%" />
                                <col width="70%" />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th scope="row"><img src= "/images/icon_talk.png" alt=""/>{t('footer.infor01_title')}</th>
                                    <td>{t('footer.infor01_desc')}</td>
                                </tr>
                                <tr>
                                    <th scope="row"><img src= "/images/icon_phone.png" alt=""/>{t('footer.infor02_title')}</th>
                                    <td>{t('footer.infor02_desc')}</td>
                                </tr>
                                <tr>
                                    <th scope="row"><img src= "/images/icon_email.png" alt=""/>{t('footer.infor03_title')}</th>
                                    <td>{t('footer.infor03_desc')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <footer className="footer">
                    <a href="#" className="topmove" onClick={(e)=>{ e.preventDefault(); window.scrollTo(0,0); setFocus();}}>
                        <img src={`/images/icon_topmove.png`} alt=""/>
                        <p>{t('footer.top_button')}</p>
                    </a>
                    <div className="site_map">
                        <ul>
                            <li>
                                <div className="kate_link">
                                    <h2 className="kate_name" lang="en">{siteMenus[0][t('cateName')]}</h2>
                                    <ul>
                                        {
                                            apiCategory
                                            ?   apiCategory.filter(el => el.svcMainCategory.mainCateNo !== 5)
                                                .map((el,idx)=> <li key={idx}><Link to="/service" state={{url: `service_${el.cateNo}`, key: _.uniqueId()}}>{el.cateName}</Link></li>)
                                            : null
                                        }
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="kate_link">
                                    <h2 className="kate_name" lang="en">{siteMenus[1][t('cateName')]}</h2>
                                    <ul>
                                        {
                                            apiCategory
                                            ?   apiCategory.filter(el => el.svcMainCategory.mainCateNo === 5)
                                                .map((el,idx)=> <li key={idx}><Link to="/marketplace" state={{idx, key: _.uniqueId()}}>{el.cateName}</Link></li>)
                                            : null
                                        }
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="kate_link">
                                    <h2 className="kate_name" lang="en">{siteMenus[2][t('cateName')]}</h2>
                                    <ul>
                                        <li><a href="/partnership">{t('footer.siteMap03_01')}</a></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="kate_link">
                                    <h2 className="kate_name" lang="en">{siteMenus[3][t('cateName')]}</h2>
                                    <ul>
                                        <li><a href="/support/notice?cate=0&page=0">{t('footer.siteMap05_01')}</a></li>
                                        <li><a href="/support/faq">{t('footer.siteMap05_02')}</a></li>
                                        <li><a href="/support/requests">{t('footer.siteMap05_03')}</a></li>
                                        <li><a href="/support/apiAll">{t('footer.siteMap05_04')}</a></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="kate_link">
                                    <h2 className="kate_name" lang="en"><a href="/aboutus" style={{color:"#fff"}}>{siteMenus[4][t('cateName')]}</a></h2>
                                    <ul>
{/*                                        <li><a href="/aboutus">{t('footer.siteMap04_01')}</li>*/}
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div className="kate_link">
                                    <div className="support"><a href="/support/requests" style={{color:"#fff"}}><img src={`/images/icon_support_1.png`} alt=""/>{t('footer.siteMapInfor01')}</a></div>
                                    <div className="support"><a href="/support/requests" style={{color:"#fff"}}><img src={`/images/icon_support_2.png`} alt=""/>{t('footer.siteMapInfor02')}</a></div>
                                    <div className="support"><a href={`tel:${t('footer.siteMapInfor03')}`} style={{color:"#fff"}}><img src={`/images/icon_support_3.png`} alt=""/>{t('footer.siteMapInfor03')}</a></div>
                                    <div className="support"><a href="https://blog.naver.com/apiplex" style={{color:"#fff"}}>
                                        <img src={`/images/logo_blog_naver_grayscale.png`} style={{width:"20px", height: "20px"}} alt=""/>
                                        {t('footer.siteMapInfor04')}
                                    </a></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="company_info">
                        <div className="c_section">
                            <h1><img src={`/images/logo_cjolive.png`} alt="CJ OliveNetworks"/></h1>
                            <div className="btn_area">
                                <span>
                                    <a href="#" ref={button1FocusRef} onClick={(e) => {e.preventDefault(); handleClickModalTermOpen('U');}}>{t('footer.term01')}</a>
                                </span>ㅣ
                                <span className="point">
                                    <a href="#" ref={button2FocusRef} onClick={(e) => {e.preventDefault(); handleClickModalTermOpen('P');}}>{t('footer.term02')}</a>
                                </span>ㅣ
                                <span>
                                    <Link to="/support/apiAll">{t('footer.term03')}</Link>
                                </span>
                            </div>
                            <div className="c_info_area" dangerouslySetInnerHTML={{__html : footerData.footerText.replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}}>                        
                            </div>
                            <div className="cjWebPolicy" onClick={()=>{familySite ? setFamilySite(false) : setFamilySite(true)}}>
                                <h2><a href="#" onClick={(e) => {e.preventDefault()}}>{t('footer.groupList')}</a></h2>
                                <ul className="companyGroup" style={familySite ? {display:"block"} : {display:"none"}}>
                                    {
                                        JSON.parse(footerData.affiliate).map((el,idx)=>{
                                            if(el.type == 'G'){
                                                return <li key={idx}><p className="title">{el.linkName}</p></li>
                                            }else if(el.type == 'L'){
                                                return <li key={idx}><a className="m-target" href={el.url} target="_blank" title={t('footer.newWindow')}>{el.linkName}</a></li>
                                            }else{
                                                return null;
                                            }
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                {<ModalTerm ref={childRef} modalOpen={modalOpen} setModalClose={handleClickModalTermClose} modalContent={modalContent} type={modalType}/>}                
            </>
        )
    }else{
        return <Loading />
    }
}

export default Footer;