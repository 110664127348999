import React, {useEffect, useState} from 'react';
import { t } from 'i18next';
import { getPopupNotice } from '../../Util/reqApi';

const MainNotice = () => {
    const [noticePop, setNoticePop] = useState();
    const [showModal, setShowModal] = useState(false);
    const [checkVisted, setCheckVisted] = useState(false);

    const HAS_VISITED_BEFORE = localStorage.getItem('hasVisitedBefore');

    useEffect(()=>{
        getPopupNotice(t('langNo')).then(result => {setNoticePop(result[0])})
    },[])

    useEffect(() => {
        const handleShowModal = () => {
            if (HAS_VISITED_BEFORE && HAS_VISITED_BEFORE > new Date()) {
            return;
        }
    
        if (!HAS_VISITED_BEFORE || HAS_VISITED_BEFORE < new Date()){
            setShowModal(true);
        }
    };
    
    handleShowModal();
    }, [HAS_VISITED_BEFORE]);

    const handleClose = () => {
        if(checkVisted){
            let expires = new Date();
            expires = expires.setHours(expires.getHours() + 24);
            localStorage.setItem('hasVisitedBefore', expires);
        }
        setShowModal(false)
    };

    if(showModal && noticePop){
        return(
            <div id="NoticePopA" className="pop_set" style={showModal ? {display:"block"} : {display:'none'}}>
                <div className="pop_section">
                    <div className="parea">
                        <div className="page">
                            <div className="ppheader">
                                <div className="htit">{t('modal.noticePop_title')}</div>
                                <div id="NoticePopA" className="rclose" onClick={handleClose}><img src='/images/icon_pop_close.png' alt={t('close')}/></div>
                            </div>
                            <div className="title">{noticePop.subject}</div>
                            <div className="text" dangerouslySetInnerHTML={{__html:noticePop.content.replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}}/>
                            <div className="btn_area_day">
                                <div className="view_check">
                                    <input type="checkbox" id="UNII1" name="UNII1" checked={checkVisted} onChange={(e)=>{setCheckVisted(e.target.checked)}}/>
                                    <label htmlFor="UNII1">{t('modal.checkViewMsg')}</label>
                                </div>
                                <div className="r_close_btn" onClick={handleClose}>{t('close')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }else return null;
}

export default MainNotice;