import React, {useState, useEffect} from 'react';
import Loading from '../../../../Util/Loading';
import { getApiDetail, getCurrentSvcTerm, getMySvc, serviceApplication } from '../../../../Util/reqApi';

const SvcApply = (props) => {
    const {svcNo, svcApplyModal, setSvcApplyModal, setSvcStatus, setMySvc, setSvcPermitModal} = props;
    const [apiDetail,setApiDetail] = useState({});
    const [svcTerm, setSveTerm] = useState();
    const [userChk, setUserChk] = useState(false);

    useEffect(()=>{ 
        getApiDetail(svcNo).then(result => {
            if(result.productType === 2){
                setApiDetail({...result, serviceApplicationForm : result.serviceApplicationForm.replaceAll('&quot;','"')})
            }else{
                setApiDetail(result)
            }

            if(result.svcTerm){
                setSveTerm(result.svcTerm)
            }else{
                getCurrentSvcTerm(svcNo).then(result => setSveTerm(result))
            }
        })
    },[svcNo])

    const handleApply = () => {
        if(userChk){ // 약관 동의 체크
            let applicationJson = apiDetail.productType == 2 || apiDetail.slaveSvcList.length !== 0
            ? [ // apiDetail.productType이 2거나, slaveSvcList가 없을 경우 applicationJson는
                {
                    svcNo: apiDetail.svcNo,
                    callback : document.querySelector(`#label_callback`).value,
                    ip : document.querySelector(`#label_ip`).value,
                    data : 
                        apiDetail.serviceApplicationForm
                        ?
                            JSON.parse(apiDetail.serviceApplicationForm).map((el,idx)=>{
                                if(el.user_editable === true || el.user_editable === "true"){
                                    return(
                                        {
                                            key : el.key,
                                            type : el.type,
                                            value : document.querySelector(`#label_${(el.key).replace(/ /g,"")}_${el.label.toLowerCase().replace(/ /g,"").replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi,"")}`)?.value,
                                            user_editable : true
                                        }
                                    )
                                }else{
                                    return(
                                        {
                                            key : el.key,
                                            type : 'text',
                                            value: el.value,
                                            user_editable : false
                                        }
                                    )
                                }
                            })
                        : []
                }
            ]
            : [ // apiDetail.productType이 2가 아니거나, slaveSvcList가 있는 경우 applicationJson는
                {
                    svcNo: apiDetail.svcNo,
                    callback : document.querySelector(`#label_callback`).value,
                    ip : document.querySelector(`#label_ip`).value,
                }
            ]
        
            apiDetail.slaveSvcList.length > 0 &&
            apiDetail.slaveSvcList.map((slaveSvc,idx)=>{
                return(
                    applicationJson && applicationJson.push({
                        svcNo : slaveSvc.svcNo,
                        data : 
                            slaveSvc?.serviceApplicationForm
                            ? JSON.parse(slaveSvc.serviceApplicationForm).map((el,idx)=>{
                                if(el.user_editable){
                                    return(
                                        {
                                            key : el.key,
                                            type : el.type,
                                            value : document.querySelector(`#label_${(el.key).replace(/ /g,"")}_${el.label.toLowerCase().replace(/ /g,"").replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi,"")}`)?.value,
                                            user_editable : true
                                        }
                                    )
                                }else{
                                    return(
                                        {
                                            key : el.key,
                                            type : 'text',
                                            value: el.value || '',
                                            user_editable : false
                                        }
                                    )
                                }
                            })
                            : null
                        })
                    )
                })
            serviceApplication(
                apiDetail.slaveSvcList.length === 0 ? apiDetail.productType : 2,
                (applicationJson) || null,
                ).then(result => {
                if(result === 'ok'){
                    setSvcStatus(apiDetail.productType == 2 ? 2 : 1)
                    getMySvc().then(result => setMySvc(result.filter(el => {
                        return el.mainCateNo !== 5
                    })));
                    setApiDetail();
                    setSvcApplyModal(false);
                    setSvcPermitModal(true);
                }else{
                    alert(result)
                }
            })
        }else{
            alert('서비스 이용약관에 동의해주세요.')
        }
    }

    if(svcTerm && apiDetail){
    return(
        <div id="" className="pop_set_b" style={svcApplyModal ? {display:'block'} : {display:'none'}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page">
                        <div className="ppheader">
                            <div className="htit">서비스 이용신청</div>
                            <div className="rclose" onClick={()=>{setSvcApplyModal(false)}}><img src='/images/icon_pop_close.png' alt="닫기"/></div>
                        </div>
                        <div className="">
                            <div className="s_u_p_tit">서비스 이용을 위하여 아래 약관에 동의하여 주세요.</div>
                            <div className="s_u_p_p_box" dangerouslySetInnerHTML={{__html:svcTerm.replaceAll('src="', `src="`+process.env.REACT_APP_API_SERVER_URL)}}/>
                            <div className="s_u_padding10"></div>
                            <div className="s_u_p_check">
                                <input type="checkbox" id="sup1" name="sup" checked={userChk} onChange={()=>{ 
                                    userChk ? setUserChk(false) : setUserChk(true)}
                                    }/><label htmlFor="sup1">서비스 이용약관을 읽고 동의합니다.</label>
                            </div>
                            <div className="s_u_padding20"></div>
                            
                            {
                                (()=>{
                                    if(apiDetail.productType === 2 || apiDetail.slaveSvcList.length !== 0){
                                        // api detail의 productType이 2거나, slaveSvcList가 있을 경우
                                        return <>
                                            {/* 공통 옵션 영역 */}
                                            <div className="s_u_p_tit1">옵션 설정</div>
                                            <div className="s_u_padding20"></div>
                                            <div className="s_u_p_tit1">공통</div>
                                            <div className="s_u_p_tit1">
                                                <label htmlFor="sup2" style={{marginRight:"20px", display: "inline-block",minWidth:"100px"}}><span></span>callBack URL</label>
                                                <input type="text" id={`label_callback`} />
                                            </div>
                                            <div className="s_u_p_tit1">
                                                <label htmlFor="sup2" style={{marginRight:"20px", display: "inline-block",minWidth:"100px"}}><span></span>IP</label>
                                                <input type="text" id={`label_ip`} />
                                            </div>
                                            <hr style={{border:"none", borderTop: "1px solid #ddd", margin:"20px 0"}}/>
                                            {/* 공통 옵션 영역 끝*/}

                                            {/* 마스터 옵션 영역 - 슬레이브가 없는 경우 에만 노출 됨*/}
                                            <>
                                                {
                                                    apiDetail.slaveSvcList.length === 0 ?
                                                    <>
                                                        {
                                                            JSON.parse(apiDetail.serviceApplicationForm).filter(el => {return el.user_editable === true || el.user_editable === "true"}) && JSON.parse(apiDetail.serviceApplicationForm).filter(el => {return el.user_editable === true || el.user_editable === "true"}).length > 0
                                                            ? <div className="s_u_p_tit1">{apiDetail.nameKr}</div>
                                                            : null
                                                        }
                                                        {
                                                            JSON.parse(apiDetail.serviceApplicationForm).filter(el => {return el.user_editable === true || el.user_editable === "true"}).map((el,idx)=>{
                                                                return(
                                                                    <div className="s_u_p_tit1" key={idx}>
                                                                        <label htmlFor="sup2" style={{marginRight:"20px", display: "inline-block",minWidth:"100px"}}><span></span>{el.label}</label>
                                                                        <input type={el.type} id={`label_${(el.key).replace(/ /g,"")}_${el.label.toLowerCase().replace(/ /g,"").replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi,"")}`} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            JSON.parse(apiDetail.serviceApplicationForm).filter(el => {return el.user_editable === true || el.user_editable === "true"}) && JSON.parse(apiDetail.serviceApplicationForm).filter(el => {return el.user_editable === true || el.user_editable === "true"}).length > 0
                                                            ? <hr style={{border:"none", borderTop: "1px solid #ddd", margin:"20px 0"}}/>
                                                            : null
                                                        }
                                                    </>
                                                    : null
                                                }
                                            </>
                                            {/* 마스터 옵션 영역 끝 - 슬레이브가 없는 경우 에만 노출 됨*/}

                                            {/* 슬레이브 옵션 영역 */}
                                            {
                                                apiDetail.slaveSvcList && apiDetail.slaveSvcList.length > 0
                                                ?
                                                apiDetail.slaveSvcList.map((slaveSvc,idx)=>{
                                                    return(
                                                        slaveSvc.serviceApplicationForm
                                                        ?
                                                        <>
                                                            {
                                                                JSON.parse(slaveSvc.serviceApplicationForm).filter(el => {return el.user_editable === true || el.user_editable === "true"}) && JSON.parse(slaveSvc.serviceApplicationForm).filter(el => {return el.user_editable === true || el.user_editable === "true"}).length > 0
                                                                ? <div className="s_u_p_tit1">{slaveSvc.nameKr}</div>
                                                                : null
                                                            }
                                                            {JSON.parse(slaveSvc.serviceApplicationForm).filter(el => {return el.user_editable === true || el.user_editable === "true"}).map((el,idx2)=>{
                                                                return(
                                                                    <div className="s_u_p_tit1" key={idx2}>
                                                                        <label htmlFor="sup2" style={{marginRight:"20px", display: "inline-block",minWidth:"100px"}}><span></span>{el.label}</label>
                                                                        <input type={el.type} id={`label_${(el.key).replace(/ /g,"")}_${el.label.toLowerCase().replace(/ /g,"").replace(/[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi,"")}`} />
                                                                    </div>
                                                                )
                                                            })}
                                                            {
                                                                JSON.parse(slaveSvc.serviceApplicationForm).filter(el => {return el.user_editable === true || el.user_editable === "true"}) && JSON.parse(slaveSvc.serviceApplicationForm).filter(el => {return el.user_editable === true || el.user_editable === "true"}).length > 0
                                                                ? <hr style={{border:"none", borderTop: "1px solid #ddd", margin:"20px 0"}}/>
                                                                : null
                                                            }
                                                        </>
                                                        : null
                                                    )
                                                })
                                                : null
                                            // 슬레이브 옵션 영역 끝
                                            }
                                        </>
                                    }else{
                                        return (
                                            // 마스터 타입이 1이고, 슬레이브도 없는 경우 
                                            <>
                                                <div className="s_u_p_tit1">옵션 설정(선택)</div>
                                                <div className="s_u_padding20"></div>
                                                <div className="s_u_p_tit1">
                                                    <label htmlFor="sup2" style={{marginRight:"20px", display: "inline-block",minWidth:"100px"}}><span></span>callBack URL</label>
                                                    <input type="text" id={`label_callback`} />
                                                </div>
                                                <div className="s_u_p_tit1">
                                                    <label htmlFor="sup2" style={{marginRight:"20px", display: "inline-block",minWidth:"100px"}}><span></span>IP</label>
                                                    <input type="text" id={`label_ip`} />
                                                </div>
                                            </>
                                        )
                                    }
                                })()
                            }

                            <div className="s_u_p_tit2">서비스 이용 약관의 주요 내역</div>
                            <div className="s_u_p_tt1">
                            - 고객님께 제공되는 저작권 및 소유권 등 일체의 권리는 제공사에 귀속됩니다. <br/>
                            - 고객님께서 본 서비스를 이용하여 획득한 지역정보를 복제하여 보관하거나 제 3자에게 유출해서는 안되며 보관 또는 유출하여 
                            발생하는일체의 손해에 대하여 배상하여야 합니다. <br/>
                            - 고객님께서 본 서비스를 이용하여 직접 제작한 프로그램의 저작권은 고객님께 귀속됩니다. <br/>
                            - 유해 사업자 및 악의적인 사용자에 대해서는 이용이 제한되거나 중단될 수 있습니다.<br/>
                            </div>
                            <div className="s_u_padding20"></div>
                        </div>
                        <div className="nt_btn_area_p">
                            <button className="btn_sm4" onClick={()=>{setSvcApplyModal(false)}}>닫기</button>
                            <button className="btn_sm6" onClick={handleApply}>확인</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    }else{
        return (
            <div id="" className="pop_set_b" style={svcApplyModal ? {display:'block'} : {display:'none'}}>
                <div className="pop_section">
                    <div className="parea">
                        <div className="page">
                            <Loading />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SvcApply;