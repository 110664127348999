import React from "react";
import { getNewLine } from "../../../Util/getNewLine";
import BasicButton from "./BasicButton";

const SubHeader = (props) => {
    const {subTitle, subDesc, bgImgSrc, buttons, noti_text } = props;
    return(
        <div className="sub_banner">
            {
                <div className="banner_img"><img src={bgImgSrc} alt=""/></div>
            }
            <div className={`banner_text ${noti_text ? 'noti_text' : ''}`}>
                <div className="text_area">
                    <div className="title_1">{subTitle}</div>
                    <div className="title_2">{getNewLine(subDesc)}</div>
                    {
                        buttons
                            ?
                                buttons.map((button, idx) =>
                                    <div className="btnArea" key={idx}>
                                        <BasicButton
                                            text = {button.text}
                                            cssType = {button.cssType ? button.cssType : ''}
                                            innerLink = {button.innerLink}
                                        />
                                    </div>
                                )
                            :
                                null
                    }
                </div>
            </div>
        </div>
    )
}

export default SubHeader;