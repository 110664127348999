import React from "react";
import {Link, useHistory} from "react-router-dom";

const SearchSuggestionBlock = ({el, mo}) => {

  const handleLinkClick = (e) => {
      if (window.location.href.includes(el.masterSvcNo)) {
          e.preventDefault();
          window.location.reload(); // 페이지 새로고침
      }
  };

  const parseTag = (tags) => {
    if(!tags) return '';

    const res = tags.split(',').map( (tag) => {
      return '#' + tag.trim();
    });

    return res.join(' ');
  }


  return (
      !mo?(
        <Link to={`/service/apiservices/${el.masterSvcNo}`} onClick={handleLinkClick}>
          <div className='searchSuggestionBlock'>
            <img className='searchSuggestionIcon' src={`/uploads/svcDetail/${el.iconFileName}`}></img>
            <div>
              <p className='searchSuggestionBlockTitle'>{localStorage.getItem('lan') == 'ko'? `${el.cateName} > ${el.masterApiName} > ${el.nameKr}` : `${el.cateNameEn} > ${el.masterApiNameEn} > ${el.nameEn}` }</p>
              <p className='searchSuggestionBlockTag'>{localStorage.getItem('lan') == 'ko'? (parseTag(el.tag)) : (parseTag(el.tagEn))}</p>
            </div>
          </div>
        </Link>
      )
      :(
        <Link to={`/service/apiservices/${el.masterSvcNo}`} onClick={handleLinkClick}>
          <div className='searchSuggestionBlockMo'>
            <img className='searchSuggestionIconMo' src={`/uploads/svcDetail/${el.iconFileName}`}></img>
            <div>
              <p className='searchSuggestionBlockTitleMo'>{localStorage.getItem('lan') == 'ko'? `${el.cateName} > ${el.masterApiName} > ${el.nameKr}` : `${el.cateNameEn} > ${el.masterApiNameEn} > ${el.nameEn}` }</p>
              <p className='searchSuggestionBlockTagMo'>{localStorage.getItem('lan') == 'ko'? (parseTag(el.tag)) : (parseTag(el.tagEn))}</p>
            </div>
          </div>
        </Link>
      )
  );
}

export default SearchSuggestionBlock;