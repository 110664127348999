import React, {useState, useEffect} from 'react';
import Loading from '../../Util/Loading';
import {
    getAlimProfile,
    getAlimUuidProfile,
    saveSenderProfile,
    getATProfileCategory,
    getKakaoToken,
    getUserInfor,
    recoverATProfile,
    regiATProfile
} from '../../Util/reqApi';

const ServiceConsoleTab05 = () => {
    const [modal, setModal] = useState(false);
    const [alimProfile, setAlimProfile] = useState();
    const [alimCate, setAlimCate] = useState();
    const [uuid, setUuid] = useState('');
    const [name, setName] = useState('');
    const [pNumber, setpNumber] = useState('');
    const [userId, setUserId] = useState('');
    const [plusFriend, setPlusFriend] = useState('');
    const [kakaoToken, setKakaoToken] =useState('');    
    const [simpleText, setSimpleText] = useState();
    const [simpleTextModal, setSimpleTextModal] = useState(false);
    const [lcArr, setLcArr] = useState();
    const [mcArr, setMcArr] = useState();
    const [scArr, setScArr] = useState();
    const [selectedMcArr, setSelectedMcArr] = useState();
    const [selectedScArr, setSelectedScArr] = useState();
    const [selectedLcItem, setSelectedLcItem] = useState('대분류');
    const [selectedMcItem, setSelectedMcItem] = useState('중분류');
    const [selectedScItem, setSelectedScItem] = useState('소분류');
    const [chkOne, setChkOne] = useState();
    const [profileAuth, setProfileAuth] = useState(false);

    const modalOpen = () => {setModal(true)}
    const modalClose = () => {
        setSelectedMcArr();
        setSelectedScArr();
        setPlusFriend('');
        setpNumber('');
        setKakaoToken('');
        setSelectedLcItem('대분류');
        setSelectedMcItem('중분류');
        setSelectedScItem('소분류');
        setModal(false)
    }

    const aTprofileStat = {"A" : "활성화", "S": "비활성화", "E": "삭제중", "D": "삭제"}

    const handleSearchButton = () => {
        getAlimProfile({userId : userId, uuid: uuid, name: name}).then(resultProfile => setAlimProfile(resultProfile));
    }

    useEffect(()=>{
        getUserInfor().then((result)=>{
            setUserId(result.userId);
            getAlimProfile({userId : result.userId}).then(resultProfile => setAlimProfile(resultProfile));
            getATProfileCategory({userId : result.userId}).then(resultProfileCategorys => {
                setAlimCate(resultProfileCategorys)

                let lcArr = [];
                let mcArr = [];
                let scArr = [];

                resultProfileCategorys.forEach((el,idx) => {
                    if(idx == 0 || resultProfileCategorys[idx].catName.split(',')[0] !== resultProfileCategorys[idx-1].catName.split(',')[0]){
                        lcArr.push({'catName' : el.catName.split(',')[0], 'catCode' : el.catCode.slice(0,3)})
                    }
                })
                resultProfileCategorys.forEach((el,idx) => {
                    if(idx == 0 || resultProfileCategorys[idx].catName.split(',')[1] !== resultProfileCategorys[idx-1].catName.split(',')[1]){
                        mcArr.push({'catName' : el.catName.split(',')[1], 'catCode' : el.catCode.slice(0,7)})
                    }
                })
                resultProfileCategorys.forEach((el,idx) => {
                    if(idx == 0 || resultProfileCategorys[idx].catName.split(',')[2] !== resultProfileCategorys[idx-1].catName.split(',')[2]){
                        scArr.push({'catName' : el.catName.split(',')[2], 'catCode' : el.catCode})
                    }
                })
                setLcArr(lcArr)
                setMcArr(mcArr)
                setScArr(scArr)
            })
        })
    },[])


    if(alimProfile){
        return(
            <div id="cons-2" className="tab-content current">
                <div className="tt_h2">발신프로필 관리</div>
                <div className="option_bar5">
                    {/* <div className="t_date_option_arT">
                        <input type="text" style={{width:"120px"}} />&nbsp;~&nbsp;
                        <input type="text" style={{width:"120px"}} />&nbsp;&nbsp;
                        <button className="btn_smA2">3일</button>
                        <button className="btn_smA2">주</button>
                        <button className="btn_smA2">월</button>
                    </div> */}
                    <div className="t_date_option_arT" temp={'bordertop'}> 
                        <span className="op_box">
                            <span>발신프로필 UUID </span>
                            <input type="text" style={{width:"120px"}} value={uuid} onChange={(e)=>{setUuid(e.target.value)}}/>&nbsp;&nbsp;
                        </span>
                        <span className="op_box">
                            <span>발신프로필 명 </span>
                            <input type="text" style={{width:"120px"}} value={name} onChange={(e)=>{setName(e.target.value)}}/>&nbsp;&nbsp;
                        </span>
                        {/* <span className="op_box">
                            <span>엠플레이스 ID </span>
                            <input type="text" style={{width:"120px"}} />&nbsp;&nbsp;
                        </span> */}
                        {/* <span className="op_box">
                            <span>그룹여부 </span>
                            <input type="text" style={{width:"120px"}} />&nbsp;&nbsp;
                            <button className="btn_smA1">검색</button>
                        </span> */}
                        <span className="op_box">
                            <button type="button" className="btn_sm6" onClick={handleSearchButton}>검색</button>
                        </span>
                    </div>
                </div>
                <table className="table_mypageArt">
                    <colgroup>
                        <col width="40px" />
                        {/* <col width="*" /> */}
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                        <col width="*" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th></th>
                            {/* <th>엠플레이스 ID</th> */}
                            {/* <th>그룹여부</th> */}
                            <th>발신프로필 UUID / 발신프로필 명 / 발신프로필 키</th>
                            <th>카테고리(대,중,소)</th>
                            <th>등록일</th>
                            <th>카카오톡 채널 상태 / 차단여부 / 휴면여부</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(()=>{
                            if(alimProfile.code === '508'){
                                return(
                                    <tr>
                                        <td colSpan={5}>{alimProfile.message}</td>
                                    </tr>
                                )
                            }else{
                                return alimProfile.data.list.map((el,idx) => {
                                    return(
                                        <tr key={idx+1}>
                                            <td style={{height: "100%"}} >
                                                <input
                                                    type="radio"
                                                    id="art1"
                                                    name="art"
                                                    onClick={()=>{setChkOne(idx+1)}}
                                                    checked={chkOne == (idx+1) ? true : false}
                                                /><label className="sr-only" htmlFor="art1" ></label>
                                            </td>
                                            <td>{el.uuid} / {el.name} / {el.senderKey}</td>
                                            <td>{alimCate && alimCate.find(_el => _el.catCode === el.catCode).catName}</td>
                                            <td>{el.cdate}</td>
                                            <td>{aTprofileStat[el.profileStat] || '-'} / {el.block ? "차단" : "정상"} / {el.dormant ? "휴면" : "정상"}</td>  
                                        </tr>
                                    )
                                })
                            }
                        })()}
                    </tbody>
                </table>
                <div className="tt_blank10"></div>
                <div className="pj_box_Art">
                    <button className="btn_sm4"
                        onClick={()=>{
                            if(!chkOne){alert('휴면해제 할 발신프로필을 선택해주세요.')}
                            const senderKey = alimProfile.data.list[chkOne-1].senderKey
                            if(alimProfile.data.list[chkOne-1].profileStat === 'A'){alert('프로필이 휴면상태가 아닙니다.'); return;}
                            recoverATProfile({userId, senderKey}).then(result => {
                                if(typeof(result.data) == 'string') {alert(result.data); return;}
                                else alert('휴면해제가 신청되었습니다.')
                                }
                            )
                        }}
                    >휴면 해제</button>
                    <div className="r_area">
                        {/* <button className="btn_sm6" style={{marginRight:"20px"}} onClick={modalOpen2}>+발신프로필키 입력</button> */}
                        <button className="btn_sm6" onClick={modalOpen}>신규 등록</button>
                    </div>
                </div>
                <div className="tt_blank80"></div>
                {
                    modal &&
                        <div id="" className="pop_set_p" style={modal ? {display:"block"} : {display:"none"}}>
                        <div className="pop_section">
                            <div className="parea">
                                <div className="page">
                                    <div className="ppheader">
                                        <div className="htit">발신프로필 등록</div>
                                        <div className="rclose" onClick={modalClose}><img src='/images/icon_pop_close.png' alt="닫기"/></div>
                                    </div>
                                    <div>
                                        <div className="tt_h2">발신프로필 등록</div>
                                        <div className="s_u_p_tit3">
                                            - 발신프로필을 등록하기 전에 카카오톡 채널 관리자센터에서 비즈니스인증을 받아야 합니다.<br/>
                                            - 발신프로필로 사용할 UUID(@채널검색용아이디)를 정확히 입력해 주세요. 
                                        </div>
                                        <div className="p_ct_section">
                                            <table className="tt_tableArt">
                                                <colgroup>
                                                    <col width="30%" />
                                                    <col width="*" />
                                                </colgroup>
                                                <tbody>
                                                    {/* <tr>
                                                        <th>엠플레이스ID 입력</th>
                                                        <td>
                                                            <select className="ip_size200">
                                                                <option>선택</option>
                                                                <option>선택</option>
                                                            </select>
                                                        </td>
                                                    </tr> */}
                                                    <tr>
                                                        <th>발신프로필 UUID 입력</th>
                                                        <td><input type="text" className="ip_size200" value={plusFriend} placeholder={"카카오 발신프로필 UUID"} onChange={(e)=>{setPlusFriend(e.target.value)}}/></td>
                                                    </tr>
                                                    <tr>
                                                        <th>관리자 휴대폰번호</th>
                                                        <td>
                                                            <input type="text" className="ip_size200" value={pNumber} placeholder={"인증받을 휴대폰번호(- 제외)"} onChange={(e)=>{setpNumber(e.target.value)}}/>&nbsp;
                                                            <button className={(plusFriend && pNumber)? "btn_smA1": "btn_smA1_disabled"}
                                                                    disabled={!(plusFriend && pNumber)}
                                                                    onClick={()=>{
                                                                        const isValid = /^010[0-9]{8}$/.test(pNumber);
                                                                        if(!isValid){
                                                                            setSimpleTextModal(true);
                                                                            setSimpleText("올바른 휴대폰번호를 입력해주세요.");
                                                                            return;
                                                                        }

                                                                        getKakaoToken({
                                                                            pNumber:pNumber,
                                                                            userId: userId,
                                                                            plusFriend: plusFriend
                                                                        }).then(result => {
                                                                            if(result.data.data.message){
                                                                                setSimpleTextModal(true)
                                                                                setSimpleText(result.data.data.message)
                                                                                return null
                                                                            }
                                                                            if(result.data.data.code == '200'){
                                                                                setSimpleTextModal(true)
                                                                                setSimpleText('휴대폰으로 인증번호가 발송되었습니다.')
                                                                                setProfileAuth(true)
                                                                                return null
                                                                            }
                                                                        })
                                                            }}>인증 요청</button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>인증번호</th>
                                                        <td>
                                                            <input type="text" className="ip_size200" placeholder={!profileAuth?"":"인증번호 6자리"} disabled={!profileAuth} value={kakaoToken} onChange={(e)=>{setKakaoToken(e.target.value)}}/>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>카테고리</th>
                                                        <td>
                                                            <select className="ip_size200"
                                                                onChange={(e)=>{
                                                                    const result = lcArr.find(el => el.catName === e.target.value);
                                                                    setSelectedLcItem(e.target.value)
                                                                    setSelectedScArr();
                                                                    setSelectedMcArr(mcArr.filter( el => el.catCode.slice(0,3) === result.catCode));
                                                                }}
                                                                value={selectedLcItem}
                                                            >
                                                                <option>대분류</option>
                                                                {
                                                                    lcArr && lcArr.map(el => {
                                                                        return(<option value={el.catName} key={el.catCode}>{el.catName}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                            <div className="tt_blank10"></div>
                                                            <select className="ip_size200"
                                                                onChange={(e)=>{
                                                                    const result = mcArr.find(el => el.catName === e.target.value);
                                                                    setSelectedMcItem(e.target.value)
                                                                    setSelectedScArr(scArr.filter( el => el.catCode.slice(0,7) === result.catCode ))
                                                                }}
                                                                value={selectedMcItem}
                                                            >
                                                                <option>중분류</option>
                                                                {selectedMcArr && selectedMcArr.map(el => { return(<option value={el.catName} key={el.catCode}>{el.catName}</option>)})}
                                                            </select>
                                                            <div className="tt_blank10"></div>
                                                            <select className="ip_size200"
                                                                onChange={(e)=>{setSelectedScItem(e.target.value)}}
                                                                value={selectedScItem}
                                                            >
                                                                <option>소분류</option>
                                                                {selectedScArr && selectedScArr.map(el => { return(<option value={el.catName} key={el.catCode}>{el.catName}</option>)})}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="nt_btn_area_p">
                                        <button className={(plusFriend && pNumber && kakaoToken && (selectedLcItem != '대분류') && (selectedMcItem != '중분류') && (selectedScItem != '소분류'))?"btn_sm6":"btn_sm6_disabled"}
                                            disabled={!(plusFriend && pNumber && kakaoToken && (selectedLcItem != '대분류') && (selectedMcItem != '중분류') && (selectedScItem != '소분류'))}
                                            onClick={()=>{
                                                const isValid = /^010[0-9]{8}$/.test(pNumber);
                                                if(!isValid){
                                                    setSimpleTextModal(true);
                                                    setSimpleText("올바른 휴대폰번호를 입력해주세요.");
                                                    return;
                                                }
                                                let catCode = scArr.find(el => el.catName == selectedScItem).catCode;
                                                regiATProfile({userId : userId, token : kakaoToken, pNumber: pNumber, plusFriend : plusFriend, catCode: catCode})
                                                .then(result => {
                                                    if(result.code == "509"){
                                                        getAlimUuidProfile({uuid: plusFriend}).then( result => {
                                                            if(result.code == "200" && result.data.list.length > 0){
                                                                saveSenderProfile({senderKey: result.data.list[0].senderKey, userId:userId}).then(result => {

                                                                    if(result.code == 0){
                                                                        setSimpleTextModal(true);
                                                                        setSimpleText("발신프로필 등록이 완료되었습니다.");
                                                                        setModal(false);
                                                                    }else{
                                                                        setSimpleTextModal(true);
                                                                        setSimpleText(result.data);
                                                                        return;
                                                                    }
                                                                })
                                                            }else{
                                                                setSimpleTextModal(true);
                                                                setSimpleText("유효하지 않은 발신 프로필 등록 요청입니다. 관리자에게 문의해주세요.");
                                                                return;
                                                            }
                                                        })
                                                    }else if(result.code == "200"){
                                                        setSimpleTextModal(true);
                                                        setSimpleText("발신프로필 등록이 완료되었습니다.");
                                                        setModal(false);
                                                        return;
                                                    }else{
                                                        setSimpleTextModal(true);
                                                        setSimpleText(result.message);
                                                        return;
                                                    }
                                            })
                                        }}>등록</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {simpleTextModal ? <SimpleModal simpleTextModal={simpleTextModal} setSimpleTextModal={setSimpleTextModal}>{simpleText}</SimpleModal> : null}
            </div>
        )
    }else return <Loading />
}

const SimpleModal = (props) => {
    const {children, simpleTextModal, setSimpleTextModal} = props;
    return(
        <div className="pop_set_c" style={simpleTextModal ? {display:'block'} : {display:'none'}}>
            <div className="pop_section">
                <div className="parea">
                    <div className="page">
                        <div className="ppheader">
                            <div className="htit">알림</div>
                            <div className="rclose" onClick={()=>{setSimpleTextModal(false)}}><img src={'/images/icon_pop_close.png'} alt="닫기"/></div>
                        </div>
                        <div>
                            <div className="s_u_p_tit">{children}</div>
                            <div className="s_u_p_btn">
                                <button
                                    className="login_as"
                                    onClick={()=>{
                                        setSimpleTextModal(false)
                                    }}
                                >
                                    확인
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceConsoleTab05;